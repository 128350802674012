import { encrypt, decrypt, hash } from "./crypto";
import axios from "axios";
import moment from "moment";
import { dia, shapes } from '@clientio/rappid';
import cogoToast from "cogo-toast";
import React from 'react';
import { js2xml } from 'xml-js';
import JSZip from 'jszip';
var dateFormat = require('dateformat');

//Testing KPMG 4.013 Branch
let isoCountries = {
    'KG': 'KAZAKISTAN',
    'AF': 'Afghanistan',
    'AX': 'Aland Islands',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'American Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua And Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaijan',
    'BS': 'Bahamas',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgium',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia',
    'BA': 'Bosnia And Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet Island',
    'BR': 'Brazil',
    'IO': 'British Indian Ocean Territory',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'KH': 'Cambodia',
    'CM': 'Cameroon',
    'CA': 'Canada',
    'CV': 'Cape Verde',
    'KY': 'Cayman Islands',
    'CF': 'Central African Republic',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands',
    'CO': 'Colombia',
    'KM': 'Comoros',
    'CG': 'Congo',
    'CD': 'Congo, Democratic Republic',
    'CK': 'Cook Islands',
    'CR': 'Costa Rica',
    'CI': 'Cote D\'Ivoire',
    'HR': 'Croatia',
    'CU': 'Cuba',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'EC': 'Ecuador',
    'EG': 'Egypt',
    'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'ET': 'Ethiopia',
    'FK': 'Falkland Islands (Malvinas)',
    'FO': 'Faroe Islands',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'France',
    'GF': 'French Guiana',
    'PF': 'French Polynesia',
    'TF': 'French Southern Territories',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germany',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Greece',
    'GL': 'Greenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard Island & Mcdonald Islands',
    'VA': 'Holy See (Vatican City State)',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Iran, Islamic Republic Of',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IM': 'Isle Of Man',
    'IL': 'Israel',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KR': 'Korea',
    'KW': 'Kuwait',
    'KG': 'Kyrgyzstan',
    'LA': 'Lao People\'s Democratic Republic',
    'LV': 'Latvia',
    'LB': 'Lebanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libyan Arab Jamahiriya',
    'LI': 'Liechtenstein',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MK': 'Macedonia',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshall Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesia, Federated States Of',
    'MD': 'Moldova',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Morocco',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Netherlands',
    'AN': 'Netherlands Antilles',
    'NC': 'New Caledonia',
    'NZ': 'New Zealand',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk Island',
    'MP': 'Northern Mariana Islands',
    'NO': 'Norway',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestinian Territory, Occupied',
    'PA': 'Panama',
    'PG': 'Papua New Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippines',
    'PN': 'Pitcairn',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'RE': 'Reunion',
    'RO': 'Romania',
    'RU': 'Russian Federation',
    'RW': 'Rwanda',
    'BL': 'Saint Barthelemy',
    'SH': 'Saint Helena',
    'KN': 'Saint Kitts And Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint Martin',
    'PM': 'Saint Pierre And Miquelon',
    'VC': 'Saint Vincent And Grenadines',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome And Principe',
    'SA': 'Saudi Arabia',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'SB': 'Solomon Islands',
    'SO': 'Somalia',
    'ZA': 'South Africa',
    'GS': 'South Georgia And Sandwich Isl.',
    'ES': 'Spain',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard And Jan Mayen',
    'SZ': 'Swaziland',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'SY': 'Syrian Arab Republic',
    'TW': 'Taiwan',
    'TJ': 'Tajikistan',
    'TZ': 'Tanzania',
    'TH': 'Thailand',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad And Tobago',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TM': 'Turkmenistan',
    'TC': 'Turks And Caicos Islands',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom',
    'US': 'United States',
    'UM': 'United States Outlying Islands',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Viet Nam',
    'VG': 'Virgin Islands, British',
    'VI': 'Virgin Islands, U.S.',
    'WF': 'Wallis And Futuna',
    'EH': 'Western Sahara',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe'
};

const helpers = {
    setCookie: function (cname, cvalue, exdays) {
        if (cname !== "tenantcode") {
            cvalue = typeof cvalue === 'undefined' ? '' : cvalue;
            let data = encrypt(cvalue.toString());
            document.cookie = cname + "=" + data + ";" + ";path=/";
            // document.cookie = cname + "=" + cvalue + ";" + ";path=/";
        } else {
            this.setSessionStorage(cname, cvalue);
        }

    },
    getCookie: function (cname) {
        if (cname !== "tenantcode") {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    let data = c.substring(name.length, c.length);
                    data = decrypt(data);
                    return data;
                }
            }
        } else {
            let data = this.getSessionStorage(cname);
            return data;
        }
        return "";
    },
    
    getTenantCookie: () => {
        let tenantCode = helpers.getCookie("tenantcode");
        let caseTenantCode = helpers.getSessionStorage("caseTenantCode");
        if (typeof caseTenantCode !== 'undefined' && caseTenantCode !== null && caseTenantCode !== "" && caseTenantCode.toString().length > 0) {
            tenantCode = caseTenantCode;
        }
        return tenantCode;
    },

    securaaNotification(message, type, time) {
    //Author - Gayathri Added try-Catch for the Function - june -29 
    try{
        let pythontype = type
        if(type.includes("python")){
            let valuetype = type.split("_")
            type = valuetype[1];
        }
    if (type === "success") {
        //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
            let text = message;
            if(pythontype !== "" && pythontype !== "python_success"){
            // text = text.toLowerCase();
            // text = text.charAt(0).toUpperCase() + text.slice(1);
            }
            const { hide } = cogoToast.success(
                <div style={{ display: "flex" }}>
                    <div style={{ padding: "0.3rem", fontSize: "20px", marginLeft: "-1.5rem" }}> <i className="fa fa-check-circle" aria-hidden="true" style={{ float: "left", cursor: "pointer" }}></i></div>
                    <div style={{ fontSize: "15px", padding: "0.3rem" }} id="textAreaSuccess" className="toasttextarea">{text.length > 50 ? text.substring(0, 50) + "..." : text}<div className="status" style={{display:"none"}}>suceess</div></div>
                    {text.length > 50 ?
                        <div style={{ marginTop: "10px" }}>
                            <i id="more|less" onClick={() => this.toggleText(text,"textAreaSuccess")}
                                href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                        </div> : ""}
                    <div style={{ padding: "0.3rem", fontSize: "20px", marginLeft: "20px" }}> <i className="fa fa-times" id="notificationclosesuccess" aria-hidden="true" style={{ float: "right", cursor: "pointer" }} onClick={() => hide()}></i></div>
                </div>, { position: "top-right", hideAfter: time });
    }else if (type === "error") {
         //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
            let text = message;
            if(pythontype !== "" && pythontype !== "python_error"){
            // text = text.toLowerCase();
            // text = text.charAt(0).toUpperCase() + text.slice(1);
            }
            const { hide } = cogoToast.error(
                <div style={{ display: "flex" }}>
                    <div style={{ padding: "0.3rem", fontSize: "19px", marginLeft: "-1.5rem" }}> <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ float: "left", cursor: "pointer" }}></i></div>
                    <div style={{ fontSize: "15px", padding: "0.3rem" }} id="textAreaError" className="toasttextarea">{text.length > 50 ? text.substring(0, 50) + "..." : text}</div>
                    {text.length > 50 ?
                        <div style={{ marginTop: "10px" }}>
                            <i id="more|less" onClick={() => this.toggleText(text,"textAreaError")}
                                href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                        </div> : ""}
                    <div style={{ padding: "0.3rem", fontSize: "20px", marginLeft: "20px" }}> <i className="fa fa-times" id="notificationcloseerror" aria-hidden="true" style={{ float: "right", cursor: "pointer" }} onClick={() => hide()}></i></div>
                </div>, { position: "top-right", hideAfter: time });
    }else if (type === "warn") {
         //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
            let text = message;
            if(pythontype !== "" && pythontype !== "python_warn"){
            // text = text.toLowerCase();
            // text = text.charAt(0).toUpperCase() + text.slice(1);
            }
            const { hide } = cogoToast.warn(
                <div style={{ display: "flex" }}>
                    <div style={{ padding: "0.3rem", fontSize: "19px", marginLeft: "-1.5rem" }}> <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ float: "left", cursor: "pointer"}}></i></div>
                    <div style={{ fontSize: "15px", padding: "0.3rem"}} id="textAreaWarning" className="toasttextarea">{text.length > 50 ? text.substring(0, 50) + "..." : text}</div>
                    {text.length > 50 ?
                        <div style={{ marginTop: "10px" }}>
                            <i id="more|less" onClick={() => this.toggleText(text,"textAreaWarning")}
                                href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                        </div> : ""}
                    <div style={{ padding: "0.3rem", fontSize: "20px", marginLeft: "20px" }}> <i className="fa fa-times" id="notificationclosewarn" aria-hidden="true" style={{ float: "right", cursor: "pointer"}} onClick={() => hide()}></i></div>
                </div>, { position: "top-right", hideAfter: time });
    }}catch(e){
        console.log("error")
    }
    },

    //Author - Gayathri - Added Function for the Toast Message - Start - 24 May

    securaaNotificationCopy(dataArrray, type, time, errType) {
        //Author - Gayathri Added try-Catch for the Function - june -29 
        try {
            if (type === "smtpSetting") {

                let text = dataArrray[0];
                let title = dataArrray[1];
                //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
                // text = text.toLowerCase();
                // text = text.charAt(0).toUpperCase() + text.slice(1);

                // title = title.toLowerCase();
                // title = title.charAt(0).toUpperCase() + title.slice(1);

                const { hide } = cogoToast.error(
                    <div style={{ display: "flex", flexDirection: "row", padding: "0.3rem" }}>
                        <div style={{ fontSize: "19px", marginTop: "2px", marginRight: "12px", marginLeft: "-15px" }}> <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ float: "left" }}></i></div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontWeight: "bold", marginRight: "20px" }}>{title}</div>
                            <div style={{ fontSize: "15px" }} id="textAreaError" className="toasttextarea">{text.length > 50 ? text.substring(0, 50) + "..." : text}</div>
                            {text.length > 50 ?
                                <div style={{ marginTop: "10px" }}>
                                    <i id="more|less" onClick={() => this.toggleText(text, "textAreaError")}
                                        href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                                </div> : ""}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ textAlign: "right", marginRight: "8px" }}><div id="Copytext" style={{ color: "white", fontWeight: "bold" }}></div><i className='fa fa-copy cursor-point' onClick={() => this.copytext(text)} />&nbsp;&nbsp;</div>
                            <div style={{ fontSize: "20px" }}><i className="fa fa-times" id="notificationclosesmtp" aria-hidden="true" style={{ float: "right", cursor: "pointer" }} onClick={() => hide()}></i></div>
                        </div>
                    </div>
                    , { position: "top-right", hideAfter: time });
            } else if (type === "App") {

                let message = dataArrray[0];
                let text = dataArrray[1];
                let title = dataArrray[2];
                //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
                // text = text.toLowerCase();
                // text = text.charAt(0).toUpperCase() + text.slice(1);

                // title = title.toLowerCase();
                // title = title.charAt(0).toUpperCase() + title.slice(1);

                const { hide } = cogoToast.error(
                    <div style={{ display: "flex", flexDirection: "row", padding: "0.3rem" }}>
                        <div style={{ fontSize: "19px", marginTop: "2px", marginRight: "12px", marginLeft: "-15px" }}> <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ float: "left" }}></i></div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontWeight: "bold" }}>{message}</div>
                            <div>{title}</div>
                            <div style={{ fontSize: "15px" }} id="textAreaError" className="toasttextarea">{text.length !== null && text.length !== "" && typeof text.length !== "undefined" && text.length > 50 ? text.substring(0, 50) + "..." : text}</div>
                            {text.length !== null && text.length !== "" && typeof text.length !== "undefined" && text.length > 50 ?
                                <div style={{ marginTop: "10px" }}>
                                    <i id="more|less" onClick={() => this.toggleText(text, "textAreaError")}
                                        href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                                </div> : ""}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ fontSize: "21px" }}><i className="fa fa-times" id="notificationcloseapp" aria-hidden="true" style={{ float: "right", cursor: "pointer" }} onClick={() => hide()}></i></div>
                        </div>
                    </div>
                    , { position: "top-right", hideAfter: time });
        
     }else if (type === "AppTaskform") {
        //Author Gayathri - Changes done to Make Toast Message Sentence Only First Letter Capital - july 26
       //let text  = dataArrray[0];
       // text = text.toLowerCase();
       // text = text.charAt(0).toUpperCase() + text.slice(1);

             let message = dataArrray[0];
             let text = dataArrray[1];
             let title = dataArrray[2];

       const { hide } = cogoToast.error(
           <div style={{display : "flex",flexDirection :"row",padding : "0.3rem"}}>
           <div style={{ fontSize: "19px", marginTop: "2px", marginRight: "12px", marginLeft: "-15px" }}> <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ float: "left" }}></i></div>           <div style={{display : "flex" , flexDirection : "column"}}>
           <div style={{ fontWeight: "bold" }}>{message}</div>
           <div>{title}</div>
                 <div style={{ fontSize: "15px"}} id="textAreaError" className="toasttextarea">{text.length !== null && text.length !== "" && typeof text.length !== "undefined" && text.length > 50 ? text.substring(0, 50) + "..." : text}</div>
               {text.length !== null && text.length !== "" && typeof text.length !== "undefined" && text.length > 50 ?
                                     <div style={{ marginTop: "10px" }}>
                                         <i id="more|less" onClick={() => this.toggleText(text, "textAreaError")}
                                             href="javascript:void(0)" style={{ fontSize: "20px", cursor: "pointer" }} className='fa fa-caret-down'></i>
                   </div> : ""}
           </div>
           <div style={{ display: "flex", flexDirection: "row" }}>&nbsp;&nbsp;
                                <div style={{ textAlign: "right", marginRight: "8px" }}><div id="Copytext" style={{ color: "white", fontWeight: "bold" }}></div><i className='fa fa-copy cursor-point' onClick={() => this.copytext(text)} />&nbsp;&nbsp;</div>
                                <div style={{ fontSize: "21px" }}><i className="fa fa-times" id="notificationclosetaskform" aria-hidden="true" style={{ float: "right", cursor: "pointer" }} onClick={() => hide()}></i></div>
                            </div>
                        </div>
               ,{ position: "top-right", hideAfter: time });

   }}catch(e){
       console.log("error")
   }
   },

    copytext (text) {
        try{
        let el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = text;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        document.getElementById("Copytext").innerText = "COPIED";
        // Remove temporary element
        document.body.removeChild(el);
        setTimeout(() => { document.getElementById("Copytext").innerText = ""; }, 2000);
        }catch(e){
            console.log("error")
        }
    },

    toggleText(text,id) {
        try{
        let startStatus = document.getElementById("more|less").className;
        if (text.length !== null && text.length !== "" && typeof text.length !== "undefined" && text.length > 50) {
            if (startStatus === "fa fa-caret-up") {
                document.getElementById(id).innerHTML = `${text.substring(0, 50)}...`;
                document.getElementById("more|less").className = "fa fa-caret-down";
                startStatus = "More";
            } else if (startStatus == "fa fa-caret-down") {
                document.getElementById(id).innerHTML = text;
                document.getElementById("more|less").className = "fa fa-caret-up";
                startStatus = "Less";
            }
        } else {
            document.getElementById(id).innerHTML = text;
            document.getElementById("more|less").className = "fa fa-caret-down";
        }
    }catch(e){
        console.log("error")
    }
    },
     //Author - Gayathri - Added Function for the Toast Message - End - 24 May

    setSessionStorage: function (keyName, data) {
        let dataTobeStored = encrypt(data);
        window.sessionStorage.setItem([keyName], dataTobeStored);
    },
    getSessionStorage: function (keyName) {
        let data = window.sessionStorage.getItem([keyName]);
        if (data !== null) {
            data = decrypt(data);
        }
        return data;
    },
    setLocalStorage: function (keyName, data) {
        let dataTobeStored = encrypt(data);
        window.localStorage.setItem([keyName], dataTobeStored);
    },
    getLocalStorage: function (keyName) {
        let data = window.localStorage.getItem([keyName]);
        if (data !== null) {
            data = decrypt(data);
        }
        return data;
    },
    removeLocalStorage: function (keyName) {
        // let dataTobeStored = encrypt(data);
        window.localStorage.removeItem(keyName);
    },
    removeSessionStorage: function (keyName) {
        window.sessionStorage.removeItem(keyName);
    },

    setCachetoDB: function (keyName, data) {
        let dataTobeStored = encrypt(data);
        const indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;

        if (!indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
        }
        const request = indexedDB.open("CacheSecuraa", 1);
        request.onupgradeneeded = function () {
        const db = request.result;
        const store = db.createObjectStore("graphsData", { keyPath: "graphname" });
        };
        request.onsuccess = function () {
        const db = request.result;
        const transaction = db.transaction("graphsData", "readwrite");
        const store = transaction.objectStore("graphsData");
        keyName=helpers.getCookie("license_name")+helpers.getCookie('tenantcode')+helpers.getCookie('userid')+keyName;
        store.put({ graphname:keyName, data:dataTobeStored,updated:Date.now(),tenantcode:helpers.getCookie('tenantcode'),userid:helpers.getCookie('userid')});    
        
        const idQuery = store.get(keyName);
        idQuery.onsuccess = function () {
        };
        transaction.oncomplete = function () {
        db.close();
        };
};
    },
    getCacheFromDB: function (keyName) {
        
        return new Promise((resolve, reject) => {
            const indexedDB =
                window.indexedDB ||
                window.mozIndexedDB ||
                window.webkitIndexedDB ||
                window.msIndexedDB ||
                window.shimIndexedDB;
    
            if (!indexedDB) {
                console.log("IndexedDB could not be found in this browser.");
                reject("IndexedDB not supported");
            }
    
            const request = indexedDB.open("CacheSecuraa", 1);
    
            request.onupgradeneeded = function () {
                const db = request.result;
                db.createObjectStore("graphsData", { keyPath: "graphname" });
            };
    
            request.onsuccess = function () {
                const db = request.result;
                const transaction = db.transaction("graphsData", "readwrite");
                const store = transaction.objectStore("graphsData");
                keyName=helpers.getCookie("license_name")+helpers.getCookie('tenantcode')+helpers.getCookie('userid')+keyName;
                const idQuery = store.get(keyName);
                idQuery.onsuccess = function () {
                    let data = idQuery.result ? idQuery.result.data : null;
                    if (data !== null) {
                       data  = decrypt(data);
                        let lastUpdated = idQuery.result ? idQuery.result.updated : null;
                        data =  JSON.parse(data);
                        data["updated"] = lastUpdated
                        resolve(data);
                        
                    } else {
                        reject("Error opening IndexedDB");
                    }
                };
    
                idQuery.onerror = function (event) {
                    reject("Error retrieving data from IndexedDB");
                };
    
                transaction.oncomplete = function () {
                    db.close();
                };
            };
    
            request.onerror = function (event) {
                reject("Error opening IndexedDB");
            };
        });
    },
    
    getColors: function () {
        let colorArray = [
            "#FDC431", "#E74C3C", "#3FAE49", "#9B59B6", "#0071B9",
            "#E74C3C", "#EF5350", "#EC407A", "#BDBDBD", "rgba(54, 162, 235, 20)",
            "#8D6E63", "#FF7043", "#7E57C2", "#FFA726", "#5C6BC0",
            "#D4E157", "#42A5F5", "#26C6DA", "#66BB6A", "#FFEE58",
            "#26C6DA",
            "#69d2e7", "#a7dbd8", "#e0e4cc", "#f38630", "#fa6900",
            "#fe4365", "#fc9d9a", "#f9cdad", "#c8c8a9", "#83af9b",
            "#ecd078", "#d95b43", "#c02942", "#542437", "#53777a",
            "#556270", "#4ecdc4", "#c7f464", "#ff6b6b", "#c44d58",
            "#774f38", "#e08e79", "#f1d4af", "#ece5ce", "#c5e0dc"
        ];
        let data = [];
        for (let i = 0; i < colorArray.length; i++) {
            let color = colorArray[Math.floor(Math.random() * colorArray.length)];
            let isInArray = data.includes(color);
            if (!isInArray) {
                data.push(color);
            }
        }
        return data;
    },
    getSingleColor: function () {
        //"rgba(54, 162, 235, 20)",
        let colorArray = [
            "#FDC431", "#E74C3C", "#3FAE49", "#9B59B6", "#0071B9",
            "#E74C3C", "#EF5350", "#EC407A", "#BDBDBD",
            "#8D6E63", "#FF7043", "#7E57C2", "#FFA726", "#5C6BC0",
            "#D4E157", "#42A5F5", "#26C6DA", "#66BB6A", "#FFEE58",
            "#26C6DA",
            "#69d2e7", "#a7dbd8", "#e0e4cc", "#f38630", "#fa6900",
            "#fe4365", "#fc9d9a", "#f9cdad", "#c8c8a9", "#83af9b",
            "#ecd078", "#d95b43", "#c02942", "#542437", "#53777a",
            "#556270", "#4ecdc4", "#c7f464", "#ff6b6b", "#c44d58",
            "#774f38", "#e08e79", "#f1d4af", "#ece5ce", "#c5e0dc"
        ];
        let randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];
        return randomColor;
    },
    getColorForTaskHeader: () => {
        let colorArray = ["#EC7063", "#5499C7", "#48C9B0", "#73C6B6", "#52BE80",
            "#F7DC6F", "#F5B041", "#E59866", "#D0D3D4", "#D7BDE2",
            "#F08080", "#FA8072", "#E9967A", "#FFA07A", "#AF7AC5",
            "#7FB3D5", "#3498DB", "#76D7C4", "#1ABC9C", "#A3E4D7",
            "#FCF3CF", "#FAD7A0", "#F8C471", "#F5CBA7", "#F0B27A",
            "#EB984E", "#D7DBDD", "#BDC3C7", "#90CAF9", "#42A5F5",
            "#81D4FA", "#80D8FF", "#00B0FF", "#80DEEA", "#00E5FF",
            "#69F0AE", "#DCE775", "#FFECB3", "#FFD180", "#FFCCCC",
            "#FF99CC", "linear-gradient(to right, #f46b45, #eea849)", "linear-gradient(to right, #e53935, #e35d5b)", "linear-gradient(to right, #d1913c, #ffd194)", "linear-gradient(to right, #7b4397, #dc2430)", "linear-gradient(to right, #136a8a, #267871)", "linear-gradient(to right, #feac5e, #c779d0, #4bc0c8)", "linear-gradient(to right, #ffb347, #ffcc33)", "linear-gradient(to bottom, #43cea2, #185a9d)", "linear-gradient(to bottom, #00c6ff, #0072ff)", "linear-gradient(to bottom, #f2709c, #ff9472)", "linear-gradient(to top, #ddd6f3, #faaca8)", "linear-gradient(to top, #50c9c3, #96deda)", "linear-gradient(to top, #de6262, #ffb88c)", "linear-gradient(to top, #ec6f66, #f3a183)",
            "linear-gradient(to top, #134e5e, #71b280)", "linear-gradient(to top, #1d976c, #93f9b9)",
            "linear-gradient(to top, #4cb8c4, #3cd3ad)", "linear-gradient(to top, #06beb6, #48b1bf)",
            "linear-gradient(to top, #56ccf2, #2f80ed)", "linear-gradient(to top, #f2994a, #f2c94c)",
            "linear-gradient(to top, #4ac29a, #bdfff3)", "linear-gradient(to top, #d66d75, #e29587)",
            "linear-gradient(to top, #6190e8, #a7bfe8)", "linear-gradient(to top, #ffafbd, #ffc3a0)",
            "linear-gradient(to top, #67b26f, #4ca2cd)", "linear-gradient(to top, #ff5f6d, #ffc371)"
        ];
        let randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];
        return randomColor;
    },
    getconnectorHealthColors:()=>{
        let colorArray = ["#DAF7A6","#FFC300","#FF5733","#C70039","#900C3F","#581845","#4e789f","#f4ec97","#bf9739","#ffbca4","#87a74c",
        "#e47f2d","#c9d65e","#cbb05b"];
        let randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];
        return randomColor;
    },
    restURL: window.location.hostname === "localhost" ? "http://localhost/zona_react_service/service.php" : window.location.origin+"/service/service.php",
    //    restURL: window.location.hostname === "localhost" ? "https://dev.securaa.io/service/service.php" : "service/service.php",
       checkSessionTimeout: function (data) {
        let pathname = window.location.pathname;
        if (data && !pathname.includes("tokenexpired") && !pathname.includes("logout")) {
            // console.log(window.location.href);
            // window.location.href = "tokenexpired";
            window.location.pathname = "tokenexpired";
        }
    },
    MSToTime: function (duration) {
        if (duration.length === 13) {
            if (duration > 0) {
                // milliseconds = parseInt((duration % 1000) / 100),
                let seconds = parseInt((duration / 1) % 60),
                    minutes = parseInt((duration / (1 * 60)) % 60),
                    hours = parseInt((duration / (1 * 60 * 60)) % 24),
                    days = Math.floor(parseInt(duration) / 1000 / 60 / 60 / 24);

                days = (days < 10) ? "0" + days : days;
                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;
                return days + ":" + hours + ":" + minutes + ":" + seconds;
            } else {
                return "00:00:00:00";
            }
        } else {
            if (duration > 0) {
                // milliseconds = parseInt((duration % 1000) / 100),
                let seconds = parseInt((duration / 1000) % 60),
                    minutes = parseInt((duration / (1000 * 60)) % 60),
                    hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                    days = Math.floor(parseInt(duration) / 1000 / 60 / 60 / 24);

                days = (days < 10) ? "0" + days : days;
                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;
                return days + ":" + hours + ":" + minutes + ":" + seconds;
            } else {
                return "00:00:00:00";
            }
        }
    },
    //Author - Gayathri Added Milisec Function for Changing Time Format in MTTA custom dashboard - Sep - 1st - Start
    MSToTimeMiliSeconds: function (duration) {
        if (typeof duration === 'undefined' || duration === null || duration === 0 || duration.length === 0) {
            return "-";
        }
        if (duration.length === 13) {
            if (duration > 0) {
                let seconds = parseInt((duration / 1) % 60),
                    minutes = parseInt((duration / (1 * 60)) % 60),
                    hours = parseInt((duration / (1 * 60 * 60)) % 24),
                    days = Math.floor(parseInt(duration) / 1000 / 60 / 60 / 24),
                    milliseconds = parseInt((duration % 1000) / 100);

                days = (days < 10) ? "0" + days : days;
                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;
                milliseconds = (milliseconds < 10) ? "0" + milliseconds : milliseconds;
                return days + ":" + hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
            } else {
                return "00:00:00:00:00";
            }
        } else {
            if (duration > 0) {
                let seconds = parseInt((duration / 1000) % 60),
                    minutes = parseInt((duration / (1000 * 60)) % 60),
                    hours = parseInt((duration / (1000 * 60 * 60)) % 24),
                    days = Math.floor(parseInt(duration) / 1000 / 60 / 60 / 24),
                    milliseconds = parseInt((duration % 1000) / 100);

                days = (days < 10) ? "0" + days : days;
                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;
                milliseconds = (milliseconds < 10) ? "0" + milliseconds : milliseconds;
                return days + ":" + hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
            } else {
                return "00:00:00:00:00";
            }
        }
//Author - Gayathri Added Function for Changing Time Format in MTTA custom dashboard - Sep - 1st - End
    },MSToHourMinute: function (duration) {
        var sec_num = parseInt(duration, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        return hours + ':' + minutes;
    }, TimeDifference: function (date1, date2) {
        let difference = parseInt(date1) - parseInt(date2);

        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;

        let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;

        let minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;

        let secondsDifference = Math.floor(difference / 1000);
        difference -= secondsDifference * 1000 * 100;

        return daysDifference + 'D:' + hoursDifference + 'H:' + minutesDifference + 'M:' + secondsDifference + 'S';
    }, TimeDifferenceWithMilleSec: function (date1, date2) {
        let difference = parseInt(date1) - parseInt(date2);

        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;

        let objStopTime = new Date((parseInt(date1)) * 1);
        let objStartTime = new Date((parseInt(date2)) * 1);

        let timeDifference = (objStopTime.getTime() - objStartTime.getTime());
        let differenceDate = new Date(timeDifference);
        let diffHours = differenceDate.getUTCHours();
        let diffMinutes = differenceDate.getUTCMinutes();
        let diffSeconds = differenceDate.getUTCSeconds();
        let milSec = differenceDate.getUTCMilliseconds();
        let readableDifference = daysDifference + "D:" + diffHours + 'H:' + diffMinutes + 'M:' + diffSeconds + "S:" + milSec + "L";
        return readableDifference;

    }, getTimeWithoutFormatter: (time) => {
        if (typeof time === 'undefined' || time === null || time === 0 || time === "") {
            time = "";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
        }
        return time;
    }, getTime: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss:l TT");
        }
        return time;
    }, getTimeFormat: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0 || time === "") {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss TT");
        }
        return time;
    }, getQradarOffenceTime: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0 || time === "") {
            time = "- - -";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM TT");
        }
        return time;
    },
    getQradarStartTime: function (time) {
        if (typeof time === 'undefined' || time === null) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
                time = dateFormat(time, "yyyy-mm-dd HH:MM");
            }
        }
        return time;
    },
    // Author:- Divya - 6/7/2023 Changes:- For qradar on edit changes for "Next offense fetch is from"
    // ----------------start-----------------------------------
    getChangeStartTime: function (time) {
        if (typeof time === 'undefined' || time === null) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
                time = dateFormat(time, "yyyy-mm-dd HH:MM");
            }
            else{
                time = dateFormat(time, "yyyy-mm-dd HH:MM"); 
            }
        }
        return time;
    },
    // -----------------------------end-------------------------
    getDateTime: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss");
        }
        return time;
    }, getDateForShift: function (time) {
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "dd-mm-yyyy");
        }
        return time;
    }, getTimeForShift: function (time) {
        //console.log(time)
        if (typeof time === 'undefined' || time === null || time === 0) {
            time = "-";
        } else {
            time = time.toString();
            if (time.length === 13) {
                time = new Date((parseInt(time)) * 1);
            } else {
                time = new Date((parseInt(time)) * 1000);
            }
            time = dateFormat(time, "hh:MM TT");
            // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];
            // if (time.length > 1) { // If time format correct
            //     time = time.slice(1);  // Remove full string match value
            //     time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            //     time[0] = +time[0] % 12 || 12; // Adjust hours
            // }
            // return time.join('');
        }
        return time;
    },
    getDateTimeForGZ: function (time) {
        if (typeof time === 'undefined' || time === null) {
            time = "-";
        } else {
            time = new Date(time);
            time = dateFormat(time, "dd-mm-yyyy hh:MM:ss");
        }
        return time;
    },
    IsEmpty: function (obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    },
    IsEmptyValues: function (obj) {
        let found = Object.keys(obj).filter(function (key) {
            return obj[key] > 0;
        });
        if (found.length) {
            return false;
        } else {
            return true;
        }
    },
    trimComma: function (str) {
        let trim = str.replace(/,\s*$/, "");
        return trim;
    },
    getCountryName: function (countryCode) {
        if (isoCountries.hasOwnProperty(countryCode)) {
            return isoCountries[countryCode];
        } else {
            return countryCode;
        }
    },
    formatNumber: function (totalRecordsCount) {
        let x = totalRecordsCount.toString();
        let lastThree = x.substring(x.length - 3);
        let otherNumbers = x.substring(0, x.length - 3);
        if (otherNumbers != '') {
            lastThree = ',' + lastThree;
        }
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    },
    deleteCookie: function (cname) {
        document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    },
    checkDataState: function (value) {
        if (typeof (value) === 'undefined') {
            return false;
        } else if (value === null) {
            return false;
        } else {
            return true;
        }
    }, getSourceID: function (value) {
        let caseID = "";
        if (value.length === 2) {
            caseID = value[1];
            caseID = caseID === "" ? 0 : caseID;
        } else {
            caseID = 0;
        }
        return caseID
    },
    //Gayathri - Written Common Function for Restricting Space as First Char in Input boxes - 2-5-2024
    handleKeyPress : function (target) {
        if(target.charCode === 32 && target.target.selectionStart === 0){
          target.preventDefault();
        }
        return target
      },
    isOnlySpaces : function (str)  {
        return /^\s*$/.test(str);
    },
    casesPredefinedColumns : [{ id: "zona_z_incident_id", value: "ID" }, { id: "description", value: "Description" }, { id: "id", value: "Source ID" }, { id: "tags_list", value: "Tags" }, { id: "zona_z_risk_score", value: "Risk Score" }, { id: "jira_key", value: "Jira Ticket" }, { id: "zona_z_source", value: "Source" }, { id: "zona_z_siem_tool_start_time", value: "Source Start Time" }, { id: "zona_z_createdts", value: "Start Time" }, { id: "zona_z_updatedts", value: "Updated Time" }, { id: "zona_z_owner", value: "Owner" }, { id: "rule_name", value: "Rule Name" }, { id: "zona_z_domain_name", value: "Domain" }, { id: "zona_z_status", value: "Status" }, { id: "zona_z_severity", value: "Severity" }, { id: "zona_z_category", value: "Category" }, { id: "zona_z_source_ips", value: "Source IP" }, { id: "zona_z_destination_ips", value: "Destination IP" },
    { id: "caseage", value: "Case Age" }, { id: "sla", value: "SLA" }, { id: "zona_z_closedts", value: "Closed Time" }, { id: "zona_z_tenantcode", value: "Tenant Name" }, { id: "zona_z_last_playbook_executed", value: "Last Playbook Executed" }, { id: "zona_z_playbook_status", value: "Playbook Status" },{ id: "zona_z_closed_by", value: "Closed By" },{ id: "zona_z_is_case_created_by_scheduled_playbook", value: "Case Created By Scheduled Playbook" },{ id: "siem_tool_start_time_24hrs_format", value: "Source Start Time(24hr)" },{ id: "createdts_24hrs_format", value: "Start Time(24hr)" },{ id: "closedts_24hrs_format", value: "Closed Time(24hr)" },{id:"zona_z_mtta",value:"MTTA"},{id:"zona_z_mttd",value:"MTTD"},{id:"zona_z_mttr",value:"MTTR"},{id:"zona_z_mttt",value:"MTTT"},{id:"zona_z_is_incident_status",value:"Incident Status"},
    { id: "zona_z_auto_process_rule_id", value: "Auto Process Rule ID" },{ id: "zona_z_auto_process_rule_name", value: "Auto Process Rule Name" },{ id: "zona_z_closing_reason", value: "Closing Reason" }],
    _pattern: {
        'ipv4': '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
        'ipv4v2': '^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$',
        'multipleipv4': '^[0-9.,]*$',
        'alphanumericwithhyphenunderscore': '^[a-zA-Z0-9-/_. ]*$',
        'email': '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        'cveid': '^[a-zA-Z0-9-]*$',
        'plainstring': '^[a-zA-Z_ ]+$',
        'host': '^[a-zA-Z_0-9@. ]+$',
        'uuid': '^[a-zA-Z0-9-]*$',
        'alphanumeric': '^[a-zA-Z0-9-/_! ]*$',
        'alphanumerics': '^[a-zA-Z0-9-. ]*$',
        'alphanumericswithcolon': '^[a-zA-Z_0-9-:. ]*$',
        'alphanumeric_with_space': '^[a-zA-Z0-9. ]*$',
        'alphanumeric_with_spacecomma': '^[a-zA-Z0-9@.-/_, ]*$',
        'alphanumeric_with_hyphen': '^[a-zA-Z0-9-,]*$',
        'alphanumeric_with_period': '^[a-zA-Z0-9.-]*$',
        'alphanumeric_for_rsa_platform': '^[(a-zA-Z0-9-_><*.,":=/?+% )]*$',
        'alphanumeric_for_rsa_platform_quote': '^[a-zA-Z0-9-_><*.,":\'=/?% ]*$',
        'url': '(https?://)?(www\\.)?([-a-z0-9]{2,63}\\.)*?[a-z0-9][-a-z0-9]{2,61}[a-z0-9]\\.[a-z]{2,4}(/[-\\w@\\+\\.~#\\?&/=%]*)?$',
        'numberswithhyphen': '^[0-9-]*$',
        'numberswithstart': '^[0-9*]*$',
        'numbers': '^[0-9]*$',
        'urltype': '(http:|https:)//.',
        'folderpath': '^(?:\.{2})?(?:\/\.{2})*(\/[a-zA-Z0-9]+)+$',
        'username': '^[a-zA-Z0-9-_,. ]*$',
        'password': '^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,16}$',
        'caseid': '^[0-9a-zA-Z-_*.+,:= ]*$',
        'alphanumeric_without_space': '^[a-zA-Z0-9-_,.]*$',
        'alphabet_without_space': '^[a-zA-Z_,.]*$',
        'numberswithperiod': "^[0-9.]*$",
        'stringwithbrackets': '^[.a-zA-z0-9,": {}]*$',
        'numberswithcomma': "^[0-9,]*$",
        'port': '^((()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5]))?)$',
        'filepath': '^(\\\\?([^\\/]*[\\/])*)([^\\/]+)$',
        'domain': '^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$',
        'alphanumericwithunderscore': '^[a-zA-Z0-9-/_ ]*$',
        'smtppassword': '^[^ ]*$',
        'alphanumeric_without_dot': '^[a-zA-Z0-9 ]*$',
        'alphanumeric_with_underscore_noSlash':'^[a-zA-Z0-9 _]*$',
        'alphanumericwithhyphenunderscorecomma': '^[a-zA-Z0-9-/_. ,]*$',
        'alphanumeric_threatindicator_task': '^[a-zA-Z0-9._, ]*$',
        'ipv6' : '^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$'
    },
    saveDatatoFile: function (data, fileName) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let json = JSON.stringify(data),
            blob = new Blob([json], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    downloadCSV: function (csvContent, filename) {
        var blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");

            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
    downloadFile: function (href, filename) {
        let url = helpers.restURL;
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = href
                        a.download = filename;
                        a.target = "_blank"
                        a.click();
                        window.URL.revokeObjectURL(url);
    },
    downloadReportFile:function(response, filename){
        let url = helpers.restURL;
        const urlData = window.URL.createObjectURL(new Blob([response.data]));
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = urlData;
				a.download =filename; // Update with your desired file name
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
                
    },validateZipfile:async function (file,allowedExtensions){ //Kavya Changes - Function to validate the file formats inside ZIP - 30May2024
        try{
            //Read file content as an arraybuffer
            const arrayBuffer = await file.arrayBuffer();
            //Loading the zip file contents
            const zip =  await JSZip.loadAsync(arrayBuffer);
            //Extracting the extentions of file present in the ZIP
            const extensions = Object.keys(zip.files)
                    .filter(filename => !zip.files[filename].dir) // Filter out directories
                    .map(filename => {
                        // Extract the extension and convert to lowercase
                        return filename.split('.').pop().toLowerCase();
                    });
            //Verifying the extracted extension are present in the allowedextensions
            const isValid = extensions.every(ext => allowedExtensions.includes(ext));
            return isValid;
        }catch(e){
            console.log("Function : validateZipfile, File : EvidanceCustom.js, Error :",e)
        }
    },
    getCSVData: (function (data) {
        let json = JSON.stringify(data);
        var blob = new Blob([json], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        return url;
    }),
    getWSURL: function () {
        let wsURL = '';
        if (window.location.protocol.startsWith("https")) {
            wsURL = 'wss://' + window.location.hostname + ':8000/ws';
            //wsURL = 'wss://dev.securaa.io:8000/ws';

        } else {
            wsURL = 'ws://' + window.location.hostname + ':8000/ws';
            //wsURL = 'ws://dev.securaa.io:8000/ws';
        }
        return wsURL;
    },
    sortArray: function (array, fieldName) {
        let sortedArray = Array.from(array).sort((a, b) => a[fieldName].localeCompare(b[fieldName]));
        return sortedArray
    },
    sortArrayDesc: function (array, fieldName) {
        let sortedArray = Array.from(array).sort((a, b) => b[fieldName].localeCompare(a[fieldName]));
        return sortedArray
    },
    sortArrayWithoutFieldName(array) {
        return Array.from(array).sort((a, b) => a.localeCompare(b));
    },
    sortArrayWithoutFieldNameDesc(array) {
        return Array.from(array).sort((a, b) => b.localeCompare(a));
    }, convertToIntArray: function (array) {
        let intArray = [];
        for (let i = 0; i < array.length; i++) {
            let intValue = parseInt(array[i]);
            if (!isNaN(intValue)) {
                intArray = intArray.concat(intValue);
            }
        }
        return intArray;
    },
    writeDataToFile(text) {
        var data = new Blob([text], { type: 'text/plain' });
        // let textFile = window.URL.createObjectURL(data);
        return data;
        // return textFile;
    },
    writeDataToFileLang(text, userid) {
        var replaceintgname = text.replace("securaa.GetIntegrationName()", '" "')
        replaceintgname = replaceintgname.replace("securaa.GetHost()", "'0.0.0.0'")

        const zeroPad = (num, places) => String(num).padStart(places, '0')
        var port = "50" + zeroPad(userid, 2)

        var replacehost = replaceintgname.replace("securaa.GetPort(integrationName)", port)
        var data = new Blob([replacehost], { type: 'text/plain' });
        // let textFile = window.URL.createObjectURL(data);
        return data;
    },
    writeDataToFileAndReturnFileName(text) {
        var data = new Blob([text], { type: 'text/plain' });
        let textFile = window.URL.createObjectURL(data);
        // return data;
        return textFile;
    },
    getFileBlobContent(b) {
        var u, x;
        u = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', u, false);
        x.send();
        URL.revokeObjectURL(u);
        return x.responseText;
    },
    getFileBlobContentFromFile(b) {
        var data = "";
        var xhr = new XMLHttpRequest();
        xhr.open('GET', b, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status === 200) {
                var myBlob = this.response;
                var reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    data = JSON.parse(event.target.result);
                    // console.log("data1", data);
                    return data;
                });
                let data = reader.readAsText(myBlob);
                return data;
            }
        };
        xhr.send();

        function readFile(event) {
            let data = JSON.parse(event.target.result);
            // console.log("data", data);
            return data;
        }
    },
    riskScoreData: {
        0: "Unknown",
        1: "Suspicious",
        2: "Low Threat",
        3: "Moderate",
        4: "High",
        5: "Critical"
    },
    headers:"Accept:application/xml\nContent-Type:application/xml\nX-TAXII-Accept:urn:taxii.mitre.org:message:xml:1.1\nX-TAXII-Content-Type:urn:taxii.mitre.org:message:xml:1.1\nX-TAXII-Services:urn:taxii.mitre.org:services:1.1\nX-TAXII-Protocol:urn:taxii.mitre.org:protocol:http:1.0\nAccept-Encoding:gzip, deflate\nAccept-Language:en-US,en;q=0.8\nConnection:close\nContent-Length:132" 
    ,
    indicatorsTypes: {
        "ipv4": "IPV4",
        "url": "URL",
        "hash": "Hash",
        "ipv6": "IPV6",
        // "MD5": "MD5",
        // "SHA1": "SHA1",
        // "email": "Email",
        "email": "Email",
        "fqdn": "FQDN",
        // "domain": "FQDN",
        // "host": "Host",
        "ssl": "SSL",
        "asn": "ASN",
        "mutex": "Mutex",/* sriraksha changes - added mutex,windows_registry_key,file_names,port,http_session_object,user_agent indicator start-16/05/2023 */
        "windows_registry_key": "Windows Registry Key",
        "file_names": "File Names",
        "port": "Port",
        "http_session_object": "HTTP Session Object",
        "user_agent": "User Agent",/* sriraksha changes - added mutex,windows_registry_key,file_names,port,http_session_object,user_agent indicator end-16/05/2023 */
        "yara" : "YARA",
        "cve" : "CVE",
        "filepath": "File Path",
        "uri": "URI",
        "carddata":"Card Data",
        "iban":"IBAN",
        "contact":"Contact",
        "cidr":"CIDR"
    },
    indicatorTypeAssociation: {
        "ipv4": "IPV4",
        "url": "URL",
        "hash": "Hash",
        "ipv6": "IPV6",
        // "MD5": "MD5",
        // "SHA1": "SHA1",
        // "email": "Email",
        "email": "Email",
        "fqdn": "FQDN",
        // "domain": "FQDN",
        // "host": "Host",
        "ssl": "SSL",
        "asn": "ASN",
        "mutex": "Mutex",/* sriraksha changes - added mutex,windows_registry_key,file_names,port,http_session_object,user_agent,yara,cve,filepath,
        uri,carddata,iban,contact,cidr indicators -12/03/2024 */
        "windows_registry_key": "Windows Registry Key",
        "file_names": "File Names",
        "port": "Port",
        "http_session_object": "HTTP Session Object",
        "user_agent": "User Agent",
        "yara" : "YARA",
        "cve" : "CVE",
        "filepath": "File Path",
        "uri": "URI",
        "carddata":"Card Data",
        "iban":"IBAN",
        "contact":"Contact",
        "cidr":"CIDR",
        "malware": "Malware",
        "campaign": "Campaign"
    },
    tipTableColumns: {
        "riskrating": 4,
        "falsepositive":5, //sriraksha changes: column number added -23/05/2023 
        "confidence": 13,//sriraksha changes: column number changed -23/05/2023 
        "sightings": 6,
        "firstseen": 8,
        "lastseen": 9,
        "processedts": 10,
    },
    getDataForTableField(data) {
        if (typeof data === 'undefined' || data === null) {
            return "";
        } else if (typeof data === 'string' || typeof data === 'number') {
            return data;
        } else if (typeof data === 'object') {
            if (data.length > 0) {
                return data.join(", ")
            } else {
                return data;
            }
        } else {
            return data;
        }
    },
    base64Encode(url) {
        return btoa(url);
    },
    decodeBase64(url) {
        return window.atob(url);
    },
    buildCsvData(data) {
        let CSV = '';
        CSV += "Type ,Indicator ,Intel Providers ,Risk Rating ,False Positive ,Sightings ,Tags ,First Seen ,Last Seen ,Reported Time ";
        for (let i = 0; i < data.length; i++) {
            let itype = data[i].itype;
            if (typeof itype === 'undefined' || itype === null || itype === "") {
                itype = "";
            } else {
                itype = helpers.indicatorsTypes[itype];
            }

            let indicator = data[i].indicator;
            let source = data[i].source;

            let riskrating = data[i].riskrating;
            riskrating = helpers.riskScoreData[riskrating];

            let falsepositive = data[i].falsepositive;
            let sightings = "-";
            let tags = data[i].tags;

            if (typeof tags !== 'undefined' && tags !== null && tags.length > 0) {
                tags = tags.join(" ")
            } else {
                tags = "-"
            }

            let firstseen = data[i].lastseen;
            if (typeof firstseen === 'undefined' || firstseen === null || firstseen === 0) {
                firstseen = "-";
            } else {
                let date = new Date((parseInt(firstseen)) * 1000);
                firstseen = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }

            let lastseen = data[i].lastseen;
            if (typeof lastseen === 'undefined' || lastseen === null || lastseen === 0) {
                lastseen = "-";
            } else {
                let date = new Date((parseInt(lastseen)) * 1000);
                lastseen = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }

            let reportedtime =data[i].processedts
            if (typeof reportedtime === 'undefined' || reportedtime === null || reportedtime === 0) {
                reportedtime = "-";
            } else {
                let date = new Date((parseInt(reportedtime)) * 1000);
                reportedtime = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }

            CSV += '\r\n';
            CSV += itype + "," + indicator + ","
                + source + "," + riskrating
                + "," + falsepositive + ","
                + sightings + "," + tags + ","
                + firstseen + "," + lastseen + ","+reportedtime + "";
        }
        helpers.downloadCSV(CSV, 'Indicators.csv');
        return true;
    },buildXMLData(data){
        let options = { compact: true, ignoreComment: true, spaces: 4 };
        let jsondata = helpers.BuildJSONData(data,"xml")
        let datajson ={"indicators":jsondata}
        let xmlData = js2xml(datajson, options);
        xmlData = `<?xml version="1.0" encoding="UTF-8" ?>\n<root>`+xmlData+`\n</root>`
        helpers.downloadCSV(xmlData, 'Indicators.xml');
        return true;
    },BuildJSONData(data,type){
        var JSONArr=[];
        for (let i = 0; i < data.length; i++) {
            let itype = data[i].itype;
            if (typeof itype === 'undefined' || itype === null || itype === "") {
                itype = "";
            } else {
                itype = helpers.indicatorsTypes[itype];
            }

            let indicator = data[i].indicator;
            let source = data[i].source.replace(/"/g,"");

            let riskrating = data[i].riskrating;
            riskrating = helpers.riskScoreData[riskrating];

            let falsepositive = data[i].falsepositive;
            let sightings = "-";
            let tags = data[i].tags;

            if (typeof tags !== 'undefined' && tags !== null && tags.length > 0) {
                tags = tags.join(" ")
            } else {
                tags = "-"
            }

            let firstseen = data[i].lastseen;
            if (typeof firstseen === 'undefined' || firstseen === null || firstseen === 0) {
                firstseen = "-";
            } else {
                let date = new Date((parseInt(firstseen)) * 1000);
                firstseen = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }

            let lastseen = data[i].lastseen;
            if (typeof lastseen === 'undefined' || lastseen === null || lastseen === 0) {
                lastseen = "-";
            } else {
                let date = new Date((parseInt(lastseen)) * 1000);
                lastseen = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }

            let reportedtime =data[i].processedts
            if (typeof reportedtime === 'undefined' || reportedtime === null || reportedtime === 0) {
                reportedtime = "-";
            } else {
                let date = new Date((parseInt(reportedtime)) * 1000);
                reportedtime = dateFormat(date, "dd-mm-yyyy hh:MM:ss TT");
            }


            let obj={}
            if(type === "json"){
                obj["Type"] = itype
                obj["Indicator"] = indicator
                obj["Intel Providers"]= source
                obj["Risk Rating"] = riskrating
                obj["False Positive"] = falsepositive
                obj["Sigtings"]= sightings
                obj["Tags"] = tags
                obj["First Seen"] = firstseen
                obj["Last Seen"]= lastseen
                obj["Reported Time"] = reportedtime
            }else{
                obj["Type"] = itype
                obj["Indicator"] = indicator
                obj["Intel-Providers"]= source
                obj["Risk-Rating"] = riskrating
                obj["False-Positive"] = falsepositive
                obj["Sigtings"]= sightings
                obj["Tags"] = tags
                obj["First-Seen"] = firstseen
                obj["Last-Seen"]= lastseen
                obj["Reported-Time"] = reportedtime
            }
            
           
            JSONArr.push(obj)

        }
        return JSONArr
    },validateAndReturnValue: function (data) {
        if (typeof data !== 'undefined' && data !== null && data !== "") {
            data = data;
        } else {
            data = "-";
        }
        return data;
    },
    buildArrayOfObjectFromArray: function (data, fieldName) {
        let arrayData = [];
        if (typeof data !== 'undefined' && data !== null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let obj = {
                    [fieldName]: data[i]
                };
                arrayData = arrayData.concat(obj);
            }
        }
        return arrayData;
    }, buildAxiosObject: async function (method, url, data) {

        var response;
        if (method == "GET") {
            await axios({
                method: method,
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                },
                responseType: "json"
            }).then(res => {
                response = res
            })
        } else {
            await axios({
                method: method,
                url: url,
                data: data,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                },
                responseType: "json"
            }).then(res => {
                response = res;
            })
        }
        return response
    },
    sortObjectByKey: function (object) {
        object = Object.keys(object).sort().reduce(
            (obj, key) => {
                obj[key] = object[key];
                return obj;
            },
            {}
        );
        return object;
    }, checkCurrentDate: function () {
        var timestamp = Number(helpers.getCookie('dataTableToDate'));
        var endDate = new Date(timestamp);
        var endDateString = new Date(timestamp).toDateString()
        var currenDateString = new Date().toDateString()
        var currenDate = new Date();
        if (currenDateString == endDateString) {
            if (currenDate.getTime() > endDate.getTime()) {
                let datePickerToDate = moment(currenDate).format('DD-MM-YYYY');
                let _toDate10Digits = Math.round(new Date().getTime() / 1000);
                let _toDate13Digits = Math.round(new Date().getTime() / 1);
                helpers.setCookie('datePickerToDate', datePickerToDate, 10);
                helpers.setCookie('dataTableToDate', _toDate13Digits, 10);
                helpers.setCookie('10DigitsToDate', _toDate10Digits, 10);
            }
        }
    },
    indexOf(arr, q) {
        return arr.findIndex(item => q.toLowerCase() === item.toLowerCase())
    },
    printLog(fileName, functionName, logCount, error) {
        console.group("%c------------------ %cMessage %c------------------", "color:grey;", "color:#20c997;", "color:grey;")
        console.log('%cFile : ', "color:green;", fileName);
        console.log('%cFunction : ', "color:#fd7e14;", functionName);
        console.log('%cNumber : ', "color:blue;", logCount);
        console.log('%cMessage : ', "color:red;", error);
        console.log('\n');
        console.groupEnd();
    },
    getUserName() {
        let _userData = helpers.getSessionStorage("userData"), caseID = "-1";
        _userData = JSON.parse(_userData);
        let userName = _userData.firstname + " " + _userData.lastname;
        return userName;
    },
    /*Author:Chandru Changes : filter default array START- May19 2023 */ 
    filterTransformArray:[
        // Author - Gayathri : Added Description for the values - May-23 - start
        {value:"general##contains",name: "Contains" , description: "Contains - Reports whether the left side contains right side"},
        {value:"general##doesnt_contain", name:"Doesn't Contain" , description: "Doesn't Contain - Reports whether the left side doesn't contain right side"},
        {value:"general##has_length_of",name: "Has length of" , description: "Has length of - Checks whether the left side (list) has the length of the number specified at the right side"},
        {value:"general##in",name:"In",  description :"In - Reports whether the left side is in right side list"},
        {value:"general##is_defined",name: "Is defined",  description: "Is defined - Tests whether a value is defined. Formerly known as &quot;Exists&quot;. (NOTE: This considers false and empty strings and lists as a defined values. If you do not want these to be considered as defined, consider using Is not empty instead)"},
        {value:"general##is_empty",name: "Is empty",  description: "Is empty - Tests whether value is empty (e.i false,empty string/object or not exists)"},
        {value:"general##is_not_empty",name: "Is not empty",  description: "Is not empty - Tests whether value not is empty (e.i value that is not false,empty string/object or not exists)"},
        {value:"general##not_defined",name: "Not defined",  description: "Not defined - Tests whether a value is not defined. Formerly known as &quot;Doesn't exist&quot; (NOTE: This considers false and empty strings and lists as a defined values. If you do not want these to be considered as defined, consider using Is not empty instead)"},
        {value:"general##not_in",name: "Not in",  description: "Not in - Reports whether the left side is not in right side list"},
        {value:"string##doesnt_end_with",name: "Doesn't end with",  description: "Doesn't end with - Tests whether left side not ends with right side"},
        {value:"string##doesnt_equal",name: "Doesn't equal",  description: "Doesn't equal - Tests whether left side is not equal to right side"},
        {value:"string##doesnt_include",name: "Doesn't include",  description: "Doesn't include - Tests whether right side is not a substring of left side"},
        {value:"string##doesnt_start_with",name: "Doesn't start with",  description: "Doesn't start with - Tests whether left side not begins with right side"},
        {value:"string##ends_with",name: "Ends with", description: "Ends with - Tests whether left side ends with right side"},
        {value:"string##has_length",name: "Has length", description: "Has length - Tests whether string (left side) has the length of the number specified at the right side"},
        {value:"string##in_list",name: "In list", description: "In list - Tests whether list, or comma separated list (right side) contains the left side string"},
        {value:"string##includes",name: "Includes", description: "Includes - Tests whether right side is a substring of left side"},
        {value:"string##matches_regex",name: "Matches - regex", description: "Matches - regex - Tests whether string (left side) matches the right side pattern eg: ^[A-Za-z]+$ - only alphabets regex"},
        {value:"string##not_in_list",name: "Not in list", description: "Not in list - Tests whether list ,or comma separated list (right side) does not contain the left side string"},
        {value:"string##starts_with",name: "Starts with", description: "Starts with - Tests whether left side begins with right side"},
        // {value:"string##stringcontainsarray",name: "StringContainsArray", description: "StringContainsArray - Checks whether a substring or an array of substrings is within a string array(each item will be checked). Supports single strings as well. For example, for substrings ['a','b','c'] in a string 'a' the script will return true."},
        {value:"number##doesnt_equal",name:"Doesn't equal", description: "Doesn't Equals - Doesn't equal - Reports whether the left side number is not equal the right side number"},
        {value:"number##equals",name: "Equals", description: "Equals - Reports whether the left side number is equal to the right side number"},
        {value:"number##greater_than",name: "Greater than", description: "Greater than - Reports whether the left side number is greater than the right side number"},
        {value:"number##inrange",name: "InRange", description: "InRange - checks if left side is in range of right side (from,to anotation e.g.) - InRange left=4right=1,8 will return true"},
        {value:"number##lesser_or_equals",name: "Lesser or equals", description: "Lesser or equals - Reports whether the left side number is lesser or equal than the right side number"},
        {value:"number##greater_or_equals",name: "Greater or equals", description: "Greater or equals - Reports whether the left side number is greater or equal than the right side number"},
        {value:"number##lesser_than",name: "Lesser than", description: "Lesser than - Reports whether the left side number is lesser than the right side number"},
        {value:"date##after",name: "After", description: "After - Reports whether the left side time is after the right side time"},
        {value:"date##afterrelativedate",name: "AfterRelativeDate", description: "AfterRelativeDate - Checks the given datetime has occured after the provided relative time."},
        {value:"date##before",name: "Before", description: "Before - Reports whether the left side time is before the right side time"},
        {value:"date##same_as",name: "Same as", description: "Same as - Reports whether the left side time is same as the right side time"},
        {value:"boolean##is_false",name: "Is false", description: "Is false - Tests whether value is false (or string representation of false)"},
        {value:"boolean##is_true",name: "Is true",description: "Is true - Tests whether value is true (or string representation of true)"},
        {value:"other##checkifsubdomain",name: "CheckIfSubdomain", description: "CheckIfSubdomain - Checks whether a given domain is a subdomain of one of the listed domains"},
        {value:"other##cidrbiggerthanprefix",name: "CIDRBiggerThanPrefix", description: "CIDRBiggerThanPrefix - Checks whether a given CIDR prefix is bigger than the defined maximum prefix."},
        {value:"other##greatercidrnumaddress",name: "GreaterCidrNumAddress", description: "GreaterCidrNumAddress - Check if number of availble addresses in IPv4 or IPv6 CIDR is greater than given number."},
        {value:"other##isincidrranges",name: "IsInCidrRanges", description: "IsInCidrRanges - Determines whether an IPv4 address is contained in at least one of the comma-delimited CIDR ranges. Multiple IPv4 addresses can be passed comma-delimited and each will be tested."},
        {value:"other##isrfc1918address",name: "IsRFC1918Address", description: "IsRFC1918Address - A filter that determines whether an IPv4 address is in the private RFC-1918 address space (10.0.0.0/8, 172.16.0.0/12, 192.168.0.0/16)."},
        {value:"other##lowercidrnumaddresses",name: "LowerCidrNumAddresses", description: "LowerCidrNumAddresses - Check if number of availble addresses in IPv4 CIDR is lower than given number."}
      ],
      //Author - Gayathri : Added Description for the values - May-23 - End
      /*Author:Chandru Changes : filter default array END- May19 2023 */ 

    urlarray: ["stage.securaa.io", "prod.securaa.io", "demo.securaa.io", "demomssp.securaa.io", "stagemssp.securaa.io"],
    tlpData: ["TLP:GREEN", "TLP:RED", "TLP:AMBER", "TLP:WHITE"],
    _mongoReplicaDownload: window.location.protocol + '//' + window.location.hostname + '/downloads/replica_server_downloads/',
    _risClientDownload: window.location.protocol + '//' + window.location.hostname + '/downloads/ris_downloads/',
    _tenantDownload: window.location.protocol + '//' + window.location.hostname + '/downloads/tenant_downloads/',
    _rsaPlatformDownloads: window.location.protocol + '//' + window.location.hostname + '/downloads/rsa_platform_downloads/',
    _eplrDownloads: window.location.protocol + '//' + window.location.hostname + '/downloads/eplr_downloads/',
    _backup: window.location.protocol + '//' + window.location.hostname + '/downloads/data_backup/',
    // _artifactDownloads: window.location.protocol + '//' + window.location.hostname + '/downloads/artifact/',
    // _logsDownloads: window.location.protocol + '//' + window.location.hostname + '/downloads/logs_downloads/',
    // _reportsDownloads: window.location.protocol + '//' + window.location.hostname + '/downloads/report/',

    googleApiKey: 'AIzaSyDMZnFlbgMDow7rx7Dij8JavcIrvwvJ5e8',
    docsPath: window.location.hostname === "localhost" ? "http://localhost/zona_react_service/" : "service/",
    scalabilityPath: window.location.protocol + '//' + window.location.hostname + ':9443',
    sampleTaskParametersDownload: window.location.protocol + '//' + window.location.hostname + '/downloads/sample/',
    hostForCustomAppLogo: window.location.protocol + '//' + window.location.hostname,
    swarmWorkerNodeInstallerDownload: window.location.protocol + '//' + window.location.hostname + '/downloads/swarm_nodes/',
    // rfDownloadPath: window.location.protocol + '//' + window.location.hostname + '/downloads/rf/',
    // rfCaseReputaionReportDownloadPath: window.location.protocol + '//' + window.location.hostname + '/downloads/rfreport/',
    importIndicatorSampleDownloadPath: window.location.protocol + '//' + window.location.hostname + '/sample/import-indicator.xlsx',
    importIndicatorSampleDownloadPathJSON:window.location.protocol + '//' + window.location.hostname + '/sample/import-indicator.json',
    importIndicatorSampleDownloadPathXML:window.location.protocol + '//' + window.location.hostname + '/sample/import-indicator.xml',
    importAssetSampleDownloadPath: window.location.protocol + '//' + window.location.hostname + '/sample/import-asset.xlsx',
    importAssetSampleDownloadPathJSON:window.location.protocol + '//' + window.location.hostname + '/sample/import-asset.json',
    importAssetSampleDownloadPathXML:window.location.protocol + '//' + window.location.hostname + '/sample/import-asset.xml',
    // rawLogsDownloadPath: window.location.protocol + '//' + window.location.hostname + '/downloads/rawlogs_downloads/',
    // jiraDownloadPath: window.location.protocol + '//' + window.location.hostname + '/downloads/jira_downloads/',
    // qrocrawLogsDownloadPath: window.location.protocol + '//' + window.location.hostname + '/downloads/qroc_rawlogs_downloads/',
    jointJSTaskColor: "#09897e",
    jointJSConditionColor: "#09897e",
    jointJSPlaybookColor: "#09897e",
    //Changed file path
    _reportsDownloads: '/opt/zona/securaa_downloads/report/',
    jiraDownloadPath: '/opt/zona/securaa_downloads/jira_downloads/',
    qrocrawLogsDownloadPath: '/opt/zona/securaa_downloads/qroc_rawlogs_downloads/',
    rfDownloadPath: '/opt/zona/securaa_downloads/rf/',
    rawLogsDownloadPath: '/opt/zona/securaa_downloads/rawlogs_downloads/',
    _logsDownloads: '/opt/zona/securaa_downloads/logs_downloads/',
    rfCaseReputaionReportDownloadPath: '/opt/zona/securaa_downloads/rfreport/',
    _artifactDownloads: '/opt/zona/securaa_downloads/artifact/',
    _caseDetailsDownload:'/opt/zona/securaa_downloads/case_downloads/',




    SecuraaTask: dia.Element.define('SecuraaTask', {
        size: { width: 250, height: 70 },
        attrs: {
            body: {
                refWidth: '100%',
                refHeight: '100%',
                fill: '#524d4d',
                stroke: '#e4e4e4',
                rx: 40,
                ry: 40,
                class: 'securaatask-body',
            },
            label: {
                refX: 60,
                refY: 22,
                fontFamily: 'Arial, Helvetica, sans-serif !important',
                fontWeight: 100,
                fontSize: 14,
                lineHeight: 19,
                event: 'element:label',
                cursor: 'pointer',
                fill: '#ffffff',
                text: 'Label',
                textWrap: {
                    width: -120,
                    maxLineCount: 1,
                    ellipsis: true
                },
                textVerticalAnchor: 'top',
                class: 'securaatask-label',
            },
            description: {
                refX: 60,
                refY: 40,
                fontFamily: 'Montserrat',
                fontWeight: 400,
                fontSize: 11,
                //Author - Gayathri - Changed the font size for Task Description - May - 23
                lineHeight: 15,
                fontStyle:"italic",
                opacity: 0.56,
                fill: '#ffffff',
                textVerticalAnchor: 'top',
                text: '',
                textWrap: {
                    width: -95,
                    maxLineCount: 1,
                    ellipsis: true
                }
            },
            // },
            // descIcon: {
            //     class: 'desc-icon',
            //     width: 8,
            //     height: 8,
            //     refX: 172,
            //     refY: 26,
            //     event: 'element:securaatask:desc'
            // },
            IconContainer: {

                height: 40,
                width: 40,
                cursor: 'pointer',
                event: 'element:edit',
                fill: '#21c246bd',
                refX: 10,
                refY: 15,
                rx: "12px"

            },
            icon: {
                width: 20,
                height: 20,
                refX: 20,
                refY: 25
            },
            securaataskLoaderIconContainer: {
                class: 'securaatask-loader',
                height: 14,
                width: 14,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 225,
                refY: 5,
            },
            securaataskLoaderIcon: {
                class: 'securaatask-loader',
                width: 15,
                height: 15,
                refX: 230,
                refY: 5,
            },
            securaataskFailureIconContainer: {
                class: 'securaatask-failure',
                height: 14,
                width: 14,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 234,
                refY: 5,
            },
            securaataskFailureIcon: {
                class: 'securaatask-failure',
                width: 12,
                height: 12,
                refX: 234,
                refY: 5,
            }, securaataskSuccessIconContainer: {
                class: 'securaatask-success',
                height: 14,
                width: 14,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 232,
                refY: 5,
            },
            securaataskSuccessIcon: {
                class: 'securaatask-success',
                width: 13,
                height: 13,
                refX: 232,
                refY: 5,
            }, deleteIconContainer: {
                class: 'delete-container',
                height: 14,
                width: 14,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 230,
                refY: 2,
            },
            deleteIcon: {
                class: 'delete-icon',
                width: 12,
                height: 12,
                refX: 230,
                refY: 5,
                event: 'element:securaatask:delete'
            }, eyeIconContainer: {
                class: 'eye-container',
                height: 14,
                width: 14,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 210,
                refY: 2,
            },
            eyeIcon: {
                class: 'eye-icon',
                width: 16,
                height: 16,
                refX: 210,
                cursor: 'pointer',
                refY: 4,
                event: 'element:securaatask:eye'
            },
            cloneIconContainer: {
                class: 'clone-container',
                height: 25,
                width: 25,
                cursor: 'pointer',
                event: 'element:edit',
                fill: 'none',
                refX: 240,
                refY: 60,
                rx: "12px"
            },
            cloneIcon: {
                class: 'clone-icon',
                width: 16,
                height: 16,
                refX: 243,
                refY: 63,
                event: 'element:securaatask:clone'

            }

        }
    }, {

        markup: [{
                tagName: 'rect',
                selector: 'body',
            }, {
                tagName: 'text',
                selector: 'label'
            }, {
                tagName: 'text',
                selector: 'description',
            }, 
            {
                tagName: 'rect',
                selector: 'IconContainer'
            }, {
                tagName: 'image',
                selector: 'icon',
            },{
                tagName: 'rect',
                selector: 'securaataskLoaderIconContainer'
            }, {
                tagName: 'image',
                selector: 'securaataskLoaderIcon'
            }, {
                tagName: 'rect',
                selector: 'securaataskFailureIconContainer'
            }, {
                tagName: 'image',
                selector: 'securaataskFailureIcon'
            }, {
                tagName: 'rect',
                selector: 'securaataskSuccessIconContainer'
            }, {
                tagName: 'image',
                selector: 'securaataskSuccessIcon'
            }, {
                tagName: 'rect',
                selector: 'deleteIconContainer'
            }, {
                tagName: 'image',
                selector: 'deleteIcon'
            },
            // }, {
            //     tagName: 'image',
            //     selector: 'descIcon'
            // }, 
            {
                tagName: 'rect',
                selector: 'eyeIconContainer'
            }, {
                tagName: 'image',
                selector: 'eyeIcon'
            },{
                tagName: 'rect',
                selector: 'cloneIconContainer'
            }, {
                tagName: 'image',
                selector: 'cloneIcon'
            }]
    }),


     isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return ;
        }
        return true;
    },


    buildModal(type,heading){
        
    },
    getLastUpdated(date){
        date = moment(date).fromNow();
        return date;
    },
    getrefreshTenant(tenants){
      try{  
        let userData = JSON.parse(helpers.getLocalStorage("tenantsData"));
        let finalarray = [];
          if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
           
                    Object.entries(userData).map(([key, value]) => {
                				let labelsArray = [];
                                let tenantstatus = tenants.filter(data => data.value === value.tenantcode);
                				if (key !== "" && value.connection_status === "connected") {
                					labelsArray = {
                						"id":key,
                						"value": value.tenantcode,
                						"name": value.tenantname,
                						"status": tenantstatus.length > 0 ? tenantstatus[0]["status"]:false
                					}
        
                				}
                                if(!Array.isArray(labelsArray)){
                                    finalarray.push(labelsArray);
                                }
                                
                			});
          }
          return finalarray
     }catch(e){
        console.log("Function:getrefreshTenant,File:helpers.js,Error:",e)
     } 
    },
    getTenantInfo(widgetName){
        try{  
          let widgetnameReplace = widgetName.replace(/ /g, "_");
         
          let userData = JSON.parse(helpers.getLocalStorage("tenantsData"));
          
          if (userData !== null && userData !== "" && typeof userData !== 'undefined') {
                  let finalarray = [];
                      Object.entries(userData).map(([key, value]) => {
                          let labelsArray = [];
                         
                          if (key !== "" && value.connection_status === "connected") {
                             
                              labelsArray = {
                                  "id":key,
                                  "value": value.tenantcode,
                                  "name": value.tenantname,
                                  "status": true
                              }
  
                          }
                          if(!Array.isArray(labelsArray)){
                              finalarray.push(labelsArray);
                          }
                          
                      });
                      helpers.setSessionStorage("tenantSelected"+widgetnameReplace,JSON.stringify(finalarray))
                  return finalarray
            //  }
          }
       }catch(e){
          console.log("Function:getTenantInfo,File:helpers.js,Error:",e)
       } 
      },
      getTenantNameArr(value){
        let tenantArray=[];
        if(value.length > 0){
            for(let i=0;i<value.length;i++){
                tenantArray = tenantArray.concat(value[i].tenantname)
            }
        }
        return tenantArray
      },
      hexToRgbaWithOpacity(hex, opacityPercentage){
        // Validate hex input
        if (!/^#([A-Fa-f0-9]{6})$/.test(hex)) {
            throw new Error('Invalid hex color format');
        }
    
        // Extract the red, green, blue values
        const red = parseInt(hex.slice(1, 3), 16);
        const green = parseInt(hex.slice(3, 5), 16);
        const blue = parseInt(hex.slice(5, 7), 16);
    
        // Calculate the new alpha value
        const alpha = (100 - opacityPercentage) / 100;
    
        // Return the color in RGBA format
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;



    }
};

export default helpers;
