import React, { Component,Fragment } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Moment from 'react-moment';
import dateFormat from 'dateformat';
import "../sass/style.css";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import helpers from "../helpers";
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Col, Row,InputGroup, InputGroupAddon } from "reactstrap";
import Loader from "./Loader";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Pagination from "./Pagination";
import ReactTooltip from 'react-tooltip';
import { authenticateFileName } from '../actions/downloads/authenticateFileAction';


const restURL = helpers.restURL;

class Tenants extends Component {
    constructor() {
        super();
        this.state = {
            startDate: new Date(),
            showAddTenantModal: false,
            showEditTenantModal: false,
            showDeleteCategoryModal: false,
            Data: [],
            newCategoryId: "",
            newCategoryName: "",
            newCategoryWeeks: "",
            newCategoryDays: "",
            newCategoryHours: "",
            industryArr: [],
            firstNameErr: "",
            loading: "hide",
            name: '',
            newTenantHostDetails: { name: "" },
            // newTenantAppDetails : { name: "applicationDetails.xlsx", lastModified: 1565774596481, webkitRelativePath: "", size: 5915, type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
            newTenantAppDetails: { name: "" },
            host: "No",
            //togglestate: false,
            showAddNewTenantButton: true,
            showTenantBackupModal: false,
            showTenantRestoreModal: false,
            backupValid: false,
            restoreValid: false,
            selectedBackupOption: "",
            selectedRestoreOption: "",
            currentTenantCode: "",
            dbRestoreTenantCode: "",
            sameNameFound: false,
            newEditTenantName: "",
            selectedTenantHostType: "",
            disabledupdate:false,
            tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>,
            pageLimit:"10",currentPage:1,TotalTenantData:0,sortColumn:"id",tableSortOrder: "asc",searchText:"",searchvalue:""
        }

        this.commonFormatter = this.commonFormatter.bind(this);
        this.statusFormatter = this.statusFormatter.bind(this);
        this.getTenantData = this.getTenantData.bind(this);
        this.checkForErrors = this.checkForErrors.bind(this);
        this.getindustry = this.getindustry.bind(this);
        this.saveIndustry = this.saveIndustry.bind(this);
        this.showAddTenantModal = this.showAddTenantModal.bind(this);
        this.closeAddTenantModal = this.closeAddTenantModal.bind(this);
        this.addNewTenant = this.addNewTenant.bind(this);
        this.setTenantData = this.setTenantData.bind(this);
        // this.handleTenantName = this.handleTenantName.bind(this);
        this.handleTenantDescription = this.handleTenantDescription.bind(this);
        this.handleTenantIndustry = this.handleTenantIndustry.bind(this);
        this.handleTenantHostDetails = this.handleTenantHostDetails.bind(this);
        this.handleTenantAppDetails = this.handleTenantAppDetails.bind(this);
        this.handleTenantExpiryDate = this.handleTenantExpiryDate.bind(this);
        this.showEditTenantModal = this.showEditTenantModal.bind(this);
        this.handleEditTenantName = this.handleEditTenantName.bind(this);
        this.handleEditTenantDescription = this.handleEditTenantDescription.bind(this);
        this.handleEditTenantIndustry = this.handleEditTenantIndustry.bind(this);
        this.handleEditTenantHostDetails = this.handleEditTenantHostDetails.bind(this);
        this.handleEditTenantAppDetails = this.handleEditTenantAppDetails.bind(this);
        this.handleEditTenantExpiryDate = this.handleEditTenantExpiryDate.bind(this);
        this.updateTenant = this.updateTenant.bind(this);
        this.downloadHostFile = this.downloadHostFile.bind(this);
        this.downloadAppFile = this.downloadAppFile.bind(this);
        this.closeEditTenantModal = this.closeEditTenantModal.bind(this);
        this.showDeleteCategoryModal = this.showDeleteCategoryModal.bind(this);
        this.closeDeleteTenantModal = this.closeDeleteTenantModal.bind(this);
        this.deleteTenant = this.deleteTenant.bind(this);
        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.expiryTimeFormatter = this.expiryTimeFormatter.bind(this);
        this.notificationMessage = this.notificationMessage.bind(this);
        this.downloadtenant = this.downloadtenant.bind(this);
        this.redirectErrorMessage = this.redirectErrorMessage.bind(this);
        this.validateform = this.validateform.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.changeToggle = this.changeToggle.bind(this);
        // this.addNewTenant1 = this.addNewTenant1.bind(this);
        this.showTenantBackupModal = this.showTenantBackupModal.bind(this);
        this.showTenantRestoreModal = this.showTenantRestoreModal.bind(this);
        this.handleBackupChangeSelect = this.handleBackupChangeSelect.bind(this);
        this.handleRestoreChangeSelect = this.handleRestoreChangeSelect.bind(this);
        this.closeTenantBackupModal = this.closeTenantBackupModal.bind(this);
        this.closeTenantRestoreModal = this.closeTenantRestoreModal.bind(this);
        this.doMSSPTenantBackup = this.doMSSPTenantBackup.bind(this);
        this.getBackup = this.getBackup.bind(this);
        this.doRestore = this.doRestore.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.handleTenantHostType = this.handleTenantHostType.bind(this);
    }

    componentDidMount() {
        this.getTenantData();
        this.getindustry();
        this.setState({tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>})
    }
    
    commonFormatter(cell, row) {
        try{
            if (typeof cell === 'undefined' || cell === null || cell === "" || cell.length === 0) {
                return (<div style={{ width: "150px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title="-">-</div>);
            }else{
                return (<div style={{ width: "150px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title={cell}>{cell}</div>);
            }
        }catch(e){
            console.log("Function:commonFormatter, File :Tenants.js, Error:",e);
        }
    }

    statusFormatter(cell, row) {
        try{
            if (typeof cell === 'undefined' || cell === null || cell === "" || cell.length === 0) {
                return <div style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title="-">-</div>;
            }else {
                return (<div style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }} className="custom_td" title={cell.charAt(0).toUpperCase() + cell.slice(1)}>{cell.charAt(0).toUpperCase() + cell.slice(1)}</div>);
            } 
        }catch(e){
            console.log("Function:statusFormatter, File :Tenants.js, Error:",e);
        }
    }

    notificationMessage(text) {
        helpers.securaaNotification(text,"success",10)
    }

    redirectErrorMessage(text) {
        helpers.securaaNotification(text,"error",10)
    }

    downloadtenant(row) {
        try{
            let hostName = window.location.host;
            let url = restURL + "?module=manager&rest=downloadtenant&q=" + row.tenantcode + "/" + hostName + "/";
            // let url = restURL + "?module=users&rest=downloadtenant&q=" + row.tenantcode + "/" + hostName + "/";
            // var url = "service/service.php?module=users&rest=downloadtenant&q=" + getCookie('tenantcode') + "/" + hostName +"/";
            axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    // var a = document.createElement("a");
                    // document.body.appendChild(a);
                    // a.href = helpers._tenantDownload + res.data.data;
                    // a.download = res.data.data;
                    // a.click();
                    // window.URL.revokeObjectURL(url);
                    this.props.authenticateFileName(helpers._tenantDownload,res.data.data,res.data.data,"others")

                } else {
                    this.redirectErrorMessage("Failed to download.");
                    this.setState({
                        errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>,
                        deleteRISModal: false,
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:downloadtenant, File :Tenants.js, Error:",e);
        }
    }

    // Load Tenant Data
    getTenantData() {
        try{
            this.setState({tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>,Data:[],TotalTenantData:0})
            //Kavya Changes : handling license type intead of build_type for enterprise - Start - 16/06/2023
            let licensedetails = helpers.getSessionStorage("licenseResponse");
            let licenseType = "";
            if(licensedetails !== undefined && licensedetails !== null){
                licensedetails = JSON.parse(licensedetails);
                licenseType = licensedetails.details.type !== null && licensedetails.details.type !== undefined ? licensedetails.details.type : "";
            }

            let length = 0;
            if (this.state.currentPage === null || this.state.currentPage === 1) {
                length = 0;
            } else {
                length = (this.state.currentPage - 1) * parseInt(this.state.pageLimit);
            }

            var data = {
                companycode: helpers.getCookie('companycode'),
                tenantids: JSON.parse(helpers.getCookie('tenantIDS')),
                userid: parseInt(helpers.getCookie('userid')),
                rest: 'gettenants',
                module: 'users',
                limit: parseInt(this.state.pageLimit),
                offset: parseInt(length),
                sortcolumn:this.state.sortColumn,
                sortorder:this.state.tableSortOrder,
                searchstring : this.state.searchvalue,
                pagetype:"tenants"
            };
            var url = restURL;
            axios({
                method: "POST",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                data: data
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    this.checkForErrors(res.data);
                    if (res.data.data.length > 0) {
                        if (licenseType.toLowerCase().includes("enterprise")) {
                            this.setState({ showAddNewTenantButton: false });
                        } else if (!licenseType.toLowerCase().includes("enterprise")) {
                            this.setState({ showAddNewTenantButton: true });
                        } else {
                            this.setState({ showAddNewTenantButton: false });
                        }
                    }
                    this.setState({ tableLoader: "" });
                } else {
                    this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>,tableLoader:"These is no data to display",Data:[],TotalTenantData:0 });
                }
                this.setState({ loading: "hide" });
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:getTenantData, File :Tenants.js, Error:",e);
        }
    }

    //Check for Errors
    checkForErrors(responsedata) {
        try{
            if (responsedata.data === null || typeof responsedata.data === 'undefined') {
                this.setState({ errorMessage: <h5>No Data</h5> });
            } else if (responsedata.displaymessage != 'undefined' && responsedata.displaymessage != "") {
                let displaymessage = "";
                if (responsedata.displaymessage != "Configuration Error") {
                    displaymessage += <h5 class="text-center">{responsedata.displaymessage}</h5>;
                }
                if (responsedata.displaymessage == "Configuration Error") {
                    displaymessage += <h5 class="configureTenant orange" style="text-align: center;cursor: pointer;"> Click here to Configure</h5>;
                }
                this.setState({ errorMessage: displaymessage });
            } else {
                let Totaldata = responsedata.totalcount !== null && responsedata.totalcount !== undefined ? responsedata.totalcount : 0
                this.setState({ Data: responsedata.data,TotalTenantData: Totaldata},
                    // this.updateTenantData(responsedata.data)
                    );
            }
        }catch(e){
            console.log("Function:checkForErrors, File :Tenants.js, Error:",e);
        }
    }
    updateTenantData =(tenantsData) =>{
        try{
            let userData = helpers.getSessionStorage("userData");
            let tenantsInfo = tenantsData;
            let _tenantIDS=[];
            if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                userData = JSON.parse(userData);
                

                for (let i = 0; i < tenantsInfo.length; i++) {
                    tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                    tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                    tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
                }
                userData.tenantsinfo = tenantsInfo;
                helpers.setSessionStorage("userData", JSON.stringify(userData));
                helpers.setLocalStorage("tenantsData",JSON.stringify(userData.tenantsinfo))
            // helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
            }
        }catch(e){
            console.log("Function:updateTenantData, File :Tenants.js, Error:",e);
        }
    }
   

    expiryTimeFormatter(row) {
        try{
            if(row !== null && row !== undefined && row !== ""){
                return (<div style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }}>
                <Moment format="YYYY-MM-DD" unix>{row}</Moment>
                </div>);
            }else{
                return (<div style={{ width: "200px", color: this.props.theme ? this.props.theme.color : "#2A3139" }}> - </div>);
            }
        }catch(e){
            console.log("Function:expiryTimeFormatter, File :Tenants.js, Error:",e);
        }
        
    }

    // changeToggle(event) {
    //     console.log("event", event.target.checked);
    //     console.log("build_type", helpers.getCookie("build_type"));
    //     this.setState({
    //         togglestate: event.target.checked
    //     }, function () {
    //         console.log("togglechecked", this.state.togglestate);
    //     })
    // }

    buttonFormatter(cell, row) {
        try{
            let userData = helpers.getSessionStorage("userData");
            let data1 = JSON.parse(userData);
            var responseHtml = '';
            if (data1.build_type == "mssp" && row.status === "active") {
                return (
                    <div className="Actionicons">
                        <i className="fa fa-edit EditIcon cursor-point" title="Edit" id="TenantEditBtn_1" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showEditTenantModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                        {data1.issuperadmin === "y" ?
                            <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            : ""}
                        {row.host_type !== "local" ?
                            <span><i className="fa fa-download cursor-point" title="Download" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.downloadtenant(row)}></i> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                            : ""}
                        {/* <i className="fa fa-cloud cursor-point" title="Backup" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showTenantBackupModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-undo cursor-point" title="Restore" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showTenantRestoreModal(row)}></i> */}
                    </div>
                )
            } else if(row.status === "failed" ) {
                return (
                    <div className="Actionicons">
                        {data1.issuperadmin === "y" ?
                            <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            : ""}
                    </div>)
            }else if(row.status === "pending"){
                return(<></>)
            }else{
                return (
                    <div className="Actionicons">
                        <i className="fa fa-edit EditIcon cursor-point" title="Edit" id="TenantEditBtn_2" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showEditTenantModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                        {data1.issuperadmin === "y" ?
                            <span><i className="fa fa-trash EditIcon cursor-point" title="Delete" style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }} onClick={() => this.showDeleteCategoryModal(row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            : ""}
                    </div>)
            }
        }catch(e){
            console.log("Function:buttonFormatter, File :Tenants.js, Error:",e);
        }
    }

    getindustry() {
        try{
            var url = restURL + "?module=users&rest=getindustries&q=" + helpers.getCookie('companycode') + "/";
            axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    this.saveIndustry(res.data);
                } else {
                    this.setState({
                        errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:getindustry, File :Tenants.js, Error:",e);
        }
    }

    saveIndustry(responsedata) {
        try{
            if (responsedata.success === true && responsedata.data !== null) {
                // let industry = [];
                // for (var i = 0; i < responsedata.data.length; i++) {
                // var tempIndustry = { label: responsedata.data[i].name, value: responsedata.data[i].id };
                // industry.push(tempIndustry);
                // }
                this.setState({ industryArr: responsedata.data });
            } else {
                this.setState({ errorMessage: <h5>No Data</h5> });
            }
        }catch(e){
            console.log("Function:getindustry, File :Tenants.js, Error:",e);
        }
    }

    //Add New Category Modal
    showAddTenantModal() {
        this.setState({
            showAddTenantModal: true,
            newTenantHostDetails: { name: "" },
            newTenantAppDetails: { name: "" },
            host: "No",
            NameErr: "",
            industryError: "",
            DescriptionErr: "",
            newTenantName: "",
            newTenantDescription: "",
            newTenantIndustry: "",
            newTenantExpiryDate: "",
            newEditTenantName: "",
            tenantHostTypeerror: "",
            selectedTenantHostType: "",
            // fileError : true
        });
    }

    handleTenantDescription(e) {
        let tempDescription = e.target.value;
        if (tempDescription.length === 0) {
            this.setState({ DescriptionErr: "Description is required", newTenantDescription: "" })
        } else {
            this.setState({ newTenantDescription: tempDescription, DescriptionErr: "" });
        }
    }

    handleTenantIndustry(e) {
        this.setState({ newTenantIndustry: e.target.value });
    }

    handleTenantHostType(e) {
        this.setState({ selectedTenantHostType: e.target.value });
    }

    handleTenantHostDetails(e) {
        this.setState({ newTenantHostDetails: e.target.files[0], }, this.validateform);
    }

    validateform() {
        if ((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name != '') || (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name == '')) {
            this.setState({ host: "No", fileError: true });
            // this.redirectErrorMessage("Include both files");
        } else if ((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name == '') || (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name != '')) {
            this.setState({ host: "Yes", fileError: false });
        }
    }
    handleTenantAppDetails(e) {
        //     if(this.state.newTenantHostDetails.name == ""){
        //         this.setState({tenantAppError : "show"});
        //   }else{
        //       if(this.state.newTenantHostDetails.name == ""){

        //       }
        //      console.log("inside else validateform");
        //   }
        this.setState({
            newTenantAppDetails: e.target.files[0],
        }, this.validateform);
        // setTimeout(() => {console.log("inside handleTenantHostDetails")} , 5000));
        //    this.validateform();
        // setTimeout(this.validateform , 5000) );
    }
    handleChange(e) {
        this.setState({
            startDate: e,
        });
    };
    handleTenantExpiryDate(e) {
        try{
            let expirytime = new Date(e.target.value).getTime() / 1000;
            let currenttime = new Date().getTime() / 1000;
            let difference = expirytime - currenttime;
            let tempExpiryDate = e.target.value;
            if (difference > 0) {
                if (tempExpiryDate.length == "0") {
                    this.setState({ ExpiryDateErr: "Expiry Date required", newTenantExpiryDate: "" });
                } else {
                    this.setState({ newTenantExpiryDate: tempExpiryDate, ExpiryDateErr: "" });
                }
            } else {
                this.setState({ ExpiryDateErr: "Please select date greater than the current date", newTenantExpiryDate: "" });
            }
        }catch(e){
            console.log("Function:getindustry, File :Tenants.js, Error:",e);
        }
    }

    // addNewTenant(e){
    //     console.log("this.state.newTenantAppDetails", this.state.newTenantAppDetails);
    //     console.log("this.state.newTenantHostDetails", this.state.newTenantHostDetails);
    //     if((this.state.newTenantAppDetails.name == "" && this.state.newTenantHostDetails.name != '')||  (this.state.newTenantAppDetails.name != "" && this.state.newTenantHostDetails.name == '')){
    //         this.setState({
    //             fileError : true
    //         })
    //     }else{
    //         this.setState({
    //             fileError : false
    //         })
    //     //     this.notificationMessage("Included Files");
    //     this.addNewTenant1(e);
    // }
    // }
    addNewTenant(e) {
        try{
            this.setState({ loading: "show", showAddTenantModal: false });
            e.preventDefault();
            let tenentIDs = helpers.getCookie('tenantIDS');
            let tenant_name = (this.state.newEditTenantName).trim();
            let tenant_description = this.state.newTenantDescription;
            let app_details_file = this.state.newTenantAppDetails;
            let host_details_file = this.state.newTenantHostDetails;
            let industyID = this.state.newTenantIndustry;
            let tenantcodestring = tenant_name.toLowerCase();
            let expiryDate = this.state.newTenantExpiryDate;
            let expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
            expiryDate10Digits = Math.round(expiryDate10Digits);
            // REMOVES WHITE SPACE AND MAKES A SINGLE WORD
            let tenantCode = tenantcodestring.replace(/ +/g, "");


            let formdata = new FormData();
            formdata.append("name", tenant_name);
            formdata.append("description", tenant_description);
            formdata.append("tenantcode", tenantCode);
            formdata.append("app_details", app_details_file);
            formdata.append("host_details", host_details_file);
            formdata.append("companycode", helpers.getCookie('companycode'));
            formdata.append("industryid", industyID);
            formdata.append("expirydate", parseInt(expiryDate10Digits));
            formdata.append("tenantidstring", tenentIDs);
            formdata.append("user_id", helpers.getCookie('userid'));
            formdata.append("host", "No");

            if (helpers.getCookie('build_type') === "mssp") {
                formdata.append("host_type", this.state.selectedTenantHostType);
            }

            if (helpers.getCookie('build_type') === "mssp" && this.state.selectedTenantHostType == "local") {
                formdata.append("rest", "createmssplocaltenant");
                formdata.append("module", "manager");
            } else {
                formdata.append("rest", "tenants");
                formdata.append("module", "users");
            }

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                dataType: "json",
                processData: false,
                contentType: false,
                data: formdata,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                        let tenantsInfo = res.data.data;
                        let userData = helpers.getSessionStorage("userData");
                        let _tenantIDS = [];
                        if (tenantsInfo.length === 1) {
                            helpers.setCookie("tenantcode", tenantsInfo[0].tenantcode, 10);
                        }
                        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                            userData = JSON.parse(userData);
                            for (let i = 0; i < tenantsInfo.length; i++) {
                                tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                                tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                                tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
                                _tenantIDS.push(tenantsInfo[i].id.toString());
                            }
                            userData.tenantsinfo = tenantsInfo;
                            helpers.setSessionStorage("userData", JSON.stringify(userData));
                        }
                        helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                    }

                    if (window.location.href.includes("/configure")) {
                        this.props.history.push("/home");
                    } else {
                        setTimeout(() => { window.location.reload(true); }, 300);
                        this.notificationMessage("Tenant added successfully.");
                        // this.checkForErrors(res.data);
                        this.setState({
                            dataLoadingText: "loading...", Data: [],currentPage: 1,pageLimit:10,tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>
                        },()=>{
                            this.getTenantData()
                        }); 
                    }
                } else {
                    //Kavya Changes : Added condition for error values - 07/07/2023
                    if(res.data.displaymessage !== null && res.data.displaymessage !== undefined && res.data.displaymessage !== ""){
                        this.redirectErrorMessage(res.data.displaymessage)
                    }else{
                        this.redirectErrorMessage("Error in response.")
                    }
                    // this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
                }
                this.setState({ loading: "hide",tableLoader:"" });
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:getindustry, File :Tenants.js, Error:",e);
        }
    }

    closeAddTenantModal() {
        this.setState({ showAddTenantModal: false });
    }

    //Edit Category Modal
    showEditTenantModal(row) {
        try{
            this.setState({disabledupdate:true})
            this.setState({ NameErr: "", DescriptionErr: "" });
            // var protocol = window.location.protocol;
            // var hostname = window.location.hostname;
            let expDate = row.expirydate;

            let expiryDate = new Date(expDate * 1000);
            let formattedDate = dateFormat(expiryDate, "yyyy-mm-dd");
            let industry = this.state.industryArr;
            let industryID = "";
            for (let i = 0; i < industry.length; i++) {
                if (row.industry === industry[i].name) {
                    industryID = industry[i].id;
                    break;
                }
            }

            this.setState({
                newEditTenantName: row.name,
                newEditTenantDescription: row.description,
                newEditTenantExpiryDate: formattedDate,
                newEditTenantIndustry: industryID,
                originalname: row.name
            });

            // var url = restURL + "?module=users&rest=gettenantdata&q=" + helpers.getCookie('companycode') + "/" + row.id + "/" + protocol + "/" + hostname + "/";
            // axios({
            //     method: "GET",
            //     url: url,
            //     headers: {
            //         access_token: helpers.getCookie('accesstoken'),
            //         jwt_token: helpers.getCookie('jwt_token'),
            //         "Content-Type": "application/json; charset=utf-8"
            //     },
            //     responseType: "json"
            // }).then(res => {
            //     helpers.checkSessionTimeout(res.data.sessionexpired);
            //     if (res.data.success) {
            //         this.setTenantData(res.data.data, expDate);
            //     } else {
            //         this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>, });
            //     }
            // }).catch(function (error) {
            //     console.log(error);
            // });

            this.setState({ newEditTenantId: row.id, showEditTenantModal: true });
        }catch(e){
            console.log("Function:showEditTenantModal, File :Tenants.js, Error:",e);
        }
    }

    setTenantData(responsedata, expDate) {
        try{
            var expiryDate = new Date(expDate * 1000);
            var formattedDate = dateFormat(expiryDate, "yyyy-mm-dd");
            this.setState({
                newEditTenantName: responsedata.name,
                newEditTenantDescription: responsedata.description,
                newEditTenantHostDetails: responsedata.hostfilename,
                newEditTenantAppDetails: responsedata.appfilename,
                newEditTenantExpiryDate: formattedDate,
                newEditTenantIndustry: responsedata.industryid,
                originalname: responsedata.name
            });
        }catch(e){
            console.log("Function:setTenantData, File :Tenants.js, Error:",e);
        }
    }

    handleEditTenantName(e) {
        try{
            this.setState({disabledupdate:false})
            if (e.target.value.length != 0) {
                if (e.target.value.match(/^[a-zA-Z ]*$/i) == null) {
                    this.setState({ NameErr: "Only text allowed", newEditTenantName: e.target.value, isValidTenantName: false });
                } else {
                    let value = e.target.value;
                    value = value.toLowerCase();
                    let tenantData = this.state.Data;
                    let sameNameFound = false;
                    for (let i = 0; i < tenantData.length; i++) {
                        if (tenantData[i].name.trim().toLowerCase() === value.trim()) {
                            if (!this.state.showAddTenantModal) {
                                if (this.state.originalname.trim().toLowerCase() !== value.trim()) {
                                    sameNameFound = true;
                                    break;
                                }
                            } else {
                                sameNameFound = true;
                                break;
                            }
                        }
                    }

                    if (sameNameFound) {
                        this.setState({ NameErr: "Tenant name is already exists. Please choose different name.", isValidTenantName: false });

                    }else if(e.target.value.toLowerCase().trim() === "master"){//Handle this condition to avoid Tenant name as "Master"

                        this.setState({ NameErr: "Tenant name cannot be created as Master. Please choose different name.", isValidTenantName: false });
                    }else {
                        this.setState({ NameErr: "", isValidTenantName: true });
                    }
                    this.setState({ newEditTenantName: e.target.value, sameNameFound });
                }
            } else {
                this.setState({ NameErr: "Tenant name is required", newEditTenantName: "", isValidTenantName: false });
            }
            if(e.target.value.length > 40){
                this.setState({ NameErr: "Tenant name should not be more than 40 characters", isValidTenantName: false });
            }
        }catch(e){
            console.log("Function:setTenantData, File :Tenants.js, Error:",e);
        }
    }

    handleEditTenantDescription(e) {
        try{
            this.setState({disabledupdate:false})
            var tempDescription = e.target.value;
            if (tempDescription.length == "0") {
                this.setState({ DescriptionErr: "Description is required", newEditTenantDescription: "" })
            } else {
                this.setState({ newEditTenantDescription: tempDescription, DescriptionErr: "" });
            }
        }catch(e){
            console.log("Function:setTenantData, File :Tenants.js, Error:",e);
        }
    }

    handleEditTenantIndustry(e) {
        this.setState({disabledupdate:false})
        this.setState({ newEditTenantIndustry: e.target.value });
    }

    handleEditTenantHostDetails(e) {
        this.setState({ newHostFile: e.target.files[0] });
    }

    handleEditTenantAppDetails(e) {
        this.setState({ newAppFile: e.target.files[0] });
    }

    handleEditTenantExpiryDate(e) {
        try{
            this.setState({disabledupdate:false})
            let expirytime = new Date(e.target.value).getTime() / 1000;
            let currenttime = new Date().getTime() / 1000;
            let difference = expirytime - currenttime;

            let tempExpiryDate = e.target.value;
            if (difference > 0) {
                if (tempExpiryDate.length == "0") {
                    this.setState({ ExpiryDateErr: "Expiry date is required", newEditTenantExpiryDate: tempExpiryDate });
                } else {
                    this.setState({ newEditTenantExpiryDate: tempExpiryDate, ExpiryDateErr: "" });
                }
            } else {
                this.setState({ ExpiryDateErr: "Can't choose previous date", newEditTenantExpiryDate: "" });
            }
        }catch(e){
            console.log("Function:handleEditTenantExpiryDate, File :Tenants.js, Error:",e);
        }
    }

    updateTenant(e) {
        try{
            this.setState({ loading: "show", showEditTenantModal: false });
            e.preventDefault();
            if (!this.state.newHostFile || !this.state.newAppFile) {
                var expiryDate = this.state.newEditTenantExpiryDate;
                var expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
                expiryDate10Digits = Math.round(expiryDate10Digits);

                var data = {
                    id: parseInt(this.state.newEditTenantId),
                    name: this.state.newEditTenantName,
                    description: this.state.newEditTenantDescription,
                    industryid: parseInt(this.state.newEditTenantIndustry),
                    expirytime: expiryDate10Digits,
                    tenantidstring: JSON.parse(helpers.getCookie('tenantIDS')),
                    user_id: parseInt(helpers.getCookie('userid')),
                    companycode: helpers.getCookie('companycode'),
                    rest: "updatetenantdetails",
                    module: "users"
                };

                axios({
                    method: "POST",
                    url: restURL,
                    headers: {
                        access_token: helpers.getCookie('accesstoken'),
                        jwt_token: helpers.getCookie('jwt_token'),
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    responseType: "json",
                    processData: false,
                    contentType: false,
                    data: JSON.stringify(data),
                }).then(res => {
                    helpers.checkSessionTimeout(res.data.sessionexpired);
                    if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                        let tenantsInfo = res.data.data;
                        let userData = helpers.getSessionStorage("userData");
                        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                            userData = JSON.parse(userData);
                            for (let i = 0; i < tenantsInfo.length; i++) {
                                for (let j = 0; j < userData.tenantsinfo.length; j++) {
                                    if (parseInt(tenantsInfo[i].id) === userData.tenantsinfo[j].id) {
                                        userData.tenantsinfo[j].tenantname = tenantsInfo[i].name;
                                        userData.tenantsinfo[j].expirytime = tenantsInfo[i].expirydate;
                                        break;
                                    }
                                }
                            }
                            helpers.setSessionStorage("userData", JSON.stringify(userData));
                            setTimeout(() => { window.location.reload(true); }, 200);
                        }
                    }
                    if (res.data.success) {
                        this.notificationMessage("Tenant updated successfully.");
                        // this.checkForErrors(res.data);
                        this.setState({
                            dataLoadingText: "loading...", Data: [], currentPage: 1,pageLimit:10,tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>
                        },()=>{
                            this.getTenantData()
                        }); 
                    } else {
                        //Kavya Changes : Added condition for error values - 07/07/2023
                        if(res.data.displaymessage !== null && res.data.displaymessage !== undefined && res.data.displaymessage !== ""){
                            this.redirectErrorMessage(res.data.displaymessage)
                        }else{
                            this.redirectErrorMessage("Error in response.")
                        }
                        // this.setState({
                        //     errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>,
                        // });
                    }
                    this.setState({ loading: "hide",tableLoader:"" });
                }).catch(function (error) {
                    console.log(error);
                });

            } else {

                let tenentIDs = helpers.getCookie('tenantIDS');
                let tenant_name = this.state.newEditTenantName;
                let tenant_description = this.state.newEditTenantDescription;
                let app_details_file = this.state.newAppFile;
                let host_details_file = this.state.newHostFile;
                let industyID = this.state.newEditTenantIndustry;
                let tenantcodestring = tenant_name.toLowerCase();
                let expiryDate = this.state.newEditTenantExpiryDate;
                let expiryDate10Digits = new Date(expiryDate).getTime() / 1000;
                expiryDate10Digits = Math.round(expiryDate10Digits);

                // REMOVES WHITE SPACE AND MAKES A SINGLE WORD
                let tenantCode = tenantcodestring.replace(/ +/g, "");
                let formdata = new FormData();
                formdata.append("id", parseInt(this.state.newEditTenantId));
                formdata.append("name", tenant_name);
                formdata.append("description", tenant_description);
                formdata.append("tenantcode", tenantCode);
                formdata.append("app_details_update", app_details_file);
                formdata.append("host_details_update", host_details_file);
                formdata.append("companycode", helpers.getCookie('companycode'));
                formdata.append("industryid", industyID);
                formdata.append("expirydate", parseInt(expiryDate10Digits));
                formdata.append("tenantidstring", tenentIDs);
                formdata.append("user_id", helpers.getCookie('userid'));
                formdata.append("rest", "updatetenant");
                formdata.append("module", "users");

                axios({
                    method: "POST",
                    url: restURL,
                    headers: {
                        access_token: helpers.getCookie('accesstoken'),
                        jwt_token: helpers.getCookie('jwt_token'),
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    responseType: "json",
                    processData: false,
                    contentType: false,
                    data: formdata,
                }).then(res => {
                    helpers.checkSessionTimeout(res.data.sessionexpired);
                    if (res.data.success) {

                    } else {
                        this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>, });
                    }
                    this.setState({ loading: "hide" });
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }catch(e){
            console.log("Function:updateTenant, File :Tenants.js, Error:",e);
        }
    }

    downloadHostFile() {
        try{
            this.props.authenticateFileName(helpers._logsDownloads,[this.state.newEditTenantHostDetails],this.state.newEditTenantHostDetails,"others")
            // const fileurl = "/downloads/logs_downloads/" + [this.state.newEditTenantHostDetails];
            // const url = window.URL.createObjectURL(new Blob([fileurl]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', this.state.newEditTenantHostDetails);
            // document.body.appendChild(link);
            // link.style.display = 'none';
            // link.click();
        }catch(e){
            console.log("Function:downloadHostFile, File :Tenants.js, Error:",e);
        }
    }

    downloadAppFile() {
        try{
            this.props.authenticateFileName(helpers._logsDownloads,[this.state.newEditTenantAppDetails],this.state.newEditTenantAppDetails,"others")
            // const fileurl = "/downloads/logs_downloads/" + [this.state.newEditTenantAppDetails];
            // const url = window.URL.createObjectURL(new Blob([fileurl]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', this.state.newEditTenantAppDetails);
            // document.body.appendChild(link);
            // link.style.display = 'none';
            // link.click();
        }catch(e){
            console.log("Function:downloadAppFile, File :Tenants.js, Error:",e);
        }
    }

    downloadSampleHostFile() {
        try{
            this.props.authenticateFileName("/opt/zona/securaa_downloads/sample","hostDetails.xlsx","hostDetails.xlsx","others")
            // const fileurl = "/downloads/sample/hostDetails.xlsx";
            // const url = window.URL.createObjectURL(new Blob([fileurl]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'hostDetails.xlsx');
            // document.body.appendChild(link);
            // link.style.display = 'none';
            // link.click();
        }catch(e){
            console.log("Function:downloadSampleHostFile, File :Tenants.js, Error:",e);
        }
    }

    downloadSampleAppFile() {
        try{
            this.props.authenticateFileName("/opt/zona/securaa_downloads/sample","applicationDetails.xlsx","applicationDetails.xlsx","others")

            // const fileurl = "/downloads/sample/applicationDetails.xlsx";
            // const url = window.URL.createObjectURL(new Blob([fileurl]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'applicationDetails.xlxs');
            // document.body.appendChild(link);
            // link.style.display = 'none';
            // link.click();
        }catch(e){
            console.log("Function:downloadSampleHostFile, File :Tenants.js, Error:",e);
        }
    }

    closeEditTenantModal() {
        this.setState({ showEditTenantModal: false });
    }

    //Delete Category Model
    showDeleteCategoryModal(row) {
        this.setState({
            newTenantCode: row.tenantcode,
            newTenantId: row.id,
            name: row.name,
            showDeleteCategoryModal: true,
        })
    }

    closeDeleteTenantModal() {
        this.setState({ showDeleteCategoryModal: false });
    }

    deleteTenant() {
        try{
            this.setState({ loading: "show", showDeleteCategoryModal: false });
            let data = {
                id: parseInt(this.state.newTenantId),
                // user_id :this.state.user_id,
                user_id: parseInt(helpers.getCookie('userid')),
                companycode: helpers.getCookie('companycode'),
                tenantidstring: JSON.parse(helpers.getCookie('tenantIDS')),
                tenantcode: this.state.newTenantCode,
                rest: 'deletetenant',
                module: 'users'
            };

            axios({
                method: "DELETE",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                data: JSON.stringify(data)
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    let _tenantIDS = [];
                    if (typeof res.data.data !== 'undefined' && res.data.data !== null && res.data.data.length > 0) {
                        let tenantsInfo = res.data.data;
                        if (tenantsInfo.length === 1) {
                            helpers.setCookie("tenantcode", tenantsInfo[0].tenantcode, 10);
                        }
                        let userData = helpers.getSessionStorage("userData");
                        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                            userData = JSON.parse(userData);
                            for (let i = 0; i < tenantsInfo.length; i++) {
                                tenantsInfo[i].id = parseInt(tenantsInfo[i].id);
                                tenantsInfo[i]["tenantname"] = tenantsInfo[i].name;
                                tenantsInfo[i]["expirytime"] = tenantsInfo[i].expirydate;
                                _tenantIDS.push(tenantsInfo[i].id.toString());
                            }
                            userData.tenantsinfo = tenantsInfo;
                            helpers.setSessionStorage("userData", JSON.stringify(userData));
                            setTimeout(() => { window.location.reload(true); }, 300);
                        }
                        helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                    } else {
                        let userData = helpers.getSessionStorage("userData");
                        if (typeof userData !== 'undefined' && userData !== null && userData !== "") {
                            userData = JSON.parse(userData);
                            let tenantsInfo = [];
                            userData.tenantsinfo = tenantsInfo;
                            helpers.setSessionStorage("userData", JSON.stringify(userData));
                        }
                        helpers.setCookie("tenantcode", "", 10);
                        helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
                        setTimeout(() => {
                            this.props.history.push("/configure");
                        }, 300);
                    }

                    this.notificationMessage("Tenant deleted successfully.");
                    // this.checkForErrors(res.data);
                    this.setState({
                        dataLoadingText: "loading...", Data: [], currentPage: 1,pageLimit:10,tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>
                    },()=>{
                        this.getTenantData()
                    }); 
                } else {
                    this.setState({
                        errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                    });
                }
                this.setState({ loading: "hide",tableLoader:"" });
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:deleteTenant, File :Tenants.js, Error:",e);
        }
    }

    showTenantBackupModal(row) {
        this.setState({ showTenantBackupModal: true, currentTenantCode: row.tenantcode });
    }

    showTenantRestoreModal(row) {
        this.setState({ showTenantRestoreModal: true, dbRestoreTenantCode: row.tenantcode });
    }

    handleBackupChangeSelect(e) {
        this.setState({ selectedBackupOption: e.target.value, backupValid: true });
    }

    handleRestoreChangeSelect(e) {
        this.setState({ selectedRestoreOption: e.target.value, restoreValid: true });
    }

    closeTenantBackupModal() {
        this.setState({ showTenantBackupModal: false });
    }

    closeTenantRestoreModal() {
        this.setState({ showTenantRestoreModal: false });
    }

    doMSSPTenantBackup() {
        if (this.state.selectedBackupOption === "All")
            this.getBackup("all");
        else if (this.state.selectedBackupOption === "Configuration")
            this.getBackup("config");
        else if (this.state.selectedBackupOption === "Data")
            this.getBackup("data");
    }

    getBackup(dumpType) {
        try{
            this.setState({ loading: "show" });
            let url = restURL + "?module=manager&rest=msspdatabackup&q=" + this.state.currentTenantCode + "/" + dumpType + "/";
            axios({
                method: "GET",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                },
                responseType: "json"
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = helpers._backup + res.data.data;
                    let fileName = res.data.data.split("/");
                    a.download = fileName[1];
                    a.click();
                    setTimeout(() => { this.notificationMessage("Backup done successfully.") }, 1000);
                } else {
                    setTimeout(() => { this.redirectErrorMessage("Failed") }, 1000);
                    this.setState({
                        errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6>
                    });
                }
                this.setState({ loading: "hide" });
                this.setState({ showTenantBackupModal: false });
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:getBackup, File :Tenants.js, Error:",e);
        }
    }

    handleRestore(e) {
        this.setState({ restoreAllZip: e.target.files[0] });
    }

    doRestore() {
        try{
            this.setState({ loading: "show" });
            let formdata = new FormData();
            formdata.append("database_data", this.state.restoreAllZip);
            formdata.append("rest", "msspdatarestore");
            formdata.append("tenantcode", this.state.dbRestoreTenantCode)
            formdata.append("company_code", helpers.getCookie('companycode'));
            formdata.append("restore_type", this.state.selectedRestoreOption.toLowerCase());
            formdata.append("module", "manager");

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                },
                responseType: "json",
                processData: false,
                contentType: false,
                data: formdata,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    setTimeout(() => {
                        this.props.history.push("/logout");
                    }, 1500);
                } else {
                    this.setState({ errorMessage: <h6 className="text-center">{res.data.displaymessage}</h6> });
                    this.redirectErrorMessage("Failed");
                }
                this.setState({ showTenantRestoreModal: false, });
                this.setState({ loading: "hide" });
            }).catch(function (error) {
                console.log(error);
            });
        }catch(e){
            console.log("Function:doRestore, File :Tenants.js, Error:",e);
        }
    }

    handleKeyPress(target) {
        if(target.charCode === 32 && target.target.selectionStart === 0){
          target.preventDefault();
        }
      }

    onPageChanged=(data)=>{
        try{

            if (this.state.currentPage != data.currentPage) {
                this.setState({ Data: []});
            }
            let pageNumber = data.currentPage - 1;
            pageNumber = pageNumber < 0 ? 0 : pageNumber;

            this.setState({
                dataLoadingText: "loading...", Data: [], currentPage: data.currentPage,tableLoader: <div className="loaderListContents"><Loader.tableLoaderN /></div>
            },()=>{
                this.getTenantData()
            });  
        }catch(e){
            console.log("Function:onPageChanged, File :Tenants.js, Error:",e);
        }
    }

    onSortChange = (sortName, sortOrder) => {
        try{
            let { searchString } = this.state;
            
            this.setState({
                currentPage: this.state.currentPage,
                tableSortOrder: sortOrder,
                // dataLoadingText: <div className="loaderListContents"><Loader.tableLoaderN /></div>,
                // allIncidents: [],
                totalRecords: 0,
                sortColumn:sortName
            },()=>{
                this.getTenantData()
            })
        }catch(e){
            console.log("Function : onSortChange, File : Tenants.js, Error :",e)
        }

    }
    
    handleSearch = (e) => {
        if(e.target.value===""){
            this.setState({searchvalue:""},()=>{
                this.getTenantData();
            })
        }
        this.setState({searchText:e.target.value,searchvalue:e.target.value})
    };
    handleSearchValue = () => {
        this.setState({searchvalue:this.state.searchText})
        this.getTenantData();
    };

    handlekeyDown = (event) => {
        try{
            if(event.charCode === 32 && event.target.selectionStart === 0){
                event.preventDefault();
            }
            let keyCode = event.which;
            if (keyCode === 13) {
                this.setState({searchvalue:event.target.value},()=>{
                    if(event.target.value !== ""){
                        this.getTenantData();
                    }
                })

            }
        }catch(e){
            console.log("Function : handlekeyDown, Filename : Tenants.js, Error :",e)
        }
    }
    
    csvTimeFormatter = (cell, row) => {
        try{
            if (typeof cell !== 'undefined' || cell !== null || cell !== "") {
                return (helpers.getDateForShift(cell));
            } else {
                return ("NA");
            }
        }catch(e){
            console.log("Function : csvTimeFormatter, Filename : Tenants.js, Error :",e)
        }
    }

    render() {

        let modalStyle = { color: "" };
        let inputStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            modalStyle = this.props.theme.modal;
            inputStyle = this.props.theme.input;
        }

        const options = {
            page: 1,
            sizePerPage: 2,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'bottom',
            hidePageListOnlyOnePage: true,
            hideSizePerPage: true,
            searchPosition: 'left',
            noDataText: this.state.tableLoader
        };

        const MyExportCSV = (props) => {
            const handleClick = () => {
                props.onExport();
            };
            return (
                <div className={helpers.getCookie("_casepanelPermission") === "true"?"":"disabled"}>
                    <ReactTooltip />
                    {/*Kavya Changes : condition handled to hide exportCSV button if data is not present - 05/06/2023*/}
                    {this.state.Data !== null && this.state.Data !== undefined && this.state.Data.length > 0 ?
                    <Button size="sm" id="AuditTrialExportToCsv" className="btn btn-success" onClick={handleClick}>Export to CSV</Button>
                    :<React.Fragment/>}
                </div>
            );
        };

        const columns = [{
            dataField: 'name',
            text: 'Name',
            align: 'left',
            id : "tenantname",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '250px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px"};
            },
            sort: true,
            onSort:(field, order) => {
                 this.onSortChange(field,order)
            },
            formatter: this.commonFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        }, {
            dataField: 'description',
            text: 'Description',
            align: 'left',
            id : "tenantdescription",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '300px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: true,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.commonFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        }, {
            dataField: 'industry',
            text: 'Industry',
            align: 'left',
            id : "tenantindustry",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '200px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: true,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.commonFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        }, {
            dataField: 'expirydate',
            text: 'Expiry Date',
            align: 'left',
            id : "tenant_expiry_date",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '200px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: true,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.expiryTimeFormatter,
            csvFormatter: this.csvTimeFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        }, {
            dataField: 'host_type',
            text: 'Host Type',
            align: 'left',
            id : "tenant_host_type",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '200px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: true,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.commonFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        },{
            dataField: 'connection_status',
            text: 'Status',
            align: 'left',
            id : "tenant_connection_status",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '200px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: true,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.statusFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        },{
            dataField: 'uid',
            text: 'Action',
            align: 'left',
            id : "tenant_action",
            headerStyle: (columns, colIndex) => {
                return { textAlign: "left", width: '200px', color:  this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" };
            },
            sort: false,
            onSort:(field, order) => {
                this.onSortChange(field,order)
           },
            formatter: this.buttonFormatter,
            style : {color:  this.props.theme ? this.props.theme.color : "#2A3139"}
        }];

        const fontColor = this.props.theme ? this.props.theme.color : "#2A3139";
        let userData = helpers.getSessionStorage("userData");
        let data1 = JSON.parse(userData);
        
		let licensedetails = helpers.getSessionStorage("licenseResponse");
        let licenseType = "";
        if(licensedetails !== undefined && licensedetails !== null){
            licensedetails = JSON.parse(licensedetails);
            licenseType = licensedetails.details.type !== null && licensedetails.details.type !== undefined ? licensedetails.details.type : "";
        }


        return (
            <div >
                <LoadingSpinner showSpinner={this.state.loading} />
                {/* {window.location.href.includes("/configure") ? "" :
                    <div className="width100">
                        {window.location.href.includes("/Platform") ?
                            <BootstrapTable id="TenantsSearch" pagination={true} data={this.state.Data} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' options={options} search>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="name" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.commonFormatter}>Name</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='150' dataField="description" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Description</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Industry</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="expirydate" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.expiryTimeFormatter}>Expiry Date</TableHeaderColumn>
                                {helpers.getCookie('build_type') === "mssp" ?
                                <TableHeaderColumn dataAlign='left' width='100' dataField="host_type" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Host Type</TableHeaderColumn>
                                :
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} hidden>Industry</TableHeaderColumn>
                                }
                                <TableHeaderColumn dataAlign='left' width='70' dataField="connection_status" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="uid" hidden={helpers.getCookie("_platformWritePersmission") === "true" ? false : true} thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataFormat={this.buttonFormatter} visible={this.state.showDelete}>Action</TableHeaderColumn>
                            </BootstrapTable>
                            : <BootstrapTable pagination={true} data={this.state.Data} trStyle={{ fontSize: "13px" }} bodyStyle={{ overflow: 'auto' }} striped={false} hover={true} trClassName='table-format' options={options}>
                                <TableHeaderColumn dataAlign='left' width='80' dataField="name" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} isKey={true} dataSort={true} dataFormat={this.commonFormatter}>Name</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='160' dataField="description" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter} >Description</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='110' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Industry</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='70' dataField="connection_status" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                                <TableHeaderColumn dataAlign='left' width='100' dataField="expirydate" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.expiryTimeFormatter}>Expiry Date</TableHeaderColumn>
                                {helpers.getCookie('build_type') === "mssp" ?
                                <TableHeaderColumn dataAlign='left' width='100' dataField="host_type" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} dataFormat={this.commonFormatter}>Host Type</TableHeaderColumn>
                                :
                                <TableHeaderColumn dataAlign='left' width='100' dataField="industry" thStyle={{ color: this.props.theme ? this.props.theme.color : "#2A3139", fontSize: "12px" }} dataSort={true} hidden>Industry</TableHeaderColumn>
                                }
                            </BootstrapTable>
                        }
                    </div>
                } */}

                {/*Kavya Changes : Changing Bootstrap table to bootstrap table next*/}
                <Fragment>
                
                <InputGroup className="full-length-search-bar" style={{ width: "98%" }} >
                    <Input id={"auditrialsearchbox"} className="searchinputstyle" placeholder="Search" style={inputStyle} onChange={(e) => this.handleSearch(e)} onKeyPress={this.handlekeyDown} value={this.state.searchText} autoComplete="off" />
                    <InputGroupAddon addonType="append">
                        <Button style={inputStyle} className="custom-search-btn" size="sm">
                            <i className="fa fa-search" style={{ color: fontColor, cursor: "pointer", fontSize: "16px" }} onClick={() => this.handleSearchValue()} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup> 
                
                    {window.location.href.includes("/configure") ? "" :
                        <React.Fragment>
                            <ToolkitProvider
                            keyField={(row, index) => index.toString()}
                            data={this.state.Data}
                            columns={columns}
                            columnToggle
                            search
                            exportCSV
                            >
                                {
                                    props => (
                                        <div>
                                            <div style={{ float: "left" }}>
                                                <MyExportCSV {...props.csvProps}/>
                                            </div>
                                            {/* <div style={{ float: "left" }}>
                                                <SettingBtn {...props.csvProps} />
                                            </div> */}
                                        
                                            <div className="width100">
                                                <BootstrapTable  id="TenantsSearch"
                                                    {...props.baseProps}
                                                    classes = "mytableclass "
                                                    hover
                                                    rowStyle={{ fontSize: "13px", "border-bottom-style": "" }}
                                                    style={{ "border-top-style": "" }}
                                                    rowClasses="custom-row-class"
                                                    noDataIndication={this.state.tableLoader}
                                                    bordered={true} 
                                                    trClassName='table-format'
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                            <Col sm="6">
                            </Col>
                            <Col sm="6">
                                <div className="pull-right" style={{ marginTop: "10px" }} >
                                    <Pagination
                                        totalRecords={this.state.TotalTenantData}
                                        pageLimit={parseInt(this.state.pageLimit)}
                                        pageNeighbours={10}
                                        onPageChanged={(data) => this.onPageChanged(data)}
                                        currentPage={this.state.currentPage}
                                    />
                                    
                                </div>
                            </Col>
                        </React.Fragment>
                    }
                </Fragment>


                {data1.issuperadmin === "y" && this.state.showAddNewTenantButton && helpers.getCookie("_platformWritePersmission") === "true" && !window.location.href.includes("/AssetExplorer") ?
                    <div style={{ marginTop: "20px", textAlign: "left" }}>
                        <ReactTooltip />
                        <Button id ="platformTenantsAddNewButton" className={"cancelButton"} size="sm" color="success" onClick={this.showAddTenantModal} >Add New Tenant</Button> 
                        &emsp;
                        <i style={{ "font-size": "14px" }} className="fa fa-info-circle cursor-point" data-tip={"Note:- Tenant creation requires a minimum of 1 minute."} data-effect="solid" data-place="bottom" ></i>
                    </div> : ""}
                {/* <ToastContainer /> */}

                <Modal size="lg" fade={false} isOpen={this.state.showAddTenantModal} toggle={this.closeAddTenantModal} backdrop="static">
                    <ModalHeader id="TenantsAddNewTenant" toggle={this.closeAddTenantModal} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Add New Tenant</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Name*</Label>

                                    <Input  id ="platformTenantsName" onChange={this.handleEditTenantName}  onKeyPress={this.handleKeyPress} value={this.state.newEditTenantName} style={inputStyle} type="text" placeholder="Tenant Name" maxLength="41" />
                                    <div className="errorMessage">{this.state.NameErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Description*</Label>
                                    <Input id ="platformTenantsDescription" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleTenantDescription} type="text" placeholder="Tenant Description" />
                                    <div className="errorMessage">{this.state.DescriptionErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Industry*</Label>
                                    <Input id ="platformTenantsIndustry"  onKeyPress={this.handleKeyPress} onChange={this.handleTenantIndustry} style={inputStyle} type="select" defaultValue="">
                                        <option value="" disabled>Select</option>
                                        {this.state.industryArr.map((data, i) => (
                                            <option value={data.id} key={i}>{data.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.industryError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Expiry Date*</Label>
                                    <Input id ="platformTenantsExpiryDate" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleTenantExpiryDate} max="9999-12-31" type="date" />
                                    <div className="errorMessage">{this.state.ExpiryDateErr}</div>
                                </FormGroup>
                            </Col>

                            {helpers.getCookie('build_type') === "mssp" ?
                                <Col sm="12">
                                    <FormGroup>
                                        <Label style={{ color: fontColor }}>Host Type*</Label>
                                        <Input id ="platformTenantsHostType" value={this.state.selectedTenantHostType}  onKeyPress={this.handleKeyPress} type="select" style={inputStyle} onChange={this.handleTenantHostType}>
                                            <option disabled value="">---Select---</option>
                                            <option value="local">Local</option>
                                            <option value="remote">Remote</option> 
                                            {/* Kavya changes tenant - enabled "Remote" option - 21/06/2023 */}
                                        </Input>
                                        <div className="errorMessage">{this.state.tenantHostTypeerror}</div>
                                    </FormGroup>
                                </Col>
                                : ""}
                        </Row>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id ="generalTenantsAddSaveBtn" size="sm" color="success" style={{ align: "right" }} onClick={this.addNewTenant} disabled={this.state.isValidTenantName === false || !this.state.newEditTenantName || !this.state.newTenantDescription || !this.state.newTenantIndustry || !this.state.newTenantExpiryDate || (helpers.getCookie('build_type') !== "enterprise" && !this.state.selectedTenantHostType)}>Save</Button>
                            <Button id ="generalTenantsAddCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeAddTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="lg" fade={false} isOpen={this.state.showEditTenantModal} toggle={this.closeEditTenantModal} backdrop="static">
                    <ModalHeader toggle={this.closeEditTenantModal} style={modalStyle}><span style={{ color: fontColor, fontSize: "20px" }}>Manage Tenant</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Name*</Label>
                                    <Input id="TenantsTenantName" value={this.state.newEditTenantName} onChange={this.handleEditTenantName}  onKeyPress={this.handleKeyPress} style={inputStyle} type="text" placeholder="Tenant Name" maxLength="41" disabled/>
                                    <div className="errorMessage">{this.state.NameErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Description*</Label>
                                    <Input id="TenantsTenantDescription" style={inputStyle} value={this.state.newEditTenantDescription}  onKeyPress={this.handleKeyPress} onChange={this.handleEditTenantDescription} type="text" placeholder="Tenant Description" />
                                    <div className="errorMessage">{this.state.DescriptionErr}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Industry*</Label>
                                    <Input id="TenantsIndustry" type="select" value={this.state.newEditTenantIndustry}  onKeyPress={this.handleKeyPress} onChange={(e) => this.handleEditTenantIndustry(e)} style={inputStyle} >
                                        <option value="" disabled>Select</option>
                                        {this.state.industryArr.map((data, i) => (
                                            <option key={i} value={data.id}>{data.name}</option>
                                        ))}
                                    </Input>
                                    <div className="errorMessage">{this.state.industryError}</div>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup>
                                    <Label style={{ color: fontColor }}>Expiry Date*</Label>
                                    <Input id="TenantsDate" style={inputStyle} value={this.state.newEditTenantExpiryDate}  onKeyPress={this.handleKeyPress} onChange={this.handleEditTenantExpiryDate} max="9999-12-31" type="date" />
                                    <div className="errorMessage">{this.state.ExpiryDateErr}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id="generalTenantsEditSaveBtn" type="submit" onClick={this.updateTenant} size="sm" color="success" style={{ align: "right" }} disabled={this.state.isValidTenantName == false || !this.state.newEditTenantDescription || !this.state.newEditTenantIndustry || !this.state.newEditTenantExpiryDate || this.state.disabledupdate}>Update</Button>
                            <Button id="generalTenantsEditCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeEditTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="md" fade={false} isOpen={this.state.showDeleteCategoryModal} toggle={this.closeDeleteTenantModal} backdrop="static">
                    <ModalHeader toggle={this.closeDeleteTenantModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Message</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <h5 style={{ color: fontColor, fontSize: "20px" }}>Delete Tenant {this.state.name} ?</h5>
                        <div className="pull-right" style={{ marginTop: "20px" }}>
                            <Button id="TenantsDelete" className={"buttonColor"} size="sm" color="danger" onClick={this.deleteTenant}>Delete</Button>
                            <Button id="TenantsClose" className={"cancelButton"} size="sm" onClick={this.closeDeleteTenantModal}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Show Tenant Backup Modal */}
                <Modal size="md" fade={false} isOpen={this.state.showTenantBackupModal} toggle={this.closeTenantBackupModal} backdrop="static">
                    <ModalHeader toggle={this.closeTenantBackupModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Backup</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <FormGroup style={{ color: "black" }}>
                            <Input value={this.state.selectedBackupOption}  onKeyPress={this.handleKeyPress} type="select" style={inputStyle} onChange={this.handleBackupChangeSelect}>
                                <option disabled value="">---Select---</option>
                                <option value="All">All</option>
                                <option value="Configuration">Configuration</option>
                                <option value="Data">Data</option>
                            </Input>
                        </FormGroup>
                        <Button size="sm" id="TenantBackupBtn" onClick={this.doMSSPTenantBackup} color="success" type="submit" style={{ float: "left", color: "white" }} disabled={!this.state.backupValid} >Backup Now </Button>
                        <Button id="TenantsCloseBtn" className={"cancelButton "} size="sm" onClick={this.closeTenantBackupModal}>Close</Button>
                    </ModalBody>
                </Modal>

                {/* Show Tenant Restore Modal*/}
                <Modal size="md" fade={false} isOpen={this.state.showTenantRestoreModal} toggle={this.closeTenantRestoreModal} backdrop="static">
                    <ModalHeader toggle={this.closeTenantRestoreModal} style={modalStyle}>
                        <span style={{ color: fontColor, fontSize: "20px" }}>Restore</span></ModalHeader>
                    <ModalBody style={modalStyle}>
                        <Input value={this.state.selectedRestoreOption} type="select" id="TenantsRestoreoption" style={inputStyle}  onKeyPress={this.handleKeyPress} onChange={this.handleRestoreChangeSelect}>
                            <option disabled value="">---Select---</option>
                            <option value="All">All</option>
                            <option value="Configuration">Configuration</option>
                            <option value="Data">Data</option>
                        </Input>
                        <br />
                        <h6 style={{ color: fontColor }}>{this.state.notificationMessage}</h6>
                        <Input style={{ border: "1px solid gray" }} type="file"  onKeyPress={this.handleKeyPress} onChange={this.handleRestore} accept=".zip" />
                        <br />
                        <Button size="sm" id="TenantRestoreBtn" onClick={this.doRestore} color="success" type="submit" style={{ float: "left", color: "white" }} disabled={!this.state.restoreValid} >Restore Now </Button>
                        <Button id="TenantsCloseBtn1" className={"cancelButton "} size="sm" onClick={this.closeTenantRestoreModal}>Close</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({authenticateFileName:authenticateFileName}, dispatch);
}

const exportDashboard = withRouter(connect(mapStateToProps, mapDispatchToProps)(Tenants));
export default exportDashboard;
