export default function noCodeReducer(state = {}, action) {
    switch (action.type) {
        case "getTaskInputFields":
            return {taskInputFields: action.payload}
        
        case "getnewlabels":
            return { casefieldData: action.payload }
            
        }
       
        return state
}