export default function csamQueryDataReducer(state = {}, action) {
    switch (action.type) {
        case "getSearchQueries":
            return { searchQueryData: action.payload }

        case "assetcount":
            return { CsamCloudData: action.payload }

        case "listofasset":
            return { ListofAsset: action.payload }

        case "individualasset":
            return { IndividualAsset: action.payload }

        case "infoofindividualdata":
            return { IndividualInfoAsset: action.payload }
        }
        return state
    }