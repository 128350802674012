export default function userManageReducer(state = {}, action) {
    switch (action.type) {
        case "getConfigurationVariables":
            return { configdata: action.payload }

        case "getLoadUserData":
            return { loaduserdata: action.payload }

        case "getTenantData":
            return { tenantdata: action.payload }

        case "getRolesData":
            return { roledata: action.payload }

        case "getModulesData":
            return { moduledata: action.payload }

        case "getConfigurationVariablesData":
            return { configvariabledata: action.payload }

        case "getADGroupsData":
            return { adgroupsdata: action.payload }

        case "getLDAPGroupsData":
            return { ldapgroupsdata: action.payload }

        case "getAllGroupsData":
            return { groupdata: action.payload }
        
        case "roleUpdated":
            return { roleUpdated: action.payload }
        }   
        return state
    }


   
       