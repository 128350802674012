import React, { Suspense } from 'react';
import './App.css';
import { applyMiddleware, createStore, combineReducers } from "redux";
import reducer from "./reducer/index";
import { Provider } from "react-redux";
import { Router, Route } from 'react-router';
import { routerReducer } from 'react-router-redux';
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Login from "./Components/Login";
import Logout from "./Components/Logout";

import AssetSetting from "./Components/AssetSetting";
import thunk from "redux-thunk";
import License from "./Components/License2";
import ChangePassword from "./Components/ChangePassword";
import Sockets from "./Components/Sockets";
import AddFirstTenant from "./Components/AddFirstTenant";

import ForgotPassword from "./Components/ForgotPassword";
import SessionExpired from "./Components/SessionExpired";
import ActivateUser from "./Components/ActivateUser";
import TISearch from "./Components/TISearch";
import Approval from "./Components/Approval";
import PageNotFound from "./Components/PageNotFound";
import Menu from "./Components/Menu";

import Loader from "./Components/Loader";


import helpers from "./helpers";


const Tasks = React.lazy(() => import('./Components/Tasks'));
const CasePanel = React.lazy(() => import('./Components/CasePanel'));
const PlaybookList = React.lazy(() => import('./Components/PlaybookList'));
const AppsIntegrations = React.lazy(() => import('./Components/AppsIntegrations'));
const Platform = React.lazy(() => import('./Components/PlatformV2'));
const Dashboard = React.lazy(() => import('./Components/Dashboard'));
const CustomDashboard = React.lazy(() => import('./Components/CustomDashboard'));
const DrilldownCases = React.lazy(() => import('./Components/DrilldownCases'));
const IndicatorBrowser = React.lazy(() => import('./Components/TIPComponents/IndicatorBrowser'));
const IndicatorOverviewHome = React.lazy(() => import('./Components/TIPComponents/IndicatorOverviewHome'));
const ImportIndicator = React.lazy(() => import('./Components/TIPComponents/ImportIndicator'));
const NewPlaybook = React.lazy(() => import('./Components/NewPlaybook'));
const Reports = React.lazy(() => import('./Components/DashboardReports'));
const AssetDetails = React.lazy(() => import('./Components/AssetDetails'));
const UserDetailHome = React.lazy(() => import('./Components/UserDetailHome'));
// const AssetExplorerHome = React.lazy(() => import('./Components/AssetExplorerHome'));
const CSAMExplorerHome = React.lazy(() => import('./Components/CSAMComponents/CSAMExplorerHome'));
const CSAMCloudHome = React.lazy(() => import('./Components/CSAMComponents/CSAMCloudHome'));
const InventoryHome = React.lazy(() => import('./Components/CSAMComponents/InventoryHome'));
const Classifiers = React.lazy(() => import('./Components/Classifiers'));
const IncidentMapper = React.lazy(() => import('./Components/IncidentMapper'));
const IndicatorsHome = React.lazy(() => import('./Components/IndicatorsHome'));

const Top5SuspiciousEmailList = React.lazy(() => import('./Components/Top5SuspiciousEmailList'));
const Top5SuspiciousIPList = React.lazy(() => import('./Components/Top5SuspiciousIPList'));
const Top5SuspiciousFQDNList = React.lazy(() => import('./Components/Top5SuspiciousFQDNList'));
const MostVulnerableAssetsList = React.lazy(() => import('./Components/MostVulnerableAssetsList'));
const CaseDeduplicationList = React.lazy(() => import('./Components/CaseDeduplicationList'));
const PotentialExploitAvailabilityList = React.lazy(() => import('./Components/PotentialExploitAvailabilityList'));
const PotentialExploitAvailabilityRFList = React.lazy(() => import('./Components/PotentialExploitAvailabilityRFList'));
const AssetUpdateInfoList = React.lazy(() => import('./Components/AssetUpdateInfoList'));
const ConfigurationPostureList = React.lazy(() => import('./Components/ConfigurationPostureList'));

const SightingsList = React.lazy(() => import('./Components/TIPComponents/SightingsList'));
const IndicatorWithMultipleSourceList = React.lazy(() => import('./Components/TIPComponents/IndicatorWithMultipleSourceList'));
const LatestContributionList = React.lazy(() => import('./Components/TIPComponents/LatestContributionList'));
const MyHistoryList = React.lazy(() => import('./Components/TIPComponents/MyHistoryList'));
const LastNoteList = React.lazy(() => import('./Components/TIPComponents/LastNoteList'));
const TyposquattingList = React.lazy(() => import('./Components/TIPComponents/TyposquattingList'));
const TopProvidersList = React.lazy(() => import('./Components/TIPComponents/TopProvidersList'));
const IndicatorSourcesList = React.lazy(() => import('./Components/TIPComponents/IndicatorSourcesList'));


// const AssetsTagList = React.lazy(() => import('./Components/AssetsTagList'));
const AddReports = React.lazy(() => import('./Components/CustomReports'));
// const MarketPlaceHome = React.lazy(() => import('./Components/MarketplaceComponents/MarketPlaceHome'));
// const CloudMonitoring = React.lazy(() => import('./Components/CloudMonitoring'));
const TIPDashboard = React.lazy(() => import('./Components/TIPDashboard'));
// const AssetDashboard = React.lazy(() => import('./Components/Assetdashboard'));
const CSAMDashboard = React.lazy(() => import('./Components/CSAMComponents/CSAMdashboard'));
const CrossTenantCasesList = React.lazy(() => import('./Components/CrossTenantCasesList'));
const CrossTenantAssignedCasesList = React.lazy(() => import('./Components/CrossTenantAssignedCasesList'));
const CrossTenantCases = React.lazy(()=>import('./Components/CrossTenantCases'));
const CustomUtilsPython = React.lazy(() => import('./Components/CustomUtilsPython'))
const ServerDataTable = React.lazy(()=> import('./Components/ServerDataTable'));

const QuillEditor = React.lazy(()=> import('./Components/QuillEditor'));
const NewsFeed = React.lazy(() => import('./Components/TIPComponents/NewsFeed'));
const TipThreatActor = React.lazy(() => import('./Components/TIPComponents/TipThreatActor'));
const TipThreatActorComparison = React.lazy(() => import('./Components/TIPComponents/TipThreatActorComparison'));
const CSAMOverviewHome = React.lazy(() => import('./Components/CSAMComponents/CSAMOverviewHome'));
const USPMDashboard = React.lazy(() => import('./Components/USPMComponents/USPMdashboard'));
const ImportAsset = React.lazy(()=> import('./Components/CSAMComponents/ImportAsset'))
// const MarketPlaceHome = React.lazy(() => import('./Components/MarketplaceComponents/MarketPlaceHome'));

const WidgetList = React.lazy(()=>import('./Components/WidgetList'))

// const middleware = applyMiddleware(thunk);//createLogger()
// const store = createStore(reducer, middleware);

const store = createStore(combineReducers({ reducer, routing: routerReducer }), applyMiddleware(thunk));//createLogger()


const ComponentsWithMenuBar = ({ match }) => {
	return (
		<div>
			<Menu />
			{helpers.getCookie("tenantcode") != "master" ?
				<Switch>
					<Route path="/home" render={props => <Dashboard {...props} />} />
					<Route path="/reports" render={props => <Reports {...props} />} />
					<Route path="/dashboard" render={props => <CustomDashboard {...props} />} />
					<Route path="/cases" render={props => <DrilldownCases {...props} />} />
					<Route path="/Apps" render={props => <AppsIntegrations {...props} />} />
					<Route path="/Platform" render={props => <Platform {...props} />} />
					<Route path="/AssetSetting" render={props => <AssetSetting {...props} />} />
					<Route path="/CasePanel" render={props => <CasePanel {...props} />} />
					<Route path="/Playbook" render={props => <PlaybookList {...props} />} />
					 <Route path="/addplaybook" render={props => <NewPlaybook {...props} />} />
					<Route path="/IndicatorsHome" render={props => <IndicatorsHome {...props} />} />
					<Route path="/List" render={props => <WidgetList {...props} />} />
					<Route path="/IndicatorOverview" render={props => <IndicatorOverviewHome {...props} />} />
					<Route path="/import-indicator" render={props => <ImportIndicator {...props} />} />
					<Route path="/importAsset" render={props => <ImportAsset {...props} />} />

					{/* <Route path="/AssetExplorer" render={props => <AssetExplorerHome {...props} />} /> */}
					<Route path="/CSAMExplorer" render={props => <CSAMExplorerHome {...props} />} />
					<Route path="/CSAMCloud" render={props => <CSAMCloudHome {...props} />} />
					<Route path="/Inventory" render={props => <InventoryHome {...props} />} />
					<Route path="/UserDetail" render={props => <UserDetailHome {...props} />} />
					
					<Route path="/AssetDetails" render={props => <AssetDetails {...props} />} />
					<Route path="/tisearch" render={props => <TISearch {...props} />} />
					<Route path="/Top5SuspiciousEmailList" render={props => <Top5SuspiciousEmailList {...props} />} />
					<Route path="/Top5SuspiciousIPList" render={props => <Top5SuspiciousIPList {...props} />} />
					<Route path="/Top5SuspiciousFQDNList" render={props => <Top5SuspiciousFQDNList {...props} />} />
					<Route path="/AssetUpdateInfoList" render={props => <AssetUpdateInfoList {...props} />} />
					<Route path="/SightingsList" render={props => <SightingsList {...props} />} />
					<Route path="/MyHistoryList" render={props => <MyHistoryList {...props} />} />
					<Route path="/ConfigurationPostureList" render={props => <ConfigurationPostureList {...props} />} />
					<Route path="/IndicatorWithMultipleSourceList" render={props => <IndicatorWithMultipleSourceList {...props} />} />
					<Route path="/LatestContributionList" render={props => <LatestContributionList {...props} />} />
					<Route path="/LastNoteList" render={props => <LastNoteList {...props} />} />
					<Route path="/TyposquattingList" render={props => <TyposquattingList {...props} />} />
					<Route path="/TopProvidersList" render={props => <TopProvidersList {...props} />} />
					<Route path="/IndicatorSourcesList" render={props => <IndicatorSourcesList {...props} />} />
					<Route path="/PotentialExploitAvailabilityList" render={props => <PotentialExploitAvailabilityList {...props} />} />
					<Route path="/PotentialExploitAvailabilityRFList" render={props => <PotentialExploitAvailabilityRFList {...props} />} />
					<Route path="/MostVulnerableAssetsList" render={props => <MostVulnerableAssetsList {...props} />} />
					<Route path="/AddReports" render={props => <AddReports {...props} />} />
					<Route path="/CaseDeduplicationList" render={props => <CaseDeduplicationList {...props} />} />
					<Route path="/Classifiers" render={props => <Classifiers {...props} />} />
					<Route path="/IncidentMapper" render={props => <IncidentMapper {...props} />} />
					{/*<Route path="/marketplace" render={props => <MarketPlaceHome {...props} />} />*/}
					{/* <Route path="/Cloudmonitoring" render={props => <CloudMonitoring {...props} />} />*/}
					<Route path="/TIPDashboard" render={props => <TIPDashboard {...props} />} />
					{/* <Route path="/Assetdashboard" render={props => <AssetDashboard {...props} />} /> */}
					<Route path="/CSAMdashboard" render={props => <CSAMDashboard {...props} />} />
					<Route path="/utils" render={props => <CustomUtilsPython {...props} />} />  
					<Route path="/myCases" render={props => <ServerDataTable {...props} />} />
					{/* //Author - Gayathri Changes done to Redirect crosstenantcases to home - 21-june */}
					<Route path="/CrossTenantCases" render={props => <CrossTenantCases {...props} />} />
					<Route path = "/tiparticle" render = {props => <QuillEditor {...props}/>}/>
                    <Route path="/feeds" render={props => <NewsFeed {...props} />} />
					<Route path="/ThreatActor" render={props => <TipThreatActor {...props} />} />
					<Route path="/ThreatActorComparison" render={props => <TipThreatActorComparison {...props} />} />
					<Route path="/CSAMOverviewHome" render={props => <CSAMOverviewHome {...props} />} />
					<Route path="/USPMDashboard" render={props => <USPMDashboard {...props} />} />
					<Route path="/" render={props => <PageNotFound {...props} />} />
				</Switch>
				:
				<Switch>
					<Route path="/home" render={props => <Dashboard {...props} />} />
					<Route path="/dashboard" render={props => <CustomDashboard {...props} />} />
					<Route path="/CasePanel" render={props => <CasePanel {...props} />} />
					<Route path="/CrossTenantCasesList" render={props => <CrossTenantCasesList {...props} />} />
					<Route path="/CrossTenantAssignedCasesList" render={props => <CrossTenantAssignedCasesList {...props} />} />
					<Route path="/cases" render={props => <DrilldownCases {...props} />} />
					<Route path="/reports" render={props => <Reports {...props} />} />
					<Route path="/AddReports" render={props => <AddReports {...props} />} />
					<Route path="/CrossTenantCases" render={props => <CrossTenantCases {...props} />} />
					<Route
						path="/"
						render={() => {
							return (<Redirect to="/home" />)
						}}
					/>
				</Switch>
			}
		</div>
	);
};

const App = () => (
	<div>
		<Suspense fallback={<Loader.mainpageloader />}>
			<div className="App SecuraaRootWrapper">
				<Provider store={store}>
					<BrowserRouter basename="/" getUserConfirmation={customConfirm}>
						<Sockets type="securaa"/>
						<Switch>
							<Route exact path="/" render={props => <Login {...props} />} />
							<Route path="/license" render={props => <License {...props} />} />
							<Route path="/settings" render={props => <ChangePassword {...props} />} />
							<Route path="/configure" render={props => <AddFirstTenant {...props} />} />
							<Route path="/login" render={props => <Login {...props} />} />
							<Route path="/logout" render={props => <Logout {...props} />} />
							<Route path="/tasks" render={props => <Tasks {...props} />} />
							<Route path="/tip" render={props => <IndicatorBrowser {...props} />} />
							<Route path="/approvals" render={props => <Approval {...props} />} />
							<Route path="/forgotpassword" render={props => <ForgotPassword {...props} />} />
							<Route path="/tokenexpired" render={props => <SessionExpired {...props} />} />
							<Route path="/activateuser" render={props => <ActivateUser {...props} />} />
							<Route path="/" render={ComponentsWithMenuBar} />
						</Switch>
					</BrowserRouter>
				</Provider>
			</div>
		</Suspense>
	</div>
);
/* chandru changes - SDEV-3872 the user is able to use the same credentials on multiple system.  -Start */
const customConfirm = (message, callback) => {
	if (window.confirm(message)) {
		callback(true);
	  } else {
		if(helpers.getLocalStorage("isDuplicatelogin") === "true"){
			callback(true);
		}else{
			callback(false);
		}
		
	  }
  };
  /* chandru changes - SDEV-3872 the user is able to use the same credentials on multiple system.  -End */
export default App;
