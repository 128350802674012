export default function csamwidgetsReducer(state = {}, action) {
    switch (action.type) {
       case "getCsamCloudWidgetsData":
           return { CsamCloudWidgetsData: action.payload }

       case "getAlertCases" :
           return{alertcasesdata : action.payload}

        case "getConfigData" :
           return{configdata : action.payload}

        case "getBusinessHirerchayData" :
           return{businesshirerchaydata : action.payload}

        case "getupdateBusinessHierarchy" :
           return{updatebusinesshirerchaydata : action.payload}

         case "getUpdateTags" :
           return{updatetagsdata : action.payload}
           
         case "getComments" :
           return{commentsdata : action.payload}
       }
	   
    
    return state
}