export default function tipWidgetReducer(state = {}, action) {
    switch (action.type) {
        case "getTotalSourcesData":
            return { tipsourceData: action.payload }

        case "getTotalIndicatorsData":
            return { tipindicatorData: action.payload }
            
        case "getTopProvidersData":
            return { topprovidersData: action.payload }

        case "getSourcesDataTip":
            return { topsourcesData: action.payload }

        case "getResourceList":
            return { resourceData: action.payload }

        case "getNoteData":
            return { lastnoteData: action.payload }
        }
        return state
    }
   