import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Input, Col, Button, InputGroup, InputGroupAddon, Alert } from "reactstrap";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
// import screenSaver from "../images/download.jpeg";
import screenSaver from "../images/screensaver2.jpg";
import axios from "axios";
const uuidv1 = require('uuid/v1');
const md5 = require("md5");
const restURL = helpers.restURL;
let url = window.location.origin;
//let url = "http://localhost/downloads/logo/logo.png";
url = url === "http://localhost:3000" ? 'http://localhost' : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();
var myWindow;

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loadingIcon: "",
            showError: "none",
            showLoginForm: "show",
            showForgotPasswordForm: "hide",
            passwordFieldType: "password",
            isManagerRunning: true,
            intervalID: null,
            intervalInstallationID:null,
            showSAMLForm: "hide",
            samlClassName: "",
            errorMessage: "",
            samlRequestID: "",
            samlBtnDisabled: false,
            samlIntegrationCount: 0,
            ldapIntegrationCount: 0,
            alertType: "",
            showAlert: "hide",
            showLDAPForm: "hide",
            loginDisable: false,
            logintype: "",logo:"",timestamp:Math.floor(100000000 + Math.random() * 900000000)
        };

        this.validateForm = this.validateForm.bind(this);
        this.buildDefaultCredentails = this.buildDefaultCredentails.bind(this);
        this.processData = this.processData.bind(this);
        this.validateForgotPasswordForm = this.validateForgotPasswordForm.bind(this);
        this.checkManagerIsRunnig = this.checkManagerIsRunnig.bind(this);
    }

    buildDefaultCredentails(_userData) {
        helpers.setCookie("_platformWritePersmission", false);
        helpers.setCookie("_integrationWritePersmission", false);
        helpers.setCookie("_handbookWritePersmission", false);
        helpers.setCookie("_quickCaseWritePersmission", false);
        helpers.setCookie("_metricsWritePermission", false);
        helpers.setCookie("_riskWritePermission", false);
        helpers.setCookie("_socWritePermission", false);
        helpers.setCookie("_socTabPermission", false);
        helpers.setCookie("_playbookWritePermission", false);
        helpers.setCookie("_dashboardPersmission", false);
        helpers.setCookie("_taskPersmission", false);
        helpers.setCookie("_reportPersmission", false);
        helpers.setCookie("_casepanelPermission", false);
        helpers.setCookie("_utilsPermission", false);
        helpers.setCookie("_smtpConfiguration", false);
        
        helpers.setLocalStorage("selecteddashboard", "Cases");
        helpers.setCookie('currentDashboardSelected', "default", 1);
        for (let m = 0; m < _userData.modules.length; m++) {
            if (_userData.modules[m].modulename === "Platform Management" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_platformWritePersmission", true);
            } else if (_userData.modules[m].modulename === "Integration Management" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_integrationWritePersmission", true);
            } else if (_userData.modules[m].modulename === "Apps" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_integrationWritePersmission", true);
            } else if (_userData.modules[m].modulename === "Tasks" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_taskPersmission", true);
            } else if (_userData.modules[m].modulename === "Automation" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_handbookWritePersmission", true);
            } else if (_userData.modules[m].modulename === "Cases Dashboard and Module" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_quickCaseWritePersmission", true);
            } else if (_userData.modules[m].modulename === "Metrics Dashboard" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_metricsWritePermission", true);
            } else if (_userData.modules[m].modulename === "Risk Dashboard" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_riskWritePermission", true);
            } else if (_userData.modules[m].modulename === "SOC Dashboard" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_socWritePermission", true);
            } else if (_userData.modules[m].modulename === "SOC Dashboard") {
                helpers.setCookie("_socTabPermission", true);
            } else if (_userData.modules[m].modulename === "Playbooks" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_playbookWritePermission", true);
            } else if (_userData.modules[m].modulename === "Dashboard" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_dashboardPersmission", true);
            } else if (_userData.modules[m].modulename === "Reports" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_reportPersmission", true);
            }else if (_userData.modules[m].modulename === "Case Panel" && _userData.modules[m].readwrite === "y") {
                helpers.setCookie("_casepanelPermission", true);
            }else if(_userData.modules[m].modulename === "Utils" && _userData.modules[m].readwrite === "y"){
                helpers.setCookie("_utilsPermission", true);
            }
        }
        if(_userData.configSmtp === true){
            helpers.setCookie("_smtpConfiguration", true);
        }
    }

    componentWillMount() {
        let userData = helpers.getSessionStorage("userData");
        if (userData !== "" && userData !== null && typeof userData !== 'undefined') {
            let data = JSON.parse(userData);
            if (!data.hassecuraalicence) {
                this.props.history.push("/license");
            } else {
                this.props.history.push("/home");
            }
        } else {
            document.body.style.setProperty("background-color", `#272b35`);
            //document.body.style.setProperty("background-image", `url(${screenSaver})`);
        }
        helpers.setSessionStorage("theme", "dark");
    }

    componentDidMount() {
        //Kavya Changes : setting value to false to reset value to default on logout - 05/07/2023
        helpers.setLocalStorage("isDuplicatelogin","false");
        //this loginexpiry is set to true so that when the user logins it will be set to true so that the expiry popup shows only when the user logins for first time
        helpers.setLocalStorage("loginexpiry", "true")
        if (window.location.hostname !== 'localhost') {
            this.checkInstalling();
            this.checkManagerIsRunnig();
       }

            let url = window.location.origin;
        //let url = "http://localhost/downloads/logo/logo.png";
        url = url === "http://localhost:3000" ? 'http://localhost' : url;
        url = url + "/downloads/logo/logo.png";
        url = url.toString();
        this.setState({logo:url})
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.samlresponse !== 'undefined' && nextProps.samlresponse !== null && Object.keys(nextProps.samlresponse).length > 0) {
            this.processSamlResponse(nextProps.samlresponse);
        }

        /*chandru changes -logo update reducer */
		 if(typeof nextProps.logoupdatedata !== 'undefined' && nextProps.logoupdatedata !="" && nextProps.logoupdatedata !== null){
            if(nextProps.logoupdatedata !== this.props.logoupdatedata){
				this.setState({timestamp:new Date().getTime()})
            }
        }
	
    }

    resetErrorMessage = () => {
        this.setState({
            alertType: "",
            errorMessage: "",
            showError: "none",
            showAlert: "hide"
        });
    }

    setErrorMessage = (alertType, errorMessage, showError) => {
        this.setState({
            alertType,
            errorMessage,
            showError,
            showAlert: "show"
        });
    }

    showRegularLoginForm = () => {
        this.resetErrorMessage();
        this.setState({
            showLDAPForm: "hide",
            showSAMLForm: "hide",
            showForgotPasswordForm: "hide",
            showLoginForm: "show",
            logintype: "",
            email: "",
            password: "",
        });
    }

    // showSAMLLoginForm = () => {
    //     this.resetErrorMessage();
    //     this.setState({
    //         showLDAPForm: "hide",
    //         showSAMLForm: "show",
    //         showForgotPasswordForm: "hide",
    //         showLoginForm: "hide",
    //         logintype: ""
    //     });
    // }

    showLDAPLoginForm = () => {
        this.resetErrorMessage();
        this.setState({
            showLDAPForm: "show",
            showSAMLForm: "hide",
            showForgotPasswordForm: "hide",
            showLoginForm: "hide",
            logintype: "ldap"
        });
    }

    processSamlResponse = (responseData) => {
        if (responseData.reponseUUID === this.state.samlRequestID) {

            setTimeout(() => {
                myWindow.close();
                this.setState({ samlClassName: "fa fa-check", samlBtnDisabled: false });
                this.setErrorMessage("success", "Login success.! Redirecting...", "none");
            }, 1000);

            setTimeout(() => {
                if (responseData.success) {
                    this.buildDefaultCredentails(responseData.data);
                    this.processData(responseData.data);
                } else {
                    if (responseData.displaymessage !== "") {
                        this.setErrorMessage("danger", responseData.displaymessage, "inline-block");
                    } else {
                        this.setErrorMessage("danger", "Failed to login. Try again.", "inline-block");
                    }
                    this.setState({ samlClassName: "", samlBtnDisabled: false });
                    setTimeout(() => {
                        this.resetErrorMessage();
                    }, 5000);
                }
            }, 2000);
        }
    }

    checkManagerIsRunnig() {
        let url = restURL + "?module=manager&rest=testmanager";
        axios({
            method: "GET",
            url: url,
            headers: { access_token: "", jwt_token: "", "Content-Type": "application/json; charset=utf-8" },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                this.getSAMLIntegartionCount();
                this.getldapIntegartionCount();
                this.setState({ isManagerRunning: true });
                this.resetErrorMessage();
                if (this.state.intervalID !== null) {
                    clearInterval(this.state.intervalID);
                }
            } else {
                this.setState({ isManagerRunning: false });
                let errorMessage = <h6 style={{ color: "white", marginTop: "3px", marginBottom: "10px" }}>
                    <i className="fa fa-refresh fa-spin"></i> Securaa is booting up. Please wait...
                </h6>
                this.setErrorMessage("info", errorMessage, "none");

                if (this.state.intervalID === null) {
                    let intervalID = setInterval(() => {
                        if (!this.state.isManagerRunning) {
                            this.checkManagerIsRunnig();
                        }
                    }, 2000);
                    this.setState({ intervalID });
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    checkInstalling=()=>{
        let url = restURL + "?module=manager&rest=checkupgradeprogress";
        axios({
            method: "GET",
            url: url,
            headers: { access_token: "", jwt_token: "", "Content-Type": "application/json; charset=utf-8" },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                if (res.data.data.installation_progress === "inprogress") {

                    this.setState({ isManagerRunning: false });
                    let errorMessage = <h6 style={{ color: "white", marginTop: "3px", marginBottom: "10px" }}>
                        <i className="fa fa-refresh fa-spin"></i> Securaa is Installing. Please wait...
                    </h6>
                    this.setErrorMessage("info", errorMessage, "none");
                    if (this.state.intervalInstallationID === null) {
                    let intervalInstallationID = setInterval(() => {
                        //this.checkManagerIsRunnig();
                            this.checkInstalling();
                       
                    }, 2000); 
                    this.setState({ intervalInstallationID });
                }   
                    
                }else if(res.data.data.installation_progress === "completed"){
                    if (this.state.intervalInstallationID !== null) {
                        clearInterval(this.state.intervalInstallationID);
                    }
                    this.checkManagerIsRunnig();
                }
            }else{
                if(res.data.displaymessage == "Securaa server is not reachable"){
                    clearInterval(this.state.intervalInstallationID);
                    this.checkManagerIsRunnig();
                }
                
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    getSAMLIntegartionCount = () => {
        let url = restURL + "?module=integrations&rest=getsamlintegrationcount";
        axios({
            method: "GET",
            url: url,
            headers: { access_token: "", jwt_token: "", "Content-Type": "application/json; charset=utf-8" },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                if (res.data.data === 1) {
                    this.setState({ samlIntegrationCount: res.data.data })
                    this.setState({ showSAMLForm: "show", showLoginForm: "hide" });
                } else {
                    this.setState({ showSAMLForm: "hide", showLoginForm: "show" });
                }
            } else {
                if (res.data.displaymessage !== "") {
                    this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                } else {
                    this.setErrorMessage("danger", "Failed to fetch SAML Instances.", "inline-block");
                }
                setTimeout(() => {
                    this.resetErrorMessage();
                }, 5000);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    getldapIntegartionCount = () => {
        let url = restURL + "?module=integrations&rest=getldapintegrationcount";
        axios({
            method: "GET",
            url: url,
            headers: { access_token: "", jwt_token: "", "Content-Type": "application/json; charset=utf-8" },
            responseType: "json"
        }).then(res => {

            if (res.data.success) {
                if (res.data.data === 1) {
                    this.setState({ ldapIntegrationCount: res.data.data })
                    //this.setState({ showSAMLForm: "show", showLoginForm: "hide" });
                } else {
                    //this.setState({ showSAMLForm: "hide", showLoginForm: "show" });
                }
            } else {
                if (res.data.displaymessage !== "") {
                    //  this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                } else {
                    //  this.setErrorMessage("danger", "Failed to fetch SAML Instances.", "inline-block");
                }
                setTimeout(() => {
                    this.resetErrorMessage();
                }, 5000);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    validateForm() {


        let userEmailID = this.state.email;
        let userPassword = this.state.password;
        if (userEmailID === "") {
            this.setErrorMessage("danger", "Email is required", "inline-block");
            this.setState({loginDisable: true});
            setTimeout(() => {
                this.resetErrorMessage();
                this.setState({loginDisable: false}); 
            }, 3000);            
            return
        }

        if (userPassword === "") {
            this.setErrorMessage("danger", "Password is required", "inline-block");
            this.setState({loginDisable: true});
            setTimeout(() => {
                this.resetErrorMessage();
                this.setState({loginDisable: false}); 
            }, 3000);           
            return
        }

        let data = {};
        if (userEmailID.includes("@")) {
            data = { Email: userEmailID, Password: md5(userPassword), Logintype: this.state.logintype, rest: "login", sessionid: uuidv1() };
            this.setState({loginDisable: true});
        } else {
            data = { Email: userEmailID, Password: userPassword, Logintype: this.state.logintype, rest: "login", sessionid: uuidv1() };
        }

        if (userEmailID !== "" && userPassword !== "") {
            this.setState({ loadingIcon: "fa fa-refresh fa-spin" });
            this.resetErrorMessage();

            axios({
                method: "POST",
                url: restURL,
                headers: { "Content-Type": "application/json; charset=utf-8", "access_token": "", "jwt_token": "" },
                data: data,
                responseType: "json"
            }).then(res => {

                if (res.data.success) {
                    let data = res.data.data;
                    // let userData = JSON.stringify(data);
                    this.buildDefaultCredentails(data);
                    this.processData(data);
                    // this.setState({isManagerRunning: false});
                    this.setState({loginDisable: false});
                } else {
                    if (res.data.displaymessage === "No Valid License Found") {
                        this.props.history.push("/license");
                    } else if (res.data.displaymessage === "Securaa server is not reachable") {
                        this.setErrorMessage("danger", "Server not reachable", "inline-block");
                    } else if (res.data.displaymessage.includes("blocked for next")) {
                        this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                    } else if (res.data.displaymessage.toLowerCase().includes("license")) {
                        this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                    } else if(res.data.displaymessage !== null && res.data.displaymessage !== undefined && res.data.displaymessage !== "") {
                        this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                    } else {
                        this.setErrorMessage("danger", "Error in Login", "inline-block");
                    }
                    this.setState({loginDisable: false});
                    // this.setState({isManagerRunning: false});
                    this.setState({ loadingIcon: "" });
                    setTimeout(() => {
                        this.resetErrorMessage();
                    }, 5000);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            this.setState({ loadingIcon: "" });
        }
    }

    validateForgotPasswordForm() {

        let userEmailID = this.state.email;
        if (userEmailID === "") {
            this.setErrorMessage("danger", "Email is required", "inline-block");
            setTimeout(() => {
                this.resetErrorMessage();
            }, 3000);
            return
        }

        if (userEmailID !== "") {
            if (!userEmailID.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                this.setErrorMessage("danger", "Invalid Email ID", "inline-block");
                setTimeout(() => {
                    this.resetErrorMessage();
                }, 3000);
                return
            } else {
                this.resetErrorMessage();
            }
        }

        this.setState({ loadingIcon: "fa fa-refresh fa-spin" });

        let data = {
            email: userEmailID,
            host: window.location.hostname,
            protocol: window.location.protocol,
            rest: "forgotpassword",
            module: "user"
        };

        axios({
            method: "POST",
            url: restURL,
            headers: { "Content-Type": "application/json; charset=utf-8" },
            data: data,
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                this.setState({ loadingIcon: "", email: "" });
                let message = <h6 style={{ color :"#06357a" , marginTop: "3px", marginBottom: "10px" }}>
                    Password reset link has been sent to your mail address
                </h6>
                this.setErrorMessage("primary", message, "none");
            } else {
                this.setState({ loadingIcon: "" });
                //changes made by: Thejas PH
                //date: May 22 2023
                if (res.data.displaymessage == "not found"){
                    this.setErrorMessage("danger", "User not found", "inline-block");
                }else if (res.data.displaymessage !== "")  {
                    this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                } else if (res.data.error !== "") {
                    this.setErrorMessage("danger", res.data.error, "inline-block");
                } else {
                    this.setErrorMessage("danger", "Failed to send Email. Please try again.", "inline-block");
                }
            }
            setTimeout(() => {
                this.resetErrorMessage();
            }, 50000);
        }).catch(function (error) {
            console.log(error);
        });
    }

    processData(data) {
        helpers.setSessionStorage("licenseResponse", JSON.stringify(data.licenseResponse))
        let userData = JSON.stringify(data);
        helpers.setSessionStorage("userData", userData);

        helpers.setCookie('newlogin', "true", 10);
        helpers.setCookie('accesstoken', data.useraccesstoken, 10);
        helpers.setCookie('jwt_token', data.jwttoken, 10);
        helpers.setCookie('companycode', data.companycode, 10);
        helpers.setCookie('companyid', data.companyid, 10);
        helpers.setCookie('userid', data.id, 10);
        helpers.setCookie('displayallusersdata', data.id, 10);
        helpers.setCookie("build_type", data.build_type);
        helpers.setCookie("mac_address", data.mac_address);
        helpers.setCookie("soar_support", data.soar_support);
        helpers.setCookie("avm_support", data.avm_support);
        helpers.setCookie("tip_support", data.tip_support);
        helpers.setCookie("session_expire_time", data.session_expire_time);
        helpers.setCookie("seesion_extension_requested", "first");
        helpers.setCookie("session_extend_time_in_mins", data.session_extend_time_in_mins);
        helpers.setCookie("final_session_modal_shown", "no");
        helpers.setCookie("tip_host", data.tip_host);
        helpers.setCookie('email', data.email);
        if(data.hassecuraalicence){
            helpers.setCookie("license_name",data.licenseResponse.details.name)
        }

        let tenants = data.tenantsinfo;
        helpers.setLocalStorage("tenantsData",JSON.stringify(tenants))
        let groups = typeof data.user_groups === 'undefined' || data.user_groups === null ? [] : data.user_groups;
        let usermodules = data.modules;
        let roles = data.roles;
        let _tenantIDS = [], modules = [], userGroups = [], modulesArrayForDB = [];
        let displayallusersdata = "n";

        for (let i = 0; i < tenants.length; i++) {
            _tenantIDS.push(tenants[i].id.toString());
        }

        for (let i = 0; i < groups.length; i++) {
            userGroups.push(groups[i].id.toString());
        }

        for (let k = 0; k < roles.length; k++) {
            if (roles[k].displayallusersdata === "y") {
                displayallusersdata = "y";
                break;
            }
        }

        helpers.setCookie('displayallusersdata', displayallusersdata, 10);

        for (let j = 0; j < usermodules.length; j++) {
            if (usermodules[j].readonly === 'y' || usermodules[j].readwrite === 'y') {
                modulesArrayForDB.push(usermodules[j].modulename.trim());
                modules.push(usermodules[j].modulename.toLowerCase().trim());
            }
        }

        helpers.setCookie('tenantIDS', JSON.stringify(_tenantIDS), 10);
        helpers.setCookie('userGroups', JSON.stringify(userGroups), 10);
        helpers.setCookie('modulesArray', JSON.stringify(modules), 10);
        helpers.setCookie('modulesArrayForDB', JSON.stringify(modulesArrayForDB), 10);

        this.setState({ loadingIcon: "" });
        if (data.firstlogin) {
            this.props.history.push("/settings");
        } else if (!data.hassecuraalicence) {
            helpers.setSessionStorage("licenseResponse", JSON.stringify(data.licenseResponse))
            this.props.history.push("/license");
        } else if (data.tenantsinfo.length === 0) {
            this.props.history.push("/configure");
        } else {
           
            if (modules.indexOf('dashboard') > -1 || modules.indexOf('reports') > -1 ||
                modules.indexOf('metrics (predefined dashboard)') > -1 || modules.indexOf('cases (predefined dashboard)') > -1 ||
                modules.indexOf('threat intelligence (predefined dashboard)') > -1 || modules.indexOf('soc performance (predefined dashboard)') > -1 ||
                modules.indexOf('configuration (predefined dashboard)') > -1 || modules.indexOf('assets (predefined dashboard)') > -1 || modules.indexOf('case panel') > -1) {
                this.props.history.push("/home");
            } else if (modules.indexOf('platform management') > -1) {
                this.props.history.push("/Platform");
            } else if (modules.indexOf('apps') > -1) {
                this.props.history.push("/Apps");
            } else if (modules.indexOf('tasks') > -1) {
                this.props.history.push("/Tasks");
            } else if (modules.indexOf('playbooks') > -1) {
                this.props.history.push("/Playbook");
            } else if (modules.indexOf('indicators') > -1) {
                this.props.history.push("/IndicatorsHome");
            } else if (modules.indexOf('asset explorer') > -1) {
                this.props.history.push("/AssetExplorer");
            } else if (modules.indexOf('utils') > -1){
                this.props.history.push("/Utils");
            }else {
                this.props.history.push("/logout");
            }
        }
    }

    getSAMLUrl = () => {

        this.resetErrorMessage();
        this.setState({ samlClassName: "fa fa-refresh fa-spin", samlRequestID: "", samlBtnDisabled: true })
        let url = restURL + "?rest=getsamlurl";
        axios({
            method: "GET",
            url: url,
            headers: { access_token: "", jwt_token: "", "Content-Type": "application/json; charset=utf-8" },
            responseType: "json"
        }).then(res => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({ samlRequestID: data.requestuuid });
                myWindow = window.open(data.url, "saml-login", "");
            } else {
                this.setState({ samlClassName: "", samlBtnDisabled: false });
                if (res.data.displaymessage !== "") {
                    this.setErrorMessage("danger", res.data.displaymessage, "inline-block");
                } else {
                    this.setErrorMessage("danger", "Failed to login with SAML. Try again.", "inline-block");
                }
                setTimeout(() => {
                    this.resetErrorMessage();
                }, 5000);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    render() {

        let { samlClassName } = this.state;

        return (
            <Container>
                <Col sm="12">
                    <Col sm="2">
                    </Col>

                    <Col sm="7" md="7" xs="12" >

                        {/* saml Login form */}
                        {this.state.showSAMLForm === "show" ?
                            <div className={this.state.showSAMLForm + " login_form "}>
                                <div style={{ width: "70%", display: "inline-block" }} >
                                    <div className="login_content">
                                        <img src={this.state.logo+"?t=" + this.state.timestamp } alt="logo" style={{ width: "100%", marginBottom: "15px", maxHeight: "200px" }} />
                                        {/*} <p>Powered by Securaa</p>*/}

                                        <Button color="info" id="LoginSAML" className="securaa-btn" size="sm" style={{ width: "100%" }}
                                            disabled={this.state.samlBtnDisabled || !this.state.isManagerRunning} onClick={() => this.getSAMLUrl()}>
                                            Login using SAML <i className={samlClassName}></i>
                                        </Button>

                                        <div className="saml-separator"></div>
                                        <Button color="link" size="sm" id="LoginSAMLEmail" style={{ width: "100%", color: "#29b6f6" }} disabled={!(this.state.isManagerRunning)}
                                            onClick={() => this.showRegularLoginForm()}>
                                            Login using Email / Password
                                        </Button>
                                    </div>
                                </div>
                            </div> : ""}

                        {/* ldap Login form */}
                        {this.state.showLDAPForm === "show" ?
                            <div className={this.state.showLDAPForm + " login_form "}>
                                <div style={{ width: "70%", display: "inline-block" }} >
                                    <form className="login_content" onSubmit={(e) => { e.preventDefault(); this.validateForm(); }}>
                                        <img src={this.state.logo+"?t=" + this.state.timestamp} alt="logo" style={{ width: "100%", marginBottom: "15px", maxHeight: "200px" }} />
                                        {/*<p>Powered by Securaa</p>*/}
                                        <FormGroup>
                                            <Input type="text" value={this.state.email} className="margin-bottom-15" onChange={(event) => this.setState({ email: event.target.value })} autoComplete="new-password" name="email" id="email" placeholder="UserName" style={{ marginBottom: "15px" }} />
                                        </FormGroup>

                                        <InputGroup>
                                            <Input type={this.state.passwordFieldType} value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} autoComplete="new-password" name="password" id="password" placeholder="Password" />
                                            <InputGroupAddon addonType="append">
                                                <Button color="secondary" id="LDAPIbtn" className="task-input-field-button password-btn-light"
                                                    onMouseDown={() => this.setState({ passwordFieldType: "text" })}
                                                    onMouseUp={() => this.setState({ passwordFieldType: "password" })}
                                                    onMouseOut={() => this.setState({ passwordFieldType: "password" })}
                                                    onClick={() => { return false; }}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>

                                        {this.state.isManagerRunning === true ?
                                            <FormGroup>
                                                <Button style={{ "width": "100%" }} className="securaa-btn" color="info" id="LoginLDAP" type="submit" size="sm" disabled={!(this.state.isManagerRunning)}>
                                                    Login With LDAP<i className={this.state.loadingIcon}></i>
                                                </Button>
                                                {/*}<Button color="success" type="submit" size="sm" disabled={!(this.state.isManagerRunning)}>
                                                    Login With LDAP  <i className={this.state.loadingIcon}></i>
                                                </Button>*/}

                                            </FormGroup> : ""}

                                        <div className="separator"></div>
                                        <Button color="link" size="sm" id="LDAPEmail" style={{ width: "100%", color: "#29b6f6" }} disabled={!(this.state.isManagerRunning)}
                                            onClick={() => this.showRegularLoginForm()}>
                                            Login using Email / Password
                                        </Button>


                                    </form>
                                </div>
                            </div> : ""}

                        {/* regular login form */}

                        {this.state.showLoginForm === "show" ?
                            <div className={this.state.showLoginForm + " login_form"}>
                                <div style={{ width: "70%", display: "inline-block" }} className={this.state.showError === "none" ? "" : "errorInput"}>
                                    <form className="login_content" onSubmit={(e) => { e.preventDefault(); this.validateForm(); }}>
                                        <img src={this.state.logo+"?t=" + this.state.timestamp} alt="logo" style={{ width: "100%", marginBottom: "15px", maxHeight: "200px" }} />
                                        {/*} <p>Powered by Securaa</p>*/}
                                        <FormGroup>
                                            <Input type="text" value={this.state.email} className="margin-bottom-15" onChange={(event) => this.setState({ email: event.target.value })} autoComplete="new-password" name="email" id="securaaLoginEmail" placeholder="Email" style={{ marginBottom: "15px" }} />
                                        </FormGroup>

                                        <InputGroup>
                                            <Input type={this.state.passwordFieldType} value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} autoComplete="new-password" name="password" id="securaaLoginPassword" placeholder="Password" />
                                            <InputGroupAddon addonType="append">
                                                <Button color="secondary" id="SecuraaLoginIbtn" className="task-input-field-button password-btn-light"
                                                    onMouseDown={() => this.setState({ passwordFieldType: "text" })}
                                                    onMouseUp={() => this.setState({ passwordFieldType: "password" })}
                                                    onMouseOut={() => this.setState({ passwordFieldType: "password" })}
                                                    onClick={() => { return false; }}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>

                                        {this.state.isManagerRunning === true || this.state.loginDisable === true?
                                            <FormGroup>
                                                <Button id="securaaLoginButton" style={{ "width": "100%" }} className="securaa-btn" color="info" type="submit" size="sm" disabled={(this.state.loginDisable)}>
                                                    <span>Login</span>  <i className={this.state.loadingIcon}></i>
                                                </Button>
                                                {/*}<Button color="success" type="submit" size="sm" disabled={!(this.state.isManagerRunning)}>
                                                    Login With LDAP  <i className={this.state.loadingIcon}></i>
                                                </Button>*/}
                                                <Button color="link" size="sm" id="LoginForgotPassword" style={{ color: "#29b6f6" }} onClick={() => this.setState({
                                                    showLoginForm: "hide",
                                                    showSAMLForm: "hide",
                                                    showForgotPasswordForm: "show",
                                                    email: ""
                                                }, this.resetErrorMessage())}><span>Forgot password ?</span></Button>
                                            </FormGroup> : ""}

                                            <div className="separator"></div>
                                            {/*{this.state.samlIntegrationCount === 1 ?
                                            <Button color="link" size="sm" style={{ width: "100%", color: "#29b6f6", marginTop: "10px" }} onClick={() => this.showSAMLLoginForm()}>
                                                Login using SAML
                                            </Button> : ""}*/}

                                        {this.state.samlIntegrationCount === 1 ?
                                            <Button color="info" id="LoginSAML" className="securaa-btn" size="sm" style={{ width: "100%", marginTop: "10px" }} onClick={() => this.getSAMLUrl()}>
                                                <span>Login using SAML</span> <i className={samlClassName}></i>
                                            </Button> : ""}


                                        {this.state.ldapIntegrationCount === 1 ?
                                            <Button color="info" type="submit" id="LoginUsingLDAP" className="securaa-btn" size="sm" style={{ width: "100%" }} onClick={() => this.showLDAPLoginForm()}>
                                                Login using LDAP
                                            </Button> : ""}
                                    </form>
                                </div>
                            </div> : ""}

                        {/* forgot password form */}

                        {this.state.showForgotPasswordForm === "show" ?
                            <div className={this.state.showForgotPasswordForm + " login_form"}>
                                <div style={{ width: "70%", display: "inline-block" }} className={this.state.showError === "none" ? "" : "errorInput"}>
                                    <div className="login_content">
                                        <img src={this.state.logo +"?t=" + this.state.timestamp} alt="logo" style={{ width: "100%", marginBottom: "15px" }} />
                                        {/*} <p>Powered by Securaa</p>*/}
                                        <FormGroup>
                                            <Input type="email" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} autoComplete="new-password" name="user_email" id="user_email" placeholder="Email" style={{ marginBottom: "15px" }} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Button color="success" size="sm" id="LoginRestPassword" onClick={this.validateForgotPasswordForm}>
                                                Reset password <i className={this.state.loadingIcon}></i>
                                            </Button>

                                            <Button color="link" id="LoginClick" size="sm" style={{ color: "#29b6f6" }} onClick={() => this.setState({
                                                showLoginForm: "show",
                                                showSAMLForm: "hide",
                                                showForgotPasswordForm: "hide",
                                            }, this.resetErrorMessage())}> Click here to login</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div> : ""}

                        <Col md="12" sm="12" xs="12" style={{ marginTop: "-100px" }}>
                            <div className="bootingwrapper" style={{ width: "56%", display: "inline-block" }} >
                                <Alert color={this.state.alertType} className={this.state.showAlert}>
                                    {this.state.errorMessage}
                                </Alert>
                            </div>
                        </Col>

                    </Col>

                    <Col sm="3">
                    </Col>
                </Col>
            </Container >
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
        samlresponse: state.reducer.samlresponse,
        logoupdatedata:state.reducer.logoupdatedata
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportLogin = withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
export default exportLogin;
