export default function soarWidgetReducer(state = {}, action) {
    switch (action.type) {
        case "getAnalystWorkloadData":
            return { analystdata: action.payload }
    
        case "getAssignedCasesData":
            return { assigneddata: action.payload }

        case "getCaseDeduplicationData":
            return { deduplicationdata: action.payload }
   
        case "getCaseVelocityData":
            return { velocitydata: action.payload }

        case "getCasesByTagsData":
            return { tagsdata: action.payload }

        case "getKillChainData":
            return { killchaindata: action.payload }

        case "getTrendsPerWeekData":
            return { weekdata: action.payload }

        case "getCasesBySLAStatusData":
            return { sladata: action.payload }

        case "getCasesByStatusData":
            return { statusdata: action.payload }

        case "getCasesByCategoryData":
            return { categorydata: action.payload }

        case "getCasesByFunctionalImpactData":
            return { funimpactdata: action.payload }

        case "getCasesByInformationImpactData":
            return { infoimpactdata: action.payload }

        case "getCasesByMitreTacticsData":
            return { tacticsdata: action.payload }

        case "getCasesByMitreTechniquesData":
            return { techniquedata: action.payload }

        case "getCasesByRecoverabilityEffortData":
            return { recoverdata: action.payload }

        case "getCasesByTargetData":
            return { targetdata: action.payload }

        case "getCasesByThreatActorData":
            return { tactordata: action.payload }
        
        case "getCasesByThreatVectorData":
            return { vectordata: action.payload }

        case "getCasesByPrioritiesData":
            return { prioritydata: action.payload }

        case "gettopplaybooksaverageruntime":
            return {playbookRuntime: action.payload}

        case "getServiceNowCasesData":
            return { ticketdata: action.payload }

        case "getClosedCasesData":
            return { closecasedata: action.payload }

        case "getClosedCasesThisMonthData":
            return { monthdata: action.payload }
               
        case "getInProgressCasesData":
            return { progressdata: action.payload }

        case "getMTTRByAnalystData":
            return { analysisdata: action.payload }
    

        case "getMTTRByCategoryData":
            return { categorydata: action.payload }
    
        case "getMTTAData":
            return { mttadata: action.payload }
        
        case "getFailedTaskExecutionsData":
            return { failedTaskExecutionsData : action.payload }
        
        case "getFailedPlaybookExecutionsData":
            return { failedPlaybookExecutionsData : action.payload }
        
        case "getAutoClosedIncidentsData":
            return { autoclosedincidentsdata: action.payload }

        case "getMTTAAcknowledgeData":
            return { mttaacknowledgedata: action.payload }

        case "getMTTRData":
            return { mttrdata: action.payload }

        case "getMTTTData":
            return { mtttdata: action.payload }

        case "getEventByStatusData":
            return { eventdata: action.payload }  

        case "getResolvedCasesData":
            return { resolvedata: action.payload }  

        case "getResponseSlaData":
            return { responsedata: action.payload }  

        case "getUnassignedCasesData":
            return { unassigneddata: action.payload }
            
        case "getCasesCountByAutoProcessRuleData":
            return { casescountbyautoprocessruledata: action.payload } 

        case "getTopPlaybookUsedData":
            return { tplaybookdata: action.payload }  

        case "getTopTasksUsedData":
            return { ttaskdata: action.payload } 

        case "getMyCasesData":
            return { mycasedata: action.payload } 

        case "getplaybookData":
            return { playbookdata: action.payload } 

        case "getFeeds":
            return { feeddata: action.payload } 

        case "getOnCallUsersData":
            return { onlinedata: action.payload } 

        case "getOnCallUsersCount":
            return { oncountdata: action.payload }

        case "getUnassignedCasesTileData":
            return { casetiledata: action.payload }

        case "getUserGroupTrendsPerWeek":
            return { usergroupweekdata: action.payload }

        case "getUserGroupKillChainData":
            return { usergroupchaindata: action.payload }

        case "getUserGroupCasesByPriorities":
            return { usergroupprioritydata: action.payload }

        case "getUserGroupCasesByStatus":
            return { usergroupstatusdata: action.payload }

        case "getUserGroupCasesByCategory":
            return { usergroupstatusdata: action.payload }

        case "getUserGroupEventByStatus":
            return { usergroupstatusdata: action.payload }

        case "getUserGroupClosedCases":
            return { usergroupclosecasedata: action.payload }

        case "getUserGroupClosedCasesThisMonth":
            return { usergroupmonthdata: action.payload }

        case "getUserGroupIncomingSources":
            return { usergroupsourcedata: action.payload }

        case "getTenantIndustryData":
            return { tindustrydata: action.payload }

        case "getShiftTableData":
            return { shifttabledata: action.payload }

        case "getPlaybookMonitoringData":
            return { playbookmonitoringdata: action.payload }

        case "getCasesCountData":
            return{totalcasesdata:action.payload}

        case "getZendeskInstance":
            return {zendeskinstancedata : action.payload}

        case "getZendeskCasesData":
            return {zendeskcasesdata : action.payload}
        case "getMTTDKPIData":
            return { mttdkpidata: action.payload }

        case "getaverageplaybooklist":
            return { averageplaybooklist: action.payload }
        // author: Divya changes:- adding severity data
        // ---------------start------------------
      
        case "getCasePanelSeverity":
            return {severitydata: action.payload}

        case "getChangeSeverity":
            return {changeSeverity: action.payload}

        case "getQuickActionSeverity":
            return {severity: action.payload}

        case "getSeverity":
            return {severity: action.payload}

        case "getTaskSeverity":
            return {taskSeverity: action.payload}    
        case "getManageeEngineServiceTickets":
            return { manageengineserviceticketsdata: action.payload } 
        case "getIOCsIdentifiedBlockedData":
            return {iocsData: action.payload} 
        case "getMTTRBYSeveritySLAData":
            return {mttrData: action.payload}    
        case "getMTTDBYSeveritySLAData":
            return {mttdData: action.payload}   
        case "getCasesTriggeredvsRaisedData":
            return {triggereddata: action.payload}
        case "getIOCTrendsPerWeekData":
        return {weekdata: action.payload}
            
            return {taskSeverity: action.payload}   
        
        case "gethighandcriticalcases":
            return {highandcriticalcases: action.payload}  

        case "getCasesclosedbyclosurereason":
            return {casesclosedbyclosurereasonData: action.payload} 
        
        case "casesbylogsource":
            return {casesbylogsource: action.payload} 

        case "casetrendsontimerangeTriggered":
            return {casetrendsontimerangeTriggered: action.payload}
        
        case "casetrendsontimerangenonTriggered":
            return {casetrendsontimerangenonTriggered: action.payload}

        case "getCaseDownloadData":
            return {getCaseDownloadData: action.payload}
            
    }
  
    return state
}