import React, { Component } from 'react';
import { connect } from "react-redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Col, Button,Input,Label } from "reactstrap";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
import parse from 'html-react-parser';
const restURL = helpers.restURL;
let url = window.location.origin;
url = url === "http://localhost:3000" ? "http://localhost" : url;
url = url + "/downloads/logo/logo.png";
url = url.toString();
class Approval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: "none",
            showError: "none",
            disableApproveBtn: false,
            token: "",
            approvalMail:"",
            showSuccess: "hide",
            errorMessage: "",
            successMessage: "",
            successMessage1: "",
            subject: "",
            message: "",
            approvalStatus: "",
            approvaloptions:[],
            showButton:false,
            textColour:"",
            timestamp:new Date().getTime(),
            username:"",
            remark:"",
            userNameErrorMsg:"",
            userNameError:"hide",
            usernameValue:"",
            remarkValue:"",
            approvalMailValue:"",
            selectedFiles: [],
            fileNames: [],
            fileUploadErrorMsg: "",
            fileUploadError: "hide",
            filePathValue:[]
        };
        this.updateApprovalStatus = this.updateApprovalStatus.bind(this);
        this.getApprovalDetails = this.getApprovalDetails.bind(this);
        this.processApprovalDetails = this.processApprovalDetails.bind(this);
    }

    componentWillMount() {
        document.body.style.setProperty("background", `#272b35`);
    }

    componentDidMount() {
        this.getApprovalDetails();
    }

    getApprovalDetails() {
        this.setState({ loading: "show" });
        var token_value = '';
        var email_value = '';
        var url = window.location.href
        var parts = url.split('?');
        if (parts.length === 2) {
            var parameters = parts[1].split('&');
            for (var i = 0; i < parameters.length; i++) {
                if (parameters[i].indexOf('=') > 0) {
                    var parameter = parameters[i].split('=');
                    if (parameter[0] === "token") {
                        token_value = parameter[1];
                    }
                    if (parameter[0] === "email") {
                        email_value = parameter[1];
                    }
                }
            }
        }
        if (token_value === "") {
            this.setState({ disableApproveBtn: true, showSuccess: "hide", showError: "inline-block", errorMessage: "Invalid approval link" });
        } else {
            this.setState({ token: token_value });
        }

        if (email_value === "") {
            this.setState({ disableApproveBtn: true, showSuccess: "hide", showError: "inline-block", errorMessage: "Invalid approval link" });
        } else {
            this.setState({ approvalMail: email_value });
        }

        var url = helpers.restURL + '?module=user&rest=getapprovalstatus&q=' + token_value + '/';
        axios({
            method: "GET",
            url: url,
            headers: {
                access_token: helpers.getCookie('accesstoken'),
                jwt_token: helpers.getCookie('jwt_token'),
                "Content-Type": "application/json; charset=utf-8"
            },
            responseType: "json"
        }).then(res => {
            helpers.checkSessionTimeout(res.data.sessionexpired);
            if (res.data.success) {
                if(res.data.data.approvaloptions !== "undefined" && res.data.data.approvaloptions !== "null" && res.data.data.approvaloptions !== "" ){
                    let approvaloptionsarray = res.data.data.approvaloptions.split(",")
                    this.setState({ approvaloptions:approvaloptionsarray})
                } else {
                    let defaultOptions = "Approve,Reject"
                    let approvaloptionsarray = defaultOptions.split(",")
                    this.setState({ approvaloptions:approvaloptionsarray})
                }
                this.setState({ subject: res.data.data.subject, message: res.data.data.message,usernameValue:res.data.data.approverName,remarkValue:res.data.data.approverRemark,approvalMailValue:res.data.data.approverMail,filePathValue:res.data.data.filePath || ''})
                this.processApprovalDetails(res.data);
            } else {
                if (res.data.displaymessage !== "") {
                    this.setState({ disableApproveBtn: true, showError: "inline-block", errorMessage: res.data.displaymessage });
                } else {
                    this.setState({ disableApproveBtn: true, approvalStatus: "invalid", showError: "inline-block", errorMessage: "Invalid token" });
                }
            }
            this.setState({ loading: "hide" });
        }).catch(function (error) {
            console.log(error);
        });
    }

    processApprovalDetails(responseData) {
        let successMessageValue="";
        let successMessageValue1="";
        let showSuccessvalue="hide";
        let textColourValue=""
        if ("active" === responseData.data.validity || "addressed" === responseData.data.validity) {
            if (responseData.data.approvalstatus  !== "") {
                successMessageValue= "Request already addressed";
                successMessageValue1 ="Responded with - " + responseData.data.approvalstatus
                this.setState({ textColour:"rgba(38,185,154,.88)",showError: "none", showButton:true, showSuccess: "show", approvalStatus: "", successMessage: successMessageValue,successMessage1:successMessageValue1, disableApproveBtn: true, });
            }
        } else if ("stopped" == responseData.data.validity) {
                successMessageValue= "Playbook has been stopped";
                successMessageValue1= ""
                this.setState({ textColour:"rgba(231,76,60,0.88)",showError: "none", showButton:false, showSuccess: "show", approvalStatus: "", successMessage: successMessageValue,successMessage1:successMessageValue1, disableApproveBtn: true, });
        } else if ("expired" == responseData.data.validity) {
            if (responseData.data.approvalstatus  !== "") {
                successMessageValue= "Request already addressed"
                successMessageValue1 ="Responded with - " + responseData.data.approvalstatus
                showSuccessvalue = "show"
                textColourValue="rgba(38,185,154,.88)"
            } else {
                successMessageValue = "Request Expired"
                successMessageValue1=""
                showSuccessvalue = "hide"
                textColourValue="rgba(231,76,60,0.88)"
            }
            this.setState({ textColour:textColourValue,showError: "inline-block",showButton:true, showSuccess: showSuccessvalue, approvalStatus: "", errorMessage: successMessageValue,successMessage1:successMessageValue1,disableApproveBtn: true, });
        }
    }

    removeFile = (fileName) => {
        try{
            if ( fileName !== null && typeof fileName !== "undefined" && fileName !== "" ) {
                const updatedFiles = this.state.selectedFiles.filter(file => file.name !== fileName);
                const updatedFileNames = this.state.fileNames.filter(name => name !== fileName);

                this.setState({selectedFiles: updatedFiles, fileNames: updatedFileNames});
            }
        }catch(e){console.log("Function:removeFile,File:Approval.js,Error:",e);}
    };

    handleFileChange = (event) => {
        try{
            const files = event.target.files; // Get the list of files
            const fileArray = Array.from(files); // Convert FileList to Array

            // Create a set of existing file names to check for duplicates
            const existingFileNames = new Set(this.state.fileNames);

            // Calculate the total size of selected files
            const totalSize = fileArray.reduce((sum, file) => sum + file.size, 0);

            // Check if any file exceeds the size limit
            const oversizedFile = fileArray.find(file => file.size > 20 * 1024 * 1024); // 20 MB limit
            const totalSizeExceeded = totalSize > 20 * 1024 * 1024;

            if (oversizedFile) {
                this.setState({ fileUploadErrorMsg: "File size should not exceed 20 MB", fileUploadError: "show" });
            } else if (totalSizeExceeded) {
                this.setState({ fileUploadErrorMsg: "Total file size should not exceed 20 MB", fileUploadError: "show" });
            }else {
                const newFiles = fileArray.filter(file => !existingFileNames.has(file.name));
                const newFileNames = newFiles.map(file => file.name);
                this.setState({
                    selectedFiles: [...this.state.selectedFiles, ...newFiles],
                    fileNames: [...this.state.fileNames, ...newFileNames],
                    fileUploadErrorMsg: "",
                    fileUploadError: "hide"
                });
            }
        }catch(e){console.log("Function:handleFileChange,File:Approval.js,Error:",e);}
    };


    updateApprovalStatus(status) {
        try {
            // Calculate the total size of all selected files
            let totalSize = 0;
            for (let file of this.state.selectedFiles) {
                totalSize += file.size;
            }

            // Check if any individual file or the total size exceeds the limit
            const maxSize = 20 * 1024 * 1024; // 20 MB in bytes
            const largeFile = this.state.selectedFiles.find(file => file.size > maxSize);

            if (largeFile || totalSize > maxSize) {
                const errorMessage = largeFile ? `${largeFile.name} size should not exceed 20 MB` : `Total file size should not exceed 20 MB`;
                this.setState({fileUploadErrorMsg: errorMessage, fileUploadError: "show"});
                return;
            }

            this.setState({loading: "show", approvalStatus: status});
            const formData = new FormData();
            // Append all selected files
            for (let file of this.state.selectedFiles) {
                formData.append('files[]', file);
            }
            formData.append('uuid', this.state.token);
            formData.append('approvalstatus', status);
            formData.append('rest', 'updateapprovalstatus');
            formData.append('module', 'users');
            formData.append('approverName', this.state.username);
            formData.append('approverRemark', this.state.remark);
            formData.append('approverMail', this.state.approvalMail);
            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                responseType: "json",
                dataType: "json",
                processData: false,
                contentType: false,
                data: formData,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                this.setState({loading: "none"});
                if (res.data.success) {
                    this.setState({textColour: "rgba(38,185,154,.88)", showSuccess: "show", showError: "none", successMessage: "Responded with " + status, disableApproveBtn: true,});
                    //  this.getApprovalDetails();
                } else if (res.data.error !== "undefined" && res.data.error !== "null" && res.data.error !== "") {
                    if (res.data.error === "not found") {
                        this.getApprovalDetails();
                        this.setState({showSuccess: "hide", showError: "inline-block", errorMessage: "Mail is already approved", disableApproveBtn: false});
                    } else {
                        this.setState({showSuccess: "hide", showError: "inline-block", errorMessage: res.data.error, disableApproveBtn: false});
                    }
                } else {
                    this.setState({showSuccess: "hide", showError: "inline-block", errorMessage: "Failed to approve", disableApproveBtn: false});
                }
                this.setState({loading: "hide"});
            }).catch(function (error) {
                console.log(error);
            });
        } catch(e){console.log("Function:updateApprovalStatus,File:Approval.js,Error:",e);}
    }

    handleKeyPress = (target) => {
        if (target.charCode === 32 && target.target.selectionStart === 0) {
            target.preventDefault();
        }
    }

    getColor=(name)=>{
        let negativeButtonsArray = ["remove","remov","reject","cancel","revoke","revok","wrong","not","no","deny","refuse","refus","ignore","ignor","dispute","disput","withdraw","avoid","dismiss","decline","declin","disapprove","disapprov","disallow","withhold","constrain","hold","restrict","obstruct","ban","delete","delet","block","blacklist"]
        let isInArray = negativeButtonsArray.filter(v => name.toLowerCase().indexOf(v) > -1);
        if (isInArray.length > 0) {
            return "danger";
        }else{
            return "info"
        }
    }

    changeUserName =(event)=>{
        let usernamevalue=event.target.value
        if(usernamevalue===""){
            this.setState({userNameErrorMsg:"Please enter approver name",userNameError:"show"})
        }else if(usernamevalue.length<3){
            this.setState({userNameErrorMsg:"Approver Name should have a minimum of three characters",userNameError:"show"})
        }else{
            this.setState({userNameErrorMsg:"",userNameError:"hide"})
        }
        this.setState({username:usernamevalue})
    }

    changeRemark =(event)=>{
        this.setState({remark:event.target.value})
    }

    render() {

        return (
            <Container>
                <LoadingSpinner showSpinner={this.state.loading} />
                <Col sm="12">
                    <Col sm="1">
                    </Col>
                    <Col sm="10" className="login_form">
                        <div className="approvalform" style={{ width: "100%" }}>
                            <img src={url+ "?t=" + this.state.timestamp} alt="logo" style={{ width: "30%", marginBottom: "15px",height:"auto" }} />
                            <div>
                              
                                    <div>
                                        <h3 style={{ color: "#cfd2da" }}>{this.state.subject}</h3>
                                        <h5 style={{ color: "#cfd2da",wordWrap:"break-word" }}>{parse(this.state.message)}</h5>
                                    </div>
                               
                                <hr style={{ borderColor: "grey" }}></hr>
                                <div>
                                    {this.state.approvalStatus === "" && this.state.approvaloptions !=="" && this.state.showButton ===false ?
                                        <div style={{ paddingBottom: "10px",paddingLeft: "165px",paddingRight: "165px" }}>
                                            <FormGroup row>
                                                <Label for="email" style={{textAlign:"left"}} sm={4}>Approver Name * :</Label>
                                                <Col sm={8}>
                                                    <Input type="text"  value={this.state.username} className="approvalinputbox"   name="username" id="approvalusername" placeholder="Username" onKeyPress={this.handleKeyPress}  onChange={(e) => this.changeUserName(e)}/>
                                                    <div id="usernameerror" className={"InputError errorMessage " + this.state.userNameError} >{this.state.userNameErrorMsg}</div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="email" style={{textAlign:"left"}} sm={4}>Remark :</Label>
                                                <Col sm={8}>
                                                    <Input type="textarea" value={this.state.remark} className="approvalinputbox"  name="remark" id="approverRemark" placeholder="Remark" onKeyPress={this.handleKeyPress} onChange={(e) => this.changeRemark(e)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="fileUpload" style={{ textAlign: "left" }} sm={4}>Attachments :</Label>
                                                <Col sm={8}>
                                                    <Label className="btn btn-outline-info cursor-point">
                                                        <input id="fileUploadApproval" name="fileUploadApproval" type="file" multiple onChange={this.handleFileChange} style={{display: "none"}}/>
                                                        <i className="fa fa-upload"></i> Choose File
                                                    </Label>
                                                    <div id="fileUploadError" className={"InputError errorMessage " + this.state.fileUploadError}><br/><br/>{this.state.fileUploadErrorMsg}</div>
                                                    {this.state.fileNames && this.state.fileNames.length > 0 && (
                                                        <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: '0.5rem',paddingTop: '0.9rem' }}>
                                                            {this.state.fileNames.map((fileName, index) => (
                                                                <span key={index} style={{color: 'whitesmoke', fontSize: '12px', padding: '0.2rem 0.5rem', border: '1px solid rgba(38,185,154,.88)', borderRadius: '50px', width: '8rem', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}} title={fileName}>
                                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{fileName}</span>
                                                                    <button type="button" onClick={() => this.removeFile(fileName)} style={{background: 'none', border: 'none', color: 'rgba(231,76,60,0.88)', cursor: 'pointer', marginLeft: '0.5rem'}}>
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                </Col>
                                            </FormGroup>
                                            {this.state.approvaloptions.map((item, index) =>
                                                <Button color={this.getColor(item)} style={{width: "150px",marginTop: '10px'}}
                                                        disabled={this.state.username.length < 3 ? true : this.state.disableApproveBtn}
                                                        onClick={() => this.updateApprovalStatus(item)}>{item}</Button>
                                            )}</div>
                                        : ""}
                                </div>
                                <div className="alert alert-success"
                                    style={{ background: "transparent", border: "none", width: "100%", height: "40px", display: this.state.showError }}>
                                    <h6 style={{ color:"rgba(231,76,60,0.88)" }}>
                                        {this.state.errorMessage}
                                    </h6>
                                </div>
                                
                                {this.state.usernameValue!==""?
                                <div
                                    style={{ background: "transparent", border: "none", width: "100%", minHeight: "60px" }}>
                                    {/* <h6 style={{ color:"#e9ecef"}}>
                                        User Name:{this.state.usernameValue!==""?this.state.usernameValue:""}<br/>
                                        Remark:{this.state.remarkValue!==""?this.state.remarkValue:""}<br/>
                                    </h6> */}
                                     <table style={{ border: '1px solid grey' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ color: "#e9ecef",maxWidth:"120px",textAlign: "left",border: '1px solid #e9ecef' }}>Approver Email</th>
                                                <td style={{ textAlign: "left",border: '1px solid #e9ecef',wordWrap: 'break-word' }}>{this.state.approvalMailValue !== "" ? this.state.approvalMailValue : ""}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ width:"120px",textAlign: "left",border: '1px solid grey' }}>Approver Name</th>
                                                <td style={{ textAlign: "left",border: '1px solid grey',wordWrap: 'break-word' }}>{this.state.usernameValue !== "" ? this.state.usernameValue : ""}</td>
                                            </tr>
                                            {/* {this.state.remarkValue !== ""? */}
                                            <tr>
                                                <th style={{ width:"120px",textAlign: "left",border: '1px solid grey' }}>Remark</th>
                                                <td style={{ textAlign: "left",border: '1px solid grey',wordWrap: 'break-word' }}>{this.state.remarkValue !== "" ? this.state.remarkValue : ""}</td>
                                            </tr>
                                            {/* :""} */}
                                            <tr>
                                                <th style={{width: "120px", textAlign: "left", border: '1px solid grey'}}>Attachments</th>
                                                <td style={{textAlign: "left", border: '1px solid grey',wordWrap: 'break-word'}}>{this.state.filePathValue !== "" ? this.state.filePathValue.map((item,index) => <div style={{textAlign: 'left'}} key={index}>{item}</div>) : ""}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div> : ""}


                                <div className={this.state.showSuccess + " alert alert-success"}
                                    style={{ background: "transparent", border: "none", width: "100%", height: "60px" }}>
                                    {this.state.successMessage1==="" ?
                                    <h6 style={{ color:this.state.textColour,marginTop: "9px" }}>
                                        {this.state.successMessage} <br/>
                                    </h6>
                                    :
                                    <h6 style={{ color:this.state.textColour,marginTop: "0px" }}>
                                        {this.state.successMessage} <br/>
                                        {this.state.successMessage1}
                                    </h6>
                                    }
                                </div>

                                <hr style={{ borderColor: "grey" }}></hr>

                            </div>
                        </div>
                    </Col>
                    <Col sm="1">
                    </Col>
                </Col>
            </Container>
        )
    }
}

const exportApproval = withRouter(connect()(Approval));
export default exportApproval;
