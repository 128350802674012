import axios from "axios";
import helpers from "../../helpers";

export function authenticateFileName(href,filepath,filename,sourceType){
    try{
        return function (dispatch) {
            let url = helpers.restURL;
            let reqdata = {
                module:"user",
                rest:"downloadfilewithauth",
                tenantcode:helpers.getCookie("tenantcode"),
                filepath:href+filepath,
                userid:parseInt(helpers.getCookie("userid")),
                source:sourceType
            }
            axios({
                method: "POST",
                url: url,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                },
                data:JSON.stringify(reqdata),
                responseType: "blob"
            }).then(response => {
                if(response.data.success !== null && response.data.success !== undefined && !response.data.success){
                    if(response.data.displayMessage !== null && response.data.displayMessage !== undefined && response.data.displayMessage !== ""){
                        helpers.securaaNotification(response.data.displayMessage,"error",5)
                    }else{
                        helpers.securaaNotification("Error in response","error",5)
                    }
                }else {
                    if(response.data.type !== "text/html"){
                        let contentType = response.headers["content-type"]
                        let jsonStartIndex = contentType.indexOf('{');
                        let jsonData = contentType.substring(jsonStartIndex);
                        let jsonDataPart = ""
                        try {
                            jsonDataPart = JSON.parse(jsonData);
                        } catch (e) {
                            jsonDataPart = "";
                        }
                        let displayMessage = jsonDataPart !== null && jsonDataPart !==undefined ? jsonDataPart.displaymessage : "";
                            if (displayMessage !== null && displayMessage !== undefined && displayMessage !== ""){
                                helpers.securaaNotification(displayMessage,"error",5)
                            }else{
                                helpers.securaaNotification("Invalid file type","error",5)
                            }
                    }else {
                        helpers.downloadReportFile(response, filename)
                    }
                    dispatch({type: "autenticatefile", payload: response});
                }
                
            })
            .catch(error => {
                    console.error('There was an error downloading the file!', error);
                });
        };

    }catch(e){
        console.log("Function : authenticateUrl File : DashboardReports.js Error :",e);
    }
}