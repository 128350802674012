import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import helpers from "../helpers";
import { withRouter } from 'react-router-dom';
import { Container, FormGroup, Input, Col, Button, Label, InputGroupAddon, InputGroup } from "reactstrap";
import { getDefaultTheme, lightTheme, darkTheme } from "../actions/themeAction";
import Theme from "./theme";
import Menu from "./Menu";
import axios from "axios";
import LoadingSpinner from './LoadingSpinner';
const restURL = helpers.restURL;
const md5 = require("md5");

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            oldPasswordError: "",
            newPasswordError: "",
            confirmPasswordError: "",
            loading: "hide",
            firstLogin: false,
            hassecuraalicence: true,
            hasTenants: true,
            isSuperAdmin: true,
            oldPasswordType: "password",
            newpasswordType: "password",
            confirmPasswordType: "password",
            btnText: false
        };
        this.validateForm = this.validateForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkValueEmpty = this.checkValueEmpty.bind(this);
        this.checkUserOldPassword = this.checkUserOldPassword.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentWillMount() {
        let userData = helpers.getSessionStorage("userData");
        if (userData === "" || userData === null || typeof userData === 'undefined') {
            helpers.checkSessionTimeout(true);
        } else {
            let data = JSON.parse(userData);
            if (!data.hassecuraalicence) {
                this.setState({ hassecuraalicence: false });
            }
            if (data.tenantsinfo.length === 0) {
                this.setState({ hasTenants: false });
            }

            if (data.firstlogin) {
                this.setState({ firstLogin: true });
            }
        }
    }

    componentDidMount() {
        if (helpers.getSessionStorage("theme") === "dark") {
            this.props.darkTheme();
        } else {
            this.props.lightTheme();
        }
    }

    handleBlur = (e) => {
        this.checkValueEmpty(e);
    }

    handleChange = (e) => { 
        this.setState({ [e.target.name]: e.target.value }, ()=>{
            this.checkValueEmpty(e);
        });    
    }

    checkValueEmpty(e) {
        let errorField = e.target.name + `Error`;
        let fieldValue = e.target.value;
        if (fieldValue === "") {
            this.setState({ [errorField]: "This field is required" });
            this.setState({btnText: false})
            return
        } 
        else {
            this.setState({ [errorField]: "" });
            if(this.state.oldPassword !== "" && this.state.newPassword !== "" && this.state.confirmPassword !== "")
            this.setState({btnText: true})
        }
    }
    // Author:- Divya - 06/07/2023 changes:- Separate function for validation
    validateFormValue(){
        let oldPassword = this.state.oldPassword.trim();
        let newPassword = this.state.newPassword.trim();
        let confirmPassword = this.state.confirmPassword.trim();

        if (oldPassword === "") {
            this.setState({ oldPasswordError: "Old password is required" });
            this.setState({btnText: false})
        }

        if (newPassword === "") {
            this.setState({ newPasswordError: "New password is required" });
            this.setState({btnText: false})
        }

        if (confirmPassword === "") {
            this.setState({ confirmPasswordError: "Confirm password is required" });
            this.setState({btnText: false})
        }

        if (newPassword !== confirmPassword) {
            this.setState({ confirmPasswordError: "Confirm password doesn't match with New password" });
            this.setState({btnText: false})
        }

        var regularExpression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if (!regularExpression.test(newPassword)) {
            this.setState({ newPasswordError: "Password should have a minimum of eight characters and include at least one uppercase, one lowercase, one special character, and one number." });
            this.setState({btnText: false})
        }
    }

    validateForm() {
        this.validateFormValue();
        if (this.state.newPasswordError === "" && this.state.newPasswordError === "" && this.state.confirmPasswordError === "") {
            this.setState({btnText: true})
            this.setState({ loading: "show" });
            let userData = helpers.getSessionStorage("userData");
            let useObj = JSON.parse(userData);

            var data = {
                id: parseInt(helpers.getCookie('userid')),
                password: md5(this.state.oldPassword.trim()),
                newpassword: md5(this.state.newPassword.trim()),
                companycode: helpers.getCookie('companycode'),
                companyid: parseInt(helpers.getCookie('companyid')),
                email: useObj.email,
                module: 'user',
                rest: 'updatepassword'
            };

            axios({
                method: "PUT",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                dataType: "json",
                data: data,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (!res.data.success) {
                    let message = "";
                    if (res.data.displaymessage !== "") {
                        message = res.data.displaymessage;
                    } else {
                        message = "Failed to update"
                    }
                    helpers.securaaNotification(message,"error",10);
                    // toast.error(message, {
                    //     position: "top-right",
                    //     autoClose: 4000,
                    //     closeOnClick: true,
                    //     hideProgressBar: true,
                    //     pauseOnHover: false,
                    //     draggable: false
                    // });
                    if (message.includes("User is blocked for next 24 hours")) {
                        setTimeout(() => {
                            this.props.history.push("/logout")
                        }, 2000);
                    }
                } else if (res.data.success) {
                    helpers.securaaNotification("Password updated successfully.","success",10);
                    // toast.success('Password updated successfully', {
                    //     position: "top-right",
                    //     autoClose: 2000,
                    //     closeOnClick: true,
                    //     hideProgressBar: true,
                    //     pauseOnHover: false,
                    //     draggable: false
                    // });
                    if (this.state.firstLogin) {
                        setTimeout(() => {
                            if (!this.state.hassecuraalicence) {
                                this.props.history.push("/license");
                            } else if (!this.state.hasTenants) {
                                this.props.history.push("/configure");
                            } else {
                                this.props.history.push("/home");
                            }
                        }, 1000);
                    } else {
                        helpers.securaaNotification("Please re-login to countine.","success",10);
                        // toast.success('Please re-login to countine', {
                        //     position: "top-right",
                        //     autoClose: 2000,
                        //     closeOnClick: true,
                        //     hideProgressBar: true,
                        //     pauseOnHover: false,
                        //     draggable: false
                        // });
                        setTimeout(() => {
                            this.props.history.push("/logout");
                        }, 2000);
                    } 
                    // this.setState({ oldPassword: "", newPassword: "", confirmPassword: "" });
                }
                this.setState({ loading: "hide" });
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    checkUserOldPassword() {
        // if (this.state.newPassword !== "") {
            let userData = helpers.getSessionStorage("userData");
            let useObj = JSON.parse(userData);
            var data = {
                id: parseInt(helpers.getCookie('userid')),
                password: md5(this.state.newPassword.trim()),
                companycode: helpers.getCookie('companycode'),
                companyid: parseInt(helpers.getCookie('companyid')),
                email: useObj.email,
                module: 'user',
                rest: 'checkoldpassword'
            };

            axios({
                method: "POST",
                url: restURL,
                headers: {
                    access_token: helpers.getCookie('accesstoken'),
                    jwt_token: helpers.getCookie('jwt_token'),
                    "Content-Type": "application/json; charset=utf-8"
                },
                dataType: "json",
                data: data,
            }).then(res => {
                helpers.checkSessionTimeout(res.data.sessionexpired);
                if (res.data.success) {
                    if (res.data.data === 0) {
                        this.setState({ newPasswordError: "" });
                        this.validateForm(); //Author:- Divya - 6/7/2023 changes:- avoid calling the rest for multiple times and calling onclick of the save button
                    }                     
                    else {
                        this.setState({ newPasswordError: "New password matches the old one! Please choose different password." });
                        this.setState({btnText: false})
                        return
                    } 
                    this.setState({btnText: false})
                }
            }).catch(function (error) {
                console.log(error);
            });
        // }
    }

    redirect() {
        this.props.history.goBack();
    }

    render() {

        let divStyle = { color: "" };
        let inputStyle = { color: "" };
        if (typeof this.props.theme !== 'undefined') {
            divStyle = this.props.theme.div;
            inputStyle = this.props.theme.input;
        }

        return (
            <div>
                {/* <ToastContainer /> */}
                <LoadingSpinner showSpinner={this.state.loading} />
                {this.state.firstLogin === true ? <Theme showThemeButton="hide" /> : <Menu />}
                <Container>
                    <Col sm="12" style={{ marginTop: "60px" }}>
                        <Col sm="3">
                        </Col>
                        <Col sm="6" className="password_reset_form" style={divStyle}>
                            {/* Author - Gayathri Changed the color of text - Sep-11 */}
                            <h4 style={{ color: "#09897e" }}>
                                {this.state.firstLogin === true ? "" : <i className="fa fa-arrow-circle-left" style={{ fontSize: "22px", cursor: "pointer", float: "left", color: this.props.theme ? this.props.theme.color : "#2A3139" }} title="Back" onClick={this.redirect}></i>}
                                <i className="fa fa-key"></i> &nbsp;Reset your password</h4>
                            <div style={{ padding: "20px" }}>

                                <Label style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Old password *</Label>
                                <InputGroup>
                                    <Input type={this.state.oldPasswordType} id="OldPassword" onChange={this.handleChange} onBlur={this.handleBlur} autoComplete="password-type" value={this.state.oldPassword} name="oldPassword" id="securaaResetOldPassword" placeholder="Old password" style={inputStyle} onKeyPress={helpers.handleKeyPress} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" id="OldPasswordIbtn" className="task-input-field-button password-btn-dark"
                                            onMouseDown={() => this.setState({ oldPasswordType: "text" })}
                                            onMouseUp={() => this.setState({ oldPasswordType: "password" })}
                                            onMouseOut={() => this.setState({ oldPasswordType: "password" })}
                                            onClick={() => { return false; }}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                    </InputGroupAddon>
                                    <div className="errorMessage">{this.state.oldPasswordError}</div>
                                </InputGroup>

                                <Label style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>New password *</Label>
                                <InputGroup>
                                    <Input type={this.state.newpasswordType} id="NewPassword" onChange={this.handleChange} onBlur={this.handleBlur} value={this.state.newPassword} autoComplete="password-type" name="newPassword" id="securaaResetNewPassword" placeholder="New password" style={inputStyle} onKeyPress={helpers.handleKeyPress} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" id="NewPasswordIbtn" className="task-input-field-button password-btn-dark"
                                            onMouseDown={() => this.setState({ newpasswordType: "text" })}
                                            onMouseUp={() => this.setState({ newpasswordType: "password" })}
                                            onMouseOut={() => this.setState({ newpasswordType: "password" })}
                                            onClick={() => { return false; }}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                    </InputGroupAddon>
                                    <div className="errorMessage">{this.state.newPasswordError}</div>
                                </InputGroup>

                                <Label style={{ color: this.props.theme ? this.props.theme.color : "#2A3139" }}>Confirm password *</Label>
                                <InputGroup>
                                    <Input type={this.state.confirmPasswordType} id="ConfirmPassword" onChange={this.handleChange} onBlur={this.handleBlur} autoComplete="password-type" name="confirmPassword" value={this.state.confirmPassword} id="securaaResetConfirmPassword" placeholder="Confirm password" style={inputStyle} onKeyPress={helpers.handleKeyPress} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" id="ConfirmPasswordIbtn" className="task-input-field-button password-btn-dark"
                                            onMouseDown={() => this.setState({ confirmPasswordType: "text" })}
                                            onMouseUp={() => this.setState({ confirmPasswordType: "password" })}
                                            onMouseOut={() => this.setState({ confirmPasswordType: "password" })}
                                            onClick={() => { return false; }}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                    </InputGroupAddon>
                                    <div className="errorMessage">{this.state.confirmPasswordError}</div>
                                </InputGroup>
                                {/* Author:- Divya - 6/7/2023 changes:- added new button cancel */}
                                <div className="pull-center" style={{ marginTop: "0px" }}>
                                    <Button id="securaaResetSave" color="success" size="sm" style={{ width: "100px" }} onClick={this.checkUserOldPassword} disabled={!this.state.btnText}>Save</Button>
                                    <Button size="sm" id="ChangePasswordCancel" style={{ width: "100px" }} onClick={this.redirect}>Cancel</Button>
                                </div>
                            </div>
                        </Col>
                        <Col sm="3">
                        </Col>
                    </Col>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.reducer.theme.theme,
        layout: state.reducer.resize.layout,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDefaultTheme: getDefaultTheme, lightTheme: lightTheme, darkTheme: darkTheme }, dispatch)
}

const exportLicense = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
export default exportLicense;
